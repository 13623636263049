import React, { useCallback, useEffect } from "react";
import styles from "./ledger.module.scss";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import Table from "rc-table";
import { TablePagination } from "@mui/material";
import columns from "./ledgerConfig";
import { useSearchParams, useLocation } from "react-router-dom";
import { LEDGER_LIMIT, LEDGER_PAGE } from "src/components/constant/queryConstants";
import { fetchTransListAction } from "./ledgerSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";

function Ledgers() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { transList, transLoading } = useAppSelector((state) => state.ledger);
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchTransactionData = useCallback(() => {
    const ledgerLimit = searchParams.get(LEDGER_LIMIT) ?? 25;
    const ledgerPage = searchParams.get(LEDGER_PAGE) ?? 0;

    const data = {
      size: Number(ledgerLimit),
      from: Number(ledgerLimit) * Number(ledgerPage)
    };

    dispatch(fetchTransListAction(data));
  }, [dispatch, searchParams]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(LEDGER_PAGE, newPage + "");
    setSearchParams(searchParams);
    fetchTransactionData();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(LEDGER_LIMIT, event.target.value);
    searchParams.delete(LEDGER_PAGE);
    setSearchParams(searchParams);
    fetchTransactionData();
  };

  useEffect(() => {
    fetchTransactionData();
  }, [location.pathname, fetchTransactionData]);
  return (
    <div className={styles.ledger}>
      <div className={styles.ledgerWelCard}>
        <TransactionWelCard
          heading={"View Your Ledgers"}
          subHeading={"View details of all the ledgers on your wallets"}
        />
      </div>
      <div className={styles.ledgerTableCard}>
        <div className={styles.ledgerListHead}>
          {/* <h3>Ledger</h3> */}
          {/* <p>Filter, select and download</p> */}
        </div>
        <div className={styles.ledgerListTable}>
          <Table
            columns={columns}
            data={transLoading ? [] : transList.list}
            className={styles.tableIn}
            rowKey={(record) => record.id}
            components={
              transLoading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={transList.total}
            page={searchParams.get(LEDGER_PAGE) ? Number(searchParams.get(LEDGER_PAGE)) : 0}
            rowsPerPage={searchParams.get(LEDGER_LIMIT) ? Number(searchParams.get(LEDGER_LIMIT)) : 25}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Ledgers;
