import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { RouterProvider } from "react-router-dom";

import router from "./page/Route";
import { Provider } from "react-redux";
import store, { persistor } from "./page/store";
import { Suspense } from "react";
import LayoutSkeleton from "./components/layoutSkeleton/LayoutSkeleton";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<LayoutSkeleton />}>
          <RouterProvider router={router} />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
