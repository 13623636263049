import { PaginationRequest } from "src/types/commonTypes";
import client from "../client";

export const getAllWallets = (limit: number = 10) => {
  return client({
    query: `
    query GetAllWallets($limit: Int) {
      get_all_wallets(limit: $limit) {
        next_token
        wallet {
          balance
          created_by
          description
          display_id
          is_default
          name
          wallet_id
          wallet_status
          wallet_type
        }
      }
    }
    `,
    variables: {
      limit
    }
  });
};

export const getWalletDetail = (walletId: string) => {
  return client({
    query: `
    query GetWallet($input: GetWalletInput) {
      get_wallet(input: $input) {
        preferences {
          is_cash_withdrawal_allowed
          maximum_allowed_limit
          preference_id
          spend_frequency
          spend_limit
        }
        user {
          attributes {
            last_name
            first_name
          }
          user_id
          display_id
        }
        balance
        created_by
        description
        display_id
        is_default
        name
        wallet_id
        wallet_status
        wallet_type
      }
    }
    `,
    variables: {
      input: {
        wallet_id: walletId
      }
    }
  });
};

export type CreateWalletInputType = { description: string; name: string; userId: string };
export const createWallet = (data: CreateWalletInputType) => {
  return client({
    query: `
    mutation WalletMutation($input: CreateWalletInput) {
      create_wallet(input: $input) {
        wallet_id
      }
    }`,
    variables: {
      input: {
        description: data.description,
        name: data.name,
        object_type: "USER",
        object_id: data.userId,
        wallet_type: "GENERAL"
      }
    }
  });
};

export type UpdateWalletInputType = {
  wallet_id: string;
  name: string;
  description: string;
  set_default?: boolean;
};

export const updateWallet = (data: UpdateWalletInputType) => {
  return client({
    query: `
    mutation WalletUpdate($input: UpdateWalletInput) {
      update_wallet(input: $input) {
        balance
        created_by
        description
        display_id
        is_default
        name
        wallet_id
        wallet_status
        wallet_type
      }
    }
    `,
    variables: {
      input: data
    }
  });
};

export const toggleWalletActivation = (walletId: string, isActive: boolean) => {
  return client({
    query: `mutation ToggleWalletActivation($input: ToggleWalletActivationInput) {
      toggle_wallet_activation(input: $input) {
        wallet_status
      }
    }`,
    variables: {
      input: {
        wallet_id: walletId,
        is_active: isActive
      }
    }
  });
};

export const getWalletUsers = (walletId: string) => {
  return client({
    query: `
    query GetWalletUsers($input: WalletQueryInput) {
      get_wallet_users(input: $input) {
        display_id
        email
        is_active
        user_id
        current_organisation_role
        roles {
          organisation_id,
          value
        }
        attributes {
          first_name
          last_name
        }
      }
    }
    `,
    variables: {
      input: {
        wallet_id: walletId
      }
    }
  });
};

export const getWalletCards = (walletId: string) => {
  return client({
    query: `
    query GetWalletCards($input: WalletQueryInput) {
      get_wallet_cards(input: $input) {
        card_setting {
          card_id
          card_setting_status
          expires_at
          sub_type
          ppan
          wallet_id
          card_setting_id 
        }
        holder_name
        card_id
        ppan
        card_status
        card_type
        user {
          email
          attributes {
            first_name
            last_name
          }
        }
      }
    }
    `,
    variables: {
      input: {
        wallet_id: walletId
      }
    }
  });
};

export const removeUserFromWallet = (walletId: string, userId: string, fromAll: boolean) => {
  return client({
    query: `
      mutation RemoveUserFromWallet($input: RemoveUserWalletInput) {
        remove_user_from_wallet(input: $input) {
          wallet_id
          user_id
        }
      }    
    `,
    variables: {
      input: {
        all: fromAll,
        wallet_id: walletId,
        user_id: userId
      }
    }
  });
};

export const removeCardFromWallet = (walletId: string, cardId: string, fromAll: boolean) => {
  return client({
    query: `
      mutation RemoveCardFromWallet($input: RemoveCardWalletInput) {
        remove_card_from_wallet(input: $input) {
          card_id
          wallet_id
        }
      }    
    `,
    variables: {
      input: {
        all: fromAll,
        wallet_id: walletId,
        card_id: cardId
      }
    }
  });
};

export const connectUserToWallet = (walletId: string, userId: string, toAll: boolean = false) => {
  return client({
    query: `
    mutation ConnectUserToWallet($input: ConnectUserWalletInput) {
      connect_user_to_wallet(input: $input) {
        card_id
        user_id
        wallet_id
      }
    }
    
    `,
    variables: {
      input: {
        all: toAll,
        wallet_id: walletId,
        user_id: userId
      }
    }
  });
};

export const connectCardToWallet = (walletId: string, cardId: string, toAll: boolean = false) => {
  return client({
    query: `
    mutation AddCardToWallet($input: AddCardWalletInput) {
  add_card_to_wallet(input: $input) {
        card_id
        wallet_id
      }
    }
    `,
    variables: {
      input: {
        all: toAll,
        wallet_id: walletId,
        card_id: cardId
      }
    }
  });
};

export interface SearchWalletRequest extends PaginationRequest {
  walletFilter?: {
    wallet_status?: string;
    wallet_type?: string;
    balance?: {
      min?: number;
      max?: number;
    };
    created_at?: {
      min?: string;
      max?: string;
    };
    user_ids?: string[];
    display_id?: string;
    name?: string;
  };
  userFilter?: {
    email?: string;
  };
  orgFilter?: {
    organisationDisplayId?: string;
  };
}

export const searchWallet = (data?: SearchWalletRequest) => {
  return client({
    query: `
    query SearchWallet($input: SearchInput) {
      search_wallet(input: $input) {
        total_pages
        total_size
        items {
          balance
          created_by
          description
          display_id
          is_default
          name
          wallet_id
          wallet_status
          wallet_type
          user {
            attributes {
              last_name
              first_name
            }
            email
            user_id
            display_id
          }
        }
      }
    }`,
    variables: {
      input: {
        from: data?.from,
        size: data?.size,
        filter: {
          organisation_filter: {
            display_id: data?.orgFilter?.organisationDisplayId
          },
          wallet_filter: data?.walletFilter,
          user_filter: data?.userFilter
        }
      }
    }
  });
};

export const toggleDefaultWallet = (walletId: string) => {
  return client({
    query: `
    mutation ToggleDefaultWallet($input: SetDefaultWalletInput) {
      set_default_wallet(input: $input) {
        is_default
        display_id
        wallet_id
      }
    }`,
    variables: {
      input: {
        wallet_id: walletId
      }
    }
  });
};

export type WalletPreferenceRequest = {
  cashWithdrawalAllowed: boolean;
  maxAllowedLimit: number;
  spendFrequency: string;
  spendLimit: number;
  walletId: string;
};

export const changeWalletPreference = (data: WalletPreferenceRequest) => {
  return client({
    query: `
    mutation ChangeWalletPreference($input: WalletPreferencesInput) {
      save_wallet_preferences(input: $input) {
        is_cash_withdrawal_allowed
        maximum_allowed_limit
        preference_id
        spend_frequency
        spend_limit
      }
    }`,
    variables: {
      input: {
        spend_frequency: data.spendFrequency,
        spend_limit: data.spendLimit,
        is_cash_withdrawal_allowed: data.cashWithdrawalAllowed,
        maximum_allowed_limit: data.maxAllowedLimit,
        wallet_id: data.walletId
      }
    }
  });
};

export interface WalletDownloadRequest {
  walletFilter?: {
    wallet_status?: string;
    wallet_type?: string;
    balance?: {
      min?: number;
      max?: number;
    };
    created_at?: {
      min?: string;
      max?: string;
    };
    user_ids?: string[];
    name?: string;
    custom_organisation_id: string;
  };
  timeZone?: string;
}

export const downloadWallet = (data: WalletDownloadRequest) => {
  return client({
    query: `mutation download_wallets ($input: DownloadWalletsInput) {
      download_wallets(input: $input) {
        code
        count
        request_id
        download_url
      }
    }`,
    variables: {
      input: {
        wallet_filter: data?.walletFilter,
        timezone: data?.timeZone
      }
    }
  });
};
