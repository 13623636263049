// import { Avatar } from "@mui/material";
import { Transaction } from "src/page/transactions/transactionSlice";
import styles from "../recentrans.module.scss";
import { Link } from "react-router-dom";
import { dateTimeFormat, getDecimalNumber } from "src/utils/utils";

interface params {
  walletId?: string;
  userId?: string;
}

const generateColumns = ({ walletId, userId }: params) => {
  const baseColumns = [
    {
      title: "Merchant",
      dataIndex: "merchant",
      key: "merchant",
      render: (merchantName: string, data: Transaction) => (
        <span className={styles.tableTrans}>
          {data?.data?.merchantName === null ||
          data?.data?.merchantName === undefined ||
          data?.data?.merchantName.trim() === ""
            ? "---"
            : data?.data?.merchantName}
        </span>
      )
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number, data: Transaction) => (
        <div className={styles.tableAmount}>${getDecimalNumber(data.data.amount)}</div>
      )
    },
    {
      title: "Date / Time",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (endDate: string) => {
        const dateValue = endDate;

        if (dateValue === undefined || null) {
          return (
            <>
              <div>{"No date available"}</div>
              <div>{"No time available"}</div>
            </>
          );
        }

        return (
          <>
            <div>{dateTimeFormat(dateValue)?.date}</div>
            <div>{dateTimeFormat(dateValue)?.time}</div>
          </>
        );
      }
    }
  ];

  if (!userId) {
    baseColumns.push({
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (wallet: string, data: Transaction) => (
        <div className={styles.tableCategory}>
          {data?.data?.user?.user_id ? (
            <Link to={`/users/${data?.data?.user?.user_id}`}>
              {data?.data?.user
                ? data?.data?.user?.attributes?.first_name + " " + data?.data?.user?.attributes?.last_name
                : ""}
            </Link>
          ) : (
            "---"
          )}
        </div>
      )
    });
  }

  if (!walletId) {
    baseColumns.push(
      {
        title: "Wallet",
        dataIndex: "wallet",
        key: "wallet",
        render: (wallet: string, data: Transaction) => (
          <div className={styles.tableCategory}>
            {data?.data?.wallet_id ? (
              <Link to={`/wallets/${data?.data?.wallet_id}`}>
                {data?.data?.wallet ? data?.data?.wallet?.name : data?.data?.wallet_id}
              </Link>
            ) : (
              "---"
            )}
          </div>
        )
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        render: (balance: number, data: Transaction) => (
          <div className={styles.tableBalance}>${data?.data?.wallet?.balance ? data?.data?.wallet?.balance : 0}</div>
        )
      }
    );
  }

  return baseColumns;
};

export default generateColumns;
