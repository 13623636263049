import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { SearchWalletRequest, searchWallet } from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";
import { Transaction } from "../transactions/transactionSlice";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";

const initialWallets: Wallet[] = [];

const initialTransaction: Transaction[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: { [key: string]: walletTransaction } = {};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    walletLoading: false,
    transLoading: false,
    walletTransLoading: false,
    error: null,
    topWallets: {
      wallets: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    walletTransaction: initialwalletTransaction
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.topWallets.wallets = action.payload?.list ?? [];
      state.topWallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.topWallets.wallets = [];
      state.topWallets.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = {};
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail
} = dashboardSlice.actions;

export const fetchTopWalletAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        participant: { role: "PARTICIPANT", fullName: wallet.name }
      }));

      dispatch(fetchWalletsSuccess({ list: structuredResponse, total: structuredResponse.length }));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchTransListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};

export const fetchWalletTransactionAction = (data: any) => {
  return data;
};

export default dashboardSlice.reducer;
