import { useState, useEffect } from "react";
import { Button, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import styles from "./changePin.module.scss";
// import { MuiOtpInput } from "mui-one-time-password-input";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import { VisibilityOff, Visibility } from "@mui/icons-material";
import PinInput from "react-pin-input";
import { changeCardPinAction, fetchCardListAction } from "./changePinSlice";
import { useAppDispatch, useAppSelector } from "../store";

// type Card = {
//   id: number;
//   cardNumber: number;
//   cardName: string;
// };

// const cardOption: Card[] = [
//   {
//     id: 1,
//     cardNumber: 242334636346,
//     cardName: "Reece Miller"
//   },
//   {
//     id: 2,
//     cardNumber: 242334636346,
//     cardName: "Jane Doe"
//   },
//   {
//     id: 3,
//     cardNumber: 242334636346,
//     cardName: "John Doe"
//   },
//   {
//     id: 4,
//     cardNumber: 242334636346,
//     cardName: "John Doe"
//   },
//   {
//     id: 5,
//     cardNumber: 242334636346,
//     cardName: "John Doe"
//   }
// ];

export default function ChangePin() {
  // const wallet = useState<Wallet | null>();
  const dispatch = useAppDispatch();
  const [selectCardNumber, setSelectCardNumber] = useState<number | string>("");
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");
  const { cards } = useAppSelector((state) => state.changePin);
  const { userInfo } = useAppSelector((state) => state.auth);
  // const [pinInputType, setPinInputType] = useState<"text" | "password">("password");
  // const [confirmPinInputType, setConfirmPinInputType] = useState<"text" | "password">("password");
  // const [showPassword, setShowPassword] = useState<boolean>(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  useEffect(() => {
    const data = {
      size: 50,
      from: 0,
      card_filter: {
        user_id: userInfo?.id
      }
    };

    dispatch(fetchCardListAction(data));
  }, [dispatch, userInfo?.id]);

  const handleCardChange = (event: SelectChangeEvent<number | string>) => {
    const cardId = event.target.value as number;

    setSelectCardNumber(cardId);
  };

  const handlePinChange = (newValue: string) => {
    setPin(newValue);
    if (newValue !== confirmPin) {
      setPinError("PINs do not match");
    } else {
      setPinError("");
    }
  };

  const handleConfirmPinChange = (newValue: string) => {
    setConfirmPin(newValue);
    if (newValue !== pin) {
      setPinError("PINs do not match");
    } else {
      setPinError("");
    }
  };

  // const handleTogglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  //   {
  //     showPassword ? setPinInputType("password") : setPinInputType("text");
  //   }
  // };

  // const handleToggleConfirmPasswordVisibility = () => {
  //   setShowConfirmPassword(!showConfirmPassword);
  //   {
  //     showConfirmPassword ? setConfirmPinInputType("password") : setConfirmPinInputType("text");
  //   }
  // };

  const renderCardValue = (selected: number | string) => {
    if (selected === "") {
      return <Typography sx={{ color: "grey" }}>Select Card</Typography>;
    }
    const card = cards?.list?.find((c) => c.id === selected);

    return card ? card.user.fullName : "Select Card";
  };

  const handleChangePin = () => {
    dispatch(changeCardPinAction(String(selectCardNumber), pin));
  };

  return (
    <div className={styles.container}>
      <h1>Change Pin</h1>
      <div className={styles.cardInput}>
        <Select
          className={styles.selectOption}
          value={selectCardNumber}
          onChange={handleCardChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          renderValue={renderCardValue}
          sx={{ width: "250px" }}
        >
          <MenuItem value="" sx={{ color: "grey" }}>
            Select Card
          </MenuItem>
          {cards?.list.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
            >
              <div>{option?.user?.fullName}</div>
              <div> {option.cardNumber ? "xxxx-xxxx-xxxx-" + option.cardNumber?.slice(-4) : ""}</div>
            </MenuItem>
          ))}
        </Select>
      </div>
      {selectCardNumber && (
        <>
          <div className={styles.otpInputContainer}>
            <div className={styles.pinInput}>
              <label>Enter new PIN:</label>
              <div className={styles.inputcontainer}>
                <PinInput
                  length={4}
                  secret
                  secretDelay={500}
                  onChange={handlePinChange}
                  style={{ display: "flex", gap: "10px" }}
                  focus
                />
              </div>
            </div>

            <div className={styles.pinInput}>
              <label>Re-enter new PIN:</label>
              {/* <div className={styles.inputcontainer}> */}
              <PinInput
                length={4}
                secret
                secretDelay={500}
                onChange={handleConfirmPinChange}
                style={{ display: "flex", gap: "10px" }}
              />
              {/* </div> */}
            </div>
          </div>
          {pinError && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {pinError}
            </div>
          )}
          <div className={styles.buttons}>
            <Button
              variant="contained"
              className={styles.backBtn}
              onClick={() => {
                setSelectCardNumber("");
                setConfirmPin("");
                setPin("");
              }}
            >
              Back
            </Button>
            <Button variant="contained" className={styles.continueBtn} onClick={handleChangePin}>
              Confirm
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
