export const countryCodeData = [
  { code: "+1", label: "United States" },
  { code: "+1", label: "Canada" },
  { code: "+44", label: "United Kingdom" },
  { code: "+91", label: "India" },
  { code: "+61", label: "Australia" },
  { code: "+64", label: "New Zealand" },
  { code: "+49", label: "Germany" },
  { code: "+33", label: "France" },
  { code: "+81", label: "Japan" },
  { code: "+86", label: "China" },
  { code: "+27", label: "South Africa" },
  { code: "+55", label: "Brazil" },
  { code: "+7", label: "Russia" },
  { code: "+39", label: "Italy" },
  { code: "+34", label: "Spain" },
  { code: "+46", label: "Sweden" },
  { code: "+47", label: "Norway" },
  { code: "+31", label: "Netherlands" },
  { code: "+41", label: "Switzerland" },
  { code: "+45", label: "Denmark" }
];
