import { getDecimalNumber } from "src/utils/utils";
import styles from "./walletCard.module.scss";
import { Avatar } from "@mui/material";

interface WalletCardProps {
  avatar: string;
  title: string;
  subtitle: string;
  walletBalance: number;
  cardBalance: number;
  bg?: string;
}

function WalletCard({ avatar, title, subtitle, walletBalance, bg }: WalletCardProps) {
  return (
    <div className={styles.WalletCard}>
      <div className={styles.card}>
        <div className={styles.cardHolder}>
          <div className={styles.cardHolderImg}>
            {/* <img src={avatar} alt="Avatar" /> */}
            <Avatar id="av" className={styles.av} aria-label="Recipe" style={{ backgroundColor: bg }}>
              {avatar}
            </Avatar>
          </div>
          <div className={styles.cardHolderName}>
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
        <div className={styles.cardBalance}>
          <div className={styles.cardWalletBal}>
            <p className={styles.walletBalH}>Wallet Bal:</p>
            <p className={styles.walletBalP}>${getDecimalNumber(walletBalance)}</p>
          </div>
          {/* <div className={styles.cardCardBal}>
            <p className={styles.cardCardBalH}>Card Bal:</p>
            <p className={styles.cardCardBalP}>{cardBalance ? `$${cardBalance}` : "$0"}</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default WalletCard;
