import config from "src/config/Config";
import { useAppSelector } from "src/page/store";
import { Button } from "@mui/material";
import styles from "src/page/auth/auth.module.scss";

function CognitoAuth() {
  const { cognitoConfig } = config;
  const { loading } = useAppSelector((state) => state.auth);

  return (
    <Button
      onClick={() => {
        window.location.href = cognitoConfig.oauthProvider;
      }}
      variant="contained"
      className={styles.CorporateSignInBtn}
      disabled={loading}
    >
      <span>Sign In With Corporate Id</span>
    </Button>
  );
}

export default CognitoAuth;
