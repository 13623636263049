import { activityNameMapping } from "src/components/constant/constant";
import { Wallet } from "src/page/wallets/walletSlice";
import { User } from "src/types/commonTypes";
import { dateTimeFormat } from "src/utils/utils";
export interface FilterTagType {
  key: string;
  label: string;
  users?: User[];
  wallets?: Wallet[];
}

interface Filters {
  user?: User[] | null;
  wallet?: Wallet[] | null;
  startDate?: string | null;
  endDate?: string | null;
  minAmount?: string | null;
  maxAmount?: string | null;
  status?: string | null;
  type?: string | null;
  activityName?: string | null;
  [key: string]: any;
}

export const generateFilterTags = (filters: Filters): FilterTagType[] => {
  const filterTags: FilterTagType[] = [];

  if (filters.status) {
    filterTags.push({ key: "status", label: `Status is ${filters.status.toLowerCase()}` });
  }

  if (filters.type) {
    filterTags.push({ key: "type", label: `Type is ${filters.type.toLowerCase()}` });
  }

  if (filters.activityName) {
    filterTags.push({ key: "activityName", label: `Activity name is ${activityNameMapping[filters.activityName]}` });
  }

  if (filters.startDate && filters.endDate) {
    filterTags.push({
      key: "date",
      label: `Date in between ${dateTimeFormat(filters.startDate)?.dwml} - ${dateTimeFormat(filters.endDate)?.dwml}`
    });
  } else if (filters.startDate) {
    filterTags.push({ key: "startDate", label: `Date from ${dateTimeFormat(filters.startDate)?.dwml}` });
  } else if (filters.endDate) {
    filterTags.push({ key: "endDate", label: `Date till ${dateTimeFormat(filters.endDate)?.dwml}` });
  }

  if (filters.user && filters.user.length > 0) {
    if (filters.user.length === 1) {
      filterTags.push({ key: "user", label: `User is ${filters.user[0]?.firstName} ${filters.user[0]?.lastName}` });
    } else {
      filterTags.push({ key: "users", label: `User in`, users: filters.user });
    }
  }

  if (filters.wallet && filters.wallet.length > 0) {
    if (filters.wallet.length === 1) {
      filterTags.push({ key: "wallet", label: `Wallet is ${filters.wallet[0]?.name}` });
    } else {
      filterTags.push({ key: "wallets", label: `Wallets in`, wallets: filters.wallet });
    }
  }

  if (filters.minAmount && filters.maxAmount) {
    filterTags.push({ key: "amount", label: `Amount in between $${filters.minAmount} - $${filters.maxAmount}` });
  } else if (filters.minAmount) {
    filterTags.push({ key: "minAmount", label: `Amount greater than $${filters.minAmount}` });
  } else if (filters.maxAmount) {
    filterTags.push({ key: "maxAmount", label: `Amount less than $${filters.maxAmount}` });
  }

  return filterTags;
};
