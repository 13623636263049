import React, { useState, useEffect, useCallback } from "react";
import styles from "./walletListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./walletListTable/WalletListTable";
import Modalui from "src/components/UI/modal/Modalui";
import AddWalletModal, { AddWalletIn } from "src/components/walletList/addWalletModal/AddWalletModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchWalletListAction,
  addWalletAction,
  connectWalletAction,
  downloadWalletAction
} from "src/page/wallets/walletSlice";
import UserListFilter from "./userListFilter/UserListFilter";
import { useSearchParams, useParams, useLocation } from "react-router-dom";
import { WAL_LIMIT, WAL_PAGE } from "../constant/queryConstants";
import { utc } from "moment";
import DeleteSettingModal from "../connectCard/connectMember/deleteSupporterModal/DeleteSupporterModal";
import WalletIcon from "@mui/icons-material/Wallet";
import ConnectWalletModal from "./connectWalletModal/ConnectWalletModal";
import { CAN_CONNECT_WALLET_TO_ORGANISATION, CAN_ADD_WALLET } from "../constant/constant";
import { checkPermission, getTimeZone } from "src/utils/utils";
import CircularLoader from "../UI/circularLoader/CircularLoader";
import { FilterTagType, generateFilterTags } from "../UI/filterTag/filterTagUtils";
import FilterTag from "../UI/filterTag/FilterTag";
import { setWalletFilters } from "src/page/filtersSlice";
import { User } from "src/types/commonTypes";
import { CircularProgress } from "@mui/material";

type WalletIn = {
  walletId: number;
  organisationId?: number;
};

interface TableData {
  id: number;
  participant: {
    firstName: string;
    lastname: string;
    email: string;
    fullName: string;
  };
  description: string;
}

function WalletListCard() {
  const [open, setOpen] = useState(false);
  const [openConnectWallet, setOpenConnectWallet] = useState(false);
  const [unlinkModal, setUnlinkModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [unlinkData, setUnlinkData] = useState({ id: 0, name: "", img: "", email: "" });
  const { walletList, walletLoading, downloadWalletLoading } = useAppSelector((state) => state.wallet);
  const { cognitoConfig: config } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { wallet: walletFilters } = useAppSelector((state) => state.filters);
  const [searchText, setSearchText] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { organisationId } = useParams();
  const location = useLocation();
  const walLimit = searchParams.get(WAL_LIMIT) ?? "10";
  const walPage = searchParams.get(WAL_PAGE) ?? "0";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(WAL_PAGE, newPage + "");
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(WAL_LIMIT, event.target.value);
    searchParams.delete(WAL_PAGE);
    setSearchParams(searchParams);
  };

  const fetchWalletsData = useCallback(() => {
    const pageNo = parseInt(walPage);
    const limit = parseInt(walLimit);
    const walletFilter: any = {};

    if (walletFilters.status) {
      walletFilter.wallet_status = walletFilters.status;
    }
    if (walletFilters.type) {
      walletFilter.wallet_type = walletFilters.type;
    }
    if (walletFilters.minAmount !== null) {
      walletFilter.balance = { ...(walletFilter.balance || {}), min: Number(walletFilters.minAmount) };
    }
    if (walletFilters.maxAmount !== null) {
      walletFilter.balance = { ...(walletFilter.balance || {}), max: Number(walletFilters.maxAmount) };
    }
    if (walletFilters.startDate) {
      walletFilter.created_at = { ...(walletFilter.created_at || {}), min: utc(walletFilters.startDate).toISOString() };
    }
    if (walletFilters.endDate) {
      walletFilter.created_at = { ...(walletFilter.created_at || {}), max: utc(walletFilters.endDate).toISOString() };
    }
    if (walletFilters.user && walletFilters.user.length > 0) {
      walletFilter.user_ids = walletFilters.user.map((user) => user.id);
    }
    if (searchText) {
      walletFilter.name = searchText;
    }

    const data = {
      from: pageNo * limit,
      size: limit,
      walletFilter
    };

    dispatch(fetchWalletListAction(data));
  }, [dispatch, searchText, walLimit, walPage, walletFilters]);

  useEffect(() => {
    fetchWalletsData();
  }, [fetchWalletsData, location.pathname, walletFilters]);

  const isWalletListEmpty = !walletList || !walletList.list || walletList.list.length === 0;

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConnectWallet = () => {
    setOpenConnectWallet(!openConnectWallet);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleFilter = () => {};

  const handleAddWallet = (walletData: AddWalletIn) => {
    dispatch(
      addWalletAction(
        {
          description: walletData.description,
          name: walletData.walletName,
          userId: walletData.participantId
        },
        () => {
          fetchWalletsData();
          handleClose();
        }
      )
    );
  };

  const handleConnectWallet = (walletData: WalletIn, close: boolean) => {
    const WalletData = {
      ...walletData,
      action: "LINK"
    };

    delete WalletData.organisationId;

    connectWalletAction(Number(walletData.organisationId), WalletData, "Connected").then(() => {
      fetchWalletsData();
      if (close) {
        handleOpenConnectWallet();
      }
    });
  };

  const handleUnlinkWallet = (data: TableData) => {
    setUnlinkData({
      id: data.id,
      name: `${data.participant.fullName}`,
      img: data.participant.firstName?.charAt(0).toLocaleUpperCase(),
      email: data.description
    });
    setUnlinkModal(true);
  };

  let unlinkOperation;

  if (
    checkPermission(CAN_CONNECT_WALLET_TO_ORGANISATION) &&
    (organisationId || config?.currentOrganisation?.organisation_id)
  ) {
    unlinkOperation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: TableData) => (
        <div onClick={() => handleUnlinkWallet(record)} className={styles.unlinkOperation}>
          <Tooltip title="Unlink" arrow>
            <LinkOffIcon />
          </Tooltip>
        </div>
      ),
      width: 100
    };
  } else {
    unlinkOperation = {};
  }

  const updatedColumns = [...columns(searchText), unlinkOperation];

  useEffect(() => {
    fetchWalletsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, location.pathname]);

  const handleUnlinkClose = () => {
    setUnlinkModal(false);
  };

  const deleteInput = {
    buttonInfo: "Unlink",
    whomToRemove: "wallet's",
    headInfo: "Are you sure, you want to unlink this wallet?"
  };

  const onUnlinkWallet = (email: string, supporterId: number | string) => {
    const data = {
      walletId: supporterId,
      action: "UNLINK"
    };

    connectWalletAction(Number(organisationId || config?.currentOrganisation?.organisation_id), data, "Uninked").then(
      () => {
        fetchWalletsData();
      }
    );
  };

  const removeWalletFilter = (filterName: string) => {
    let newFilters;

    if (filterName === "date") {
      newFilters = {
        ...walletFilters,
        startDate: null,
        endDate: null
      };
    } else if (filterName === "amount") {
      newFilters = {
        ...walletFilters,
        minAmount: null,
        maxAmount: null
      };
    } else if (filterName === "users") {
      newFilters = { ...walletFilters, user: null };
    } else {
      newFilters = { ...walletFilters, [filterName]: null };
    }

    dispatch(setWalletFilters(newFilters));
  };

  const removeUserFromFilter = (userId: string, tagKey: string) => {
    if (tagKey === "users") {
      const newUsers = walletFilters?.user?.filter((user: User) => user.id !== userId);

      dispatch(setWalletFilters({ ...walletFilters, user: newUsers }));
    }
  };

  const dowmloadTransaction = () => {
    const walletFilter: any = {
      custom_organisation_id: config?.currentOrganisation?.organisation_id
    };

    if (walletFilters.status) {
      walletFilter.wallet_status = walletFilters.status;
    }
    if (walletFilters.type) {
      walletFilter.wallet_type = walletFilters.type;
    }
    if (walletFilters.minAmount !== null) {
      walletFilter.balance = { ...(walletFilter.balance || {}), min: Number(walletFilters.minAmount) };
    }
    if (walletFilters.maxAmount !== null) {
      walletFilter.balance = { ...(walletFilter.balance || {}), max: Number(walletFilters.maxAmount) };
    }
    if (walletFilters.startDate) {
      walletFilter.created_at = { ...(walletFilter.created_at || {}), min: utc(walletFilters.startDate).toISOString() };
    }
    if (walletFilters.endDate) {
      walletFilter.created_at = { ...(walletFilter.created_at || {}), max: utc(walletFilters.endDate).toISOString() };
    }
    if (walletFilters.user && walletFilters.user.length > 0) {
      walletFilter.user_ids = walletFilters.user.map((user) => user.id);
    }
    if (searchText) {
      walletFilter.name = searchText;
    }

    const data = {
      walletFilter,
      timeZone: String(getTimeZone().name)
    };

    console.log(data);

    dispatch(downloadWalletAction(data));
    setDownloadModal(false);
  };

  return (
    <>
      <Modalui
        open={downloadModal}
        handleClose={() => {
          setDownloadModal(false);
        }}
      >
        <div className={styles.referModalBox}>
          <div className={styles.referModalTop}>
            <form>
              <div className={styles.close}>
                <h5>{`The wallet will be downloaded using these filters:`}</h5>
              </div>
              <div className={styles.setting}>
                {searchText.trim() && (
                  <div className={styles.tag}>
                    <label>{`Name contains '${searchText}'`}</label>
                  </div>
                )}
                {generateFilterTags(walletFilters).map((filterTag: FilterTagType) => (
                  <FilterTag
                    key={filterTag.key}
                    tagKey={filterTag.key}
                    label={filterTag.label}
                    onRemove={() => removeWalletFilter(filterTag.key)}
                    users={walletFilters?.user}
                    removeUserWallet={(userId) => removeUserFromFilter(userId, filterTag.key)}
                  />
                ))}
              </div>
            </form>
          </div>
          <div className={styles.referModalBottom}>
            <div className={styles.referModalBtn}>
              <div className={styles.btnL}>
                <Button
                  className={styles.reportBtn}
                  variant="text"
                  onClick={() => {
                    setDownloadModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className={styles.btnR}>
                <Button
                  className={styles.filterBtn}
                  variant="outlined"
                  onClick={() => {
                    setDownloadModal(false);
                    setOpenFilter(true);
                  }}
                >
                  Modify filters
                </Button>
                <Button className={styles.sendBtn} variant="contained" onClick={dowmloadTransaction}>
                  Sure
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modalui>
      <Modalui open={openFilter} handleClose={handleCloseFilter} modaluiOver={styles.filterModalOver}>
        <UserListFilter handleClose={handleCloseFilter} handleFilter={handleFilter} />
      </Modalui>
      <Modalui open={unlinkModal} handleClose={handleUnlinkClose}>
        <DeleteSettingModal
          img={unlinkData.img}
          name={unlinkData.name}
          email={unlinkData.email}
          supporterId={unlinkData.id}
          handleClose={handleUnlinkClose}
          onDeleteSupporter={onUnlinkWallet}
          deleteInput={deleteInput}
          modalOf={"UNLINK"}
        />
      </Modalui>
      <div className={styles.walletList}>
        <div className={styles.walletListHead}>
          <div className={styles.walletListHeadIn}>
            <div className={styles.walletListHeadD}>
              <h3>Wallets List</h3>
              <p>Pick a wallet to view the details, edit budgets and connect users</p>
            </div>
            <div className={styles.walletListHeadBtn}>
              <Button
                variant="contained"
                className={styles.downloadWallet}
                onClick={() => setDownloadModal(true)}
                disabled={downloadWalletLoading}
              >
                {downloadWalletLoading ? (
                  <div className={styles.progress}>
                    <CircularProgress size={24} style={{ color: "white" }} />
                  </div>
                ) : (
                  "Download Wallets"
                )}
              </Button>
              <Button variant="outlined" className={styles.filterBtn} onClick={handleOpenFilter}>
                Filter Wallet
              </Button>
              {checkPermission(CAN_CONNECT_WALLET_TO_ORGANISATION) &&
                (organisationId || config?.currentOrganisation?.organisation_id) && (
                  <div onClick={handleOpenConnectWallet}>
                    <Button variant="contained" className={styles.addWalletBtn}>
                      <div>
                        <WalletIcon />
                      </div>
                      <div>Connect Wallet</div>
                    </Button>
                  </div>
                )}
              {checkPermission(CAN_ADD_WALLET) && (
                <div onClick={handleOpen}>
                  <Button variant="contained" className={styles.addWalletBtn}>
                    <div>
                      <PersonAddAlt1Icon />
                    </div>
                    <div>Add wallet</div>
                  </Button>
                </div>
              )}
              <Modalui open={open} handleClose={handleClose} modaluiOver={styles.modaluiOver}>
                <AddWalletModal
                  handleClose={handleClose}
                  isWalletListEmpty={isWalletListEmpty}
                  onAddWallet={handleAddWallet}
                />
              </Modalui>
              <Modalui open={openConnectWallet} handleClose={handleOpenConnectWallet} modaluiOver={styles.modaluiOver}>
                <ConnectWalletModal handleClose={handleOpenConnectWallet} onConnectWallet={handleConnectWallet} />
              </Modalui>
            </div>
          </div>
          <div className={styles.walletListFilter}>
            {/* <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button>All</Button>
            <Button>Participants</Button>
            <Button>Supporter</Button>
          </ButtonGroup> */}
            <div className={styles.filterTagsContainer}>
              {generateFilterTags(walletFilters).map((filterTag: FilterTagType) => (
                <FilterTag
                  key={filterTag.key}
                  tagKey={filterTag.key}
                  label={filterTag.label}
                  onRemove={() => removeWalletFilter(filterTag.key)}
                  users={walletFilters?.user}
                  removeUserWallet={(userId) => removeUserFromFilter(userId, filterTag.key)}
                />
              ))}
            </div>
            {/* <div className={styles.filterSearch}></div> */}
            <div className={styles.walletListSearch}>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>
        </div>
        <div className={styles.walletListTable}>
          <Table
            columns={updatedColumns}
            data={walletLoading ? [] : walletList.list}
            className={styles.tableIn}
            components={
              walletLoading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={walletList.total}
            page={searchParams.get(WAL_PAGE) ? Number(searchParams.get(WAL_PAGE)) : 0}
            rowsPerPage={searchParams.get(WAL_LIMIT) ? Number(searchParams.get(WAL_LIMIT)) : 10}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}

export default WalletListCard;
