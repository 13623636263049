import { ChangeEvent, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./addOrgModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAppSelector, useAppDispatch } from "src/page/store";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getUserByEmailAction } from "src/page/organisation/organisationSlice";
import { countryCodeData } from "src/types/countryCode";
import { phone } from "phone";
import UserNameInput from "src/components/userNameInput/UserNameInput";
import { checkEmailAvailability, checkUserNameAvailability } from "src/network/graphql/userService";

// import { setUsers } from "src/page/transactions/transactionSlice";
// import { Users } from "src/page/users/usersSlice";
// import { debounce } from "lodash";
// import { orgUserListAction } from "src/page/organisation/organisationSlice";

export type orgList = {
  organisationId?: number | string;
  id: number | null;
  name: string;
  abn: string;
  address: string;
  phone: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postcode?: string;
  country?: string;
  referenceName: string;
  referenceRole: string;
  referencePhone: string;
  referenceEmail: string;
  active?: boolean;
  superAdminInitial?: string;
  superAdminFirstName?: string;
  superAdminLastName?: string;
  superAdminDob?: string;
  superAdminEmail?: string;
  superAdminGender?: string;
  superAdminPhone?: string;
  superAdminaddressLine1?: string;
  superAdminaddressLine2?: string;
  superAdminCity?: string;
  superAdminState?: string;
  superAdminPostcode?: string;
  superAdminCountry?: string;
  superAdminUserName?: string;
  existingUserEmail?: string;
};

interface AddOrgModalProps {
  handleClose: () => void;
  onAddOrg: (_input: orgList, _cb?: () => void) => void;
  addUser?: boolean;
  orgInfo?: orgList;
}

export default function AddOrgModal({ handleClose, onAddOrg, orgInfo, addUser }: AddOrgModalProps) {
  const [input, setInput] = useState({
    id: orgInfo?.id || null,
    name: orgInfo?.name || "",
    abn: orgInfo?.abn || "",
    address: orgInfo?.address || "",
    phoneCountryCode: "+61",
    phone: "",
    website: orgInfo?.website || "",
    facebook: orgInfo?.facebook || "",
    linkedIn: orgInfo?.linkedIn || "",
    instagram: orgInfo?.instagram || "",
    line1: orgInfo?.line1 || "",
    line2: orgInfo?.line2 || "",
    city: orgInfo?.city || "",
    state: orgInfo?.state || "",
    postcode: orgInfo?.postcode || "",
    country: orgInfo?.country || "Australia",
    referenceName: orgInfo?.referenceName || "",
    referenceRole: orgInfo?.referenceRole || "",
    referencePhoneCountryCode: "+61",
    referencePhone: "",
    referenceEmail: orgInfo?.referenceEmail || "",
    superAdminInitial: "Mr",
    superAdminFirstName: "",
    superAdminLastName: "",
    superAdminDob: "",
    superAdminEmail: "",
    superAdminGender: "Male",
    superAdminPhoneCountryCode: "+61",
    superAdminPhone: "",
    superAdminaddressLine1: "",
    superAdminaddressLine2: "",
    superAdminCity: "",
    superAdminState: "",
    superAdminPostcode: "",
    superAdminCountry: "Australia",
    existingUserEmail: "",
    active: orgInfo?.active || true,
    superAdminUserName: ""
  });
  const [error, setError] = useState({
    name: "",
    abn: "",
    address: "",
    phone: "",
    website: "",
    referenceName: "",
    referenceRole: "",
    referencePhone: "",
    referenceEmail: "",
    addressLine1: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    userFirstName: "",
    userLastName: "",
    userDob: "",
    userEmail: "",
    userPhone: "",
    useraddressLine1: "",
    userCity: "",
    userState: "",
    userPostcode: "",
    userCountry: "",
    existingUserEmail: "",
    successfull: "",
    userName: ""
  });
  const [currentStep, setCurrentStep] = useState(false);
  const [isSelectExistingUser, setIsSelectExistingUser] = useState<boolean>(false);
  // const [user, setUser] = useState<Users | null>(null);
  const { addOrgLoading, userData } = useAppSelector((state) => state.organisation);

  const dispatch = useAppDispatch();

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleCancelClose = () => {
    handleClose();
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

  useEffect(() => {
    if (orgInfo?.phone) {
      const phoneData = phone(orgInfo?.phone);

      setInput((prevInput) => ({
        ...prevInput,
        phoneCountryCode: phoneData?.countryCode || "+61",
        phone: phoneData?.phoneNumber?.replace(phoneData.countryCode || "", "") || ""
      }));
    }
  }, [orgInfo?.phone]);

  useEffect(() => {
    if (orgInfo?.referencePhone) {
      const referencePhoneData = phone(orgInfo?.referencePhone);

      setInput((prevInput) => ({
        ...prevInput,
        referencePhoneCountryCode: referencePhoneData?.countryCode || "+61",
        referencePhone: referencePhoneData?.phoneNumber?.replace(referencePhoneData.countryCode || "", "") || ""
      }));
    }
  }, [orgInfo?.referencePhone]);

  const validateInput: () => boolean = () => {
    if (
      !input.name.trim() ||
      !input.abn.trim() ||
      !input.phone.trim() ||
      !input.website.trim() ||
      !input.referenceName.trim() ||
      !input.referenceRole.trim() ||
      !input.referenceEmail.trim() ||
      !input.referencePhone.trim() ||
      !input.line1.trim() ||
      !input.city.trim() ||
      !input.state.trim() ||
      !input.postcode.trim() ||
      !input.country.trim()
    ) {
      setError({
        ...error,
        name: input.name.trim() ? "" : "Company name is required",
        abn: input.abn.trim() ? "" : "Company's Abn is required",
        phone: input.phone.trim() ? "" : "Company's phone is required",
        website: input.website.trim() ? "" : "Company's website is required",
        referenceName: input.referenceName.trim() ? "" : "Name is required",
        referenceEmail: input.referenceEmail.trim() ? "" : "Email name is required",
        referenceRole: input.referenceRole.trim() ? "" : "Role is required",
        referencePhone: input.referencePhone.trim() ? "" : "Phone number is required",
        addressLine1: input.line1.trim() ? "" : "address is required",
        city: input.city.trim() ? "" : "city is required",
        state: input.state.trim() ? "" : "state is required",
        postcode: input.postcode.trim() ? "" : "postcode is required",
        country: input.country.trim() ? "" : "country is required"
      });
      return false;
    }

    if (input.abn.length < 11) {
      setError({
        ...error,
        abn: "ABN number should be equal to 11 digits"
      });
      return false;
    }

    if (!emailRegex.test(input.referenceEmail)) {
      setError({
        ...error,
        referenceEmail: "Invalid email format"
      });
      return false;
    }

    return true;
  };

  const validUser = async () => {
    const isUserNameAvailable = await checkUserNameAvailability(input.superAdminUserName);

    if (!isUserNameAvailable.data.is_username_available) {
      setError({
        ...error,
        userName: "username already exists"
      });
      return false;
    }
    const isEmailAvailable = await checkEmailAvailability(input.superAdminEmail);

    if (!isEmailAvailable.data.is_email_available) {
      setError({
        ...error,
        existingUserEmail: "email already exists"
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (isSelectExistingUser && userData?.user?.id !== 0) {
      setInput({
        ...input,
        superAdminInitial: userData.user.initial ?? "Mr",
        superAdminFirstName: userData?.user?.firstName,
        superAdminLastName: userData?.user?.lastName,
        superAdminDob: userData?.user?.dob,
        superAdminEmail: userData?.user?.email,
        superAdminGender: userData.user.gender ?? "Male",
        superAdminPhone: userData?.user?.phoneNumber,
        superAdminaddressLine1: userData?.user?.line1,
        superAdminaddressLine2: userData?.user?.line2,
        superAdminCity: userData?.user?.city,
        superAdminState: userData?.user?.state,
        superAdminPostcode: userData?.user?.zipcode,
        superAdminCountry: userData?.user?.country ?? "Australia"
      });
      setError({
        ...error,
        existingUserEmail: "",
        successfull: "Email found"
      });
    }
    // else {
    //   setError({
    //     ...error,
    //     existingUserEmail: "Email not found",
    //     successfull: ""
    //   });
    // }
  }, [isSelectExistingUser, userData]);

  const handleAddOrg = () => {
    if (addUser) {
      if (isSelectExistingUser) {
        if (!input?.existingUserEmail.trim()) {
          setError({
            ...error,
            existingUserEmail: "Email is required"
          });
          return;
        }

        if (!emailRegex.test(input?.existingUserEmail)) {
          setError({
            ...error,
            existingUserEmail: "Invalid email format"
          });
          return;
        }
      } else {
        if (
          !input.superAdminFirstName.trim() ||
          !input.superAdminLastName.trim() ||
          !input.superAdminDob.trim() ||
          !input.superAdminEmail.trim() ||
          !input.superAdminCity.trim() ||
          !input.superAdminState.trim() ||
          !input.superAdminPostcode.trim() ||
          !input.superAdminCountry.trim() ||
          !input.superAdminPhone.trim()
        ) {
          setError({
            ...error,
            userFirstName: input.superAdminFirstName.trim() ? "" : "First name is required",
            userLastName: input.superAdminLastName.trim() ? "" : "Last name is required",
            userDob: input.superAdminDob.trim() ? "" : "DOB is required",
            userEmail: input.superAdminEmail.trim() ? "" : "Email is required",
            userPhone: input.superAdminPhone.trim() ? "" : "Phone is required",
            useraddressLine1: input.superAdminaddressLine1.trim() ? "" : "address is required",
            userCity: input.superAdminCity.trim() ? "" : "city is required",
            userState: input.superAdminState.trim() ? "" : "state is required",
            userPostcode: input.superAdminPostcode.trim() ? "" : "postcode is required",
            userCountry: input.superAdminCountry.trim() ? "" : "country is required"
          });
          return;
        }

        if (!emailRegex.test(input.superAdminEmail)) {
          setError({
            ...error,
            userEmail: "Invalid email format"
          });
          return;
        }
      }

      if (!validUser()) {
        return;
      }
    } else {
      if (!validateInput()) {
        return;
      }
    }

    onAddOrg(
      {
        ...input,
        organisationId: orgInfo?.organisationId,
        phone: input.phoneCountryCode + input.phone,
        referencePhone: input.referencePhoneCountryCode + input.referencePhone,
        superAdminPhone: input.superAdminPhoneCountryCode + input.superAdminPhone
      },
      () => {
        setInput({
          id: null,
          name: "",
          abn: "",
          address: "",
          phoneCountryCode: "",
          phone: "",
          website: "",
          facebook: "",
          linkedIn: "",
          instagram: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          postcode: "",
          country: "",
          referenceName: "",
          referenceRole: "",
          referencePhone: "",
          referencePhoneCountryCode: "",
          referenceEmail: "",
          superAdminInitial: "",
          superAdminFirstName: "",
          superAdminLastName: "",
          superAdminDob: "",
          superAdminEmail: "",
          superAdminGender: "",
          superAdminPhoneCountryCode: "",
          superAdminPhone: "",
          superAdminaddressLine1: "",
          superAdminaddressLine2: "",
          superAdminCity: "",
          superAdminState: "",
          superAdminPostcode: "",
          superAdminCountry: "",
          existingUserEmail: "",
          active: true,
          superAdminUserName: ""
        });
        setError({
          ...error,
          userFirstName: "",
          userLastName: "",
          userDob: "",
          userEmail: "",
          userPhone: "",
          useraddressLine1: "",
          userCity: "",
          userState: "",
          userPostcode: "",
          userCountry: "",
          existingUserEmail: ""
        });
        handleClose();
      }
    );
  };

  const handleNext = () => {
    if (!validateInput()) return;
    setCurrentStep(true);

    setError({
      ...error,
      name: "",
      abn: "",
      address: "",
      phone: "",
      website: "",
      referenceName: "",
      referenceRole: "",
      referencePhone: "",
      referenceEmail: "",
      addressLine1: "",
      city: "",
      state: "",
      postcode: "",
      country: ""
    });
  };

  const addNewUser = () => {
    setIsSelectExistingUser(false);
    setInput({
      ...input,
      superAdminFirstName: "",
      superAdminLastName: "",
      superAdminDob: "",
      superAdminEmail: "",
      superAdminPhone: "",
      superAdminaddressLine1: "",
      superAdminCity: "",
      superAdminState: "",
      superAdminPostcode: "",
      superAdminCountry: ""
    });
  };

  const addExistingUser = () => {
    setIsSelectExistingUser(true);
    setInput({
      ...input,
      superAdminFirstName: "",
      superAdminLastName: "",
      superAdminDob: "",
      superAdminEmail: "",
      superAdminPhone: "",
      superAdminaddressLine1: "",
      superAdminCity: "",
      superAdminState: "",
      superAdminPostcode: "",
      superAdminCountry: ""
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value
    });
  };

  // const fetchUserOptions = debounce((inputValue: string) => {
  //   if (!inputValue || inputValue?.length < 2) return [];

  //   dispatch(orgUserListAction({ isActive: true, email: inputValue }));
  // }, 500);
  // const handleUserInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
  //   fetchUserOptions(newInputValue);
  // };

  const handleFetchUser = () => {
    if (!emailRegex.test(input?.existingUserEmail)) {
      setError({ ...error, existingUserEmail: "Invalid Email", successfull: "" });
      return;
    }
    dispatch(getUserByEmailAction(input?.existingUserEmail));
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    setInput({ ...input, [name]: value });
  };

  return (
    <div className={styles.rootContainer}>
      <div className={styles.modalTop}>
        <form>
          {!currentStep ? (
            <>
              <div className={styles.generalCompany}>
                <div className={styles.close}>
                  <h5>General Company Info</h5>
                  <button onClick={handleCancelClose}>
                    <CloseIcon onClick={handleCancelClose} />
                  </button>
                </div>
                <div className={styles.row}>
                  <div className={styles.companyName}>
                    <label>Company Name :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.name}
                        name="name"
                        onChange={handleInput}
                      />
                      {error.name && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.abnName}>
                    <label>ABN :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        maxLength={11}
                        value={input.abn}
                        name="abn"
                        onChange={handleInput}
                      />
                      {error.abn && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.abn}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.mobile}>
                    <label>Contact Phone :</label>
                    <div className={styles.inputWerror}>
                      <div className={styles.mobileSelect}>
                        <Select
                          value={input.phoneCountryCode || "+61"}
                          onChange={handleCountryCodeChange}
                          displayEmpty
                          name="phoneCountryCode"
                          className={styles.countryCodeSelect}
                          sx={{ height: "50px" }}
                        >
                          {countryCodeData.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              {country.code}
                            </MenuItem>
                          ))}
                        </Select>
                        <input
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.phone}
                          name="phone"
                          onChange={handleInput}
                        />
                      </div>
                      {error.phone && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.website}>
                    <label>Website :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.website}
                        name="website"
                        onChange={handleInput}
                      />
                      {error.website && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.website}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.facebook}>
                    <label>Facebook :</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.facebook}
                      name="facebook"
                      onChange={handleInput}
                    />
                  </div>
                  <div className={styles.instagram}>
                    <label>Instagram :</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.instagram}
                      name="instagram"
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.linkedIn}>
                    <label>LinkedIn :</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.linkedIn}
                      name="linkedIn"
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.companyAddress}>
                <div className={styles.close}>
                  <h5>Company Address</h5>
                </div>
                <div className={styles.row}>
                  <div className={styles.addressLine1}>
                    <label>Line 1 :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.line1}
                        name="line1"
                        onChange={handleInput}
                      />
                      {error.addressLine1 && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.addressLine1}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.addressLine2}>
                    <label>Line 2 :</label>
                    <input
                      type="text"
                      placeholder="Enter Text Here"
                      value={input.line2}
                      name="line2"
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.city}>
                    <label>City :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.city}
                        name="city"
                        onChange={handleInput}
                      />
                      {error.city && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.city}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.state}>
                    <label>State :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.state}
                        name="state"
                        onChange={handleInput}
                      />
                      {error.state && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.state}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.postcode}>
                    <label>Postcode :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.postcode}
                        name="postcode"
                        onChange={handleInput}
                      />
                      {error.postcode && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.postcode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.country}>
                    <label>Country :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.country}
                        name="country"
                        onChange={handleInput}
                      />
                      {error.country && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.country}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mainContact}>
                <div className={styles.close}>
                  <h5>Main Contact</h5>
                </div>
                <div className={styles.row}>
                  <div className={styles.name}>
                    <label>Name :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.referenceName}
                        name="referenceName"
                        onChange={handleInput}
                      />
                      {error.referenceName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.referenceName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.role}>
                    <label>Role :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.referenceRole}
                        name="referenceRole"
                        onChange={handleInput}
                      />
                      {error.referenceRole && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.referenceRole}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.phone}>
                    <label>Phone :</label>
                    <div className={styles.inputWerror}>
                      <div className={styles.mobileSelect}>
                        <Select
                          value={input.referencePhoneCountryCode || "+61"}
                          onChange={handleCountryCodeChange}
                          displayEmpty
                          name="referencePhoneCountryCode"
                          className={styles.countryCodeSelect}
                          sx={{ height: "50px" }}
                        >
                          {countryCodeData.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              {country.code}
                            </MenuItem>
                          ))}
                        </Select>

                        <input
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.referencePhone}
                          name="referencePhone"
                          onChange={handleInput}
                        />
                      </div>
                      {error.referencePhone && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.referencePhone}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.email}>
                    <label>Email :</label>
                    <div className={styles.inputWerror}>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.referenceEmail}
                        name="referenceEmail"
                        onChange={handleInput}
                      />
                      {error.referenceEmail && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.referenceEmail}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.UserDetail}>
              <div className={styles.close}>
                <h5>Add User</h5>
              </div>
              <div className={styles.radioOption}>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="userType"
                      value="new"
                      checked={!isSelectExistingUser}
                      onChange={addNewUser}
                    />
                    Add New User
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="userType"
                      value="existing"
                      checked={isSelectExistingUser}
                      onChange={addExistingUser}
                    />
                    Add Existing User
                  </label>
                </div>
              </div>
              {isSelectExistingUser ? (
                <div className={styles.existingUser}>
                  <div className={styles.email}>
                    <label>User Email</label>
                    <div className={styles.existingUserEmail}>
                      <input
                        type="email"
                        placeholder="example@gmail.com"
                        value={input.existingUserEmail}
                        name="existingUserEmail"
                        onChange={handleInput}
                      />
                      <Button className={styles.getUser} variant="contained" onClick={handleFetchUser}>
                        Get User
                      </Button>
                    </div>
                    {error.existingUserEmail && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {error.existingUserEmail}
                      </div>
                    )}
                    {error.successfull && (
                      <div className={styles.success}>
                        <CheckCircleOutlineIcon className={styles.successIcon} />
                        {error.successfull}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.newUser}>
                  <h3>Personal Information</h3>
                  <div className={styles.name}>
                    <div className={styles.initial}>
                      <FormControl sx={{ m: 1, margin: 0 }}>
                        <Select
                          value={input.superAdminInitial}
                          onChange={handleSelectChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label", name: "superAdminInitial" }}
                          sx={{
                            height: 49
                          }}
                        >
                          <MenuItem value="Mr">Mr.</MenuItem>
                          <MenuItem value="Mrs">Mrs.</MenuItem>
                          <MenuItem value="Miss">Miss.</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={styles.Fname}>
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.superAdminFirstName}
                        name="superAdminFirstName"
                        onChange={handleInput}
                      />
                      {error.userFirstName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.userFirstName}
                        </div>
                      )}
                    </div>
                    <div className={styles.Lname}>
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.superAdminLastName}
                        name="superAdminLastName"
                        onChange={handleInput}
                      />
                      {error.userLastName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.userLastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.userrow}>
                    <div className={styles.dob}>
                      <label>DOB:</label>
                      <input type="date" value={input.superAdminDob} name="superAdminDob" onChange={handleInput} />
                      {error.userDob && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.userDob}
                        </div>
                      )}
                    </div>
                    <div className={styles.email}>
                      <label>User Email</label>
                      <input
                        type="email"
                        placeholder="example@gmail.com"
                        value={input.superAdminEmail}
                        name="superAdminEmail"
                        onChange={handleInput}
                      />
                      {error.userEmail && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.userEmail}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.userrow}>
                    <div className={styles.gender}>
                      <label>Gender:</label>
                      <FormControl sx={{ m: 1, margin: 0 }}>
                        <Select
                          value={input.superAdminGender}
                          onChange={handleSelectChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label", name: "superAdminGender" }}
                          sx={{
                            height: 49
                          }}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="unknown">Non Binary</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className={styles.phone}>
                      <label>Contact</label>
                      <div className={styles.mobileSelect}>
                        <Select
                          value={input.superAdminPhoneCountryCode || "+61"}
                          onChange={handleCountryCodeChange}
                          displayEmpty
                          name="superAdminPhoneCountryCode"
                          className={styles.countryCodeSelect}
                          sx={{ height: "50px" }}
                        >
                          {countryCodeData.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                              {country.code}
                            </MenuItem>
                          ))}
                        </Select>
                        <input
                          type="text"
                          placeholder="4745625522"
                          value={input.superAdminPhone}
                          name="superAdminPhone"
                          onChange={handleInput}
                        />
                      </div>
                      {error.userPhone && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.userPhone}
                        </div>
                      )}
                    </div>
                  </div>
                  <UserNameInput
                    value={input.superAdminUserName}
                    setValue={(value) => {
                      setInput((prevValue) => ({
                        ...prevValue,
                        superAdminUserName: value
                      }));
                    }}
                    error={error.userName}
                    setError={(value: string) =>
                      setError({
                        ...error,
                        userName: value
                      })
                    }
                  />{" "}
                  <h3>Address</h3>
                  <div className={styles.userrow}>
                    <div className={styles.addressLine1}>
                      <label>Line 1 :</label>
                      <div className={styles.inputWerror}>
                        <input
                          type="text"
                          placeholder="Enter Text Here"
                          value={input.superAdminaddressLine1}
                          name="superAdminaddressLine1"
                          onChange={handleInput}
                        />
                        {error.useraddressLine1 && (
                          <div className={styles.error}>
                            <ErrorOutlineIcon className={styles.errIcon} />
                            {error.useraddressLine1}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.addressLine2}>
                      <label>Line 2 :</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={input.superAdminaddressLine2}
                        name="superAdminaddressLine2"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  {addOrgLoading ? (
                    <div></div>
                  ) : (
                    <>
                      <div className={styles.userrow}>
                        <div className={styles.city}>
                          <label>City :</label>
                          <div className={styles.inputWerror}>
                            <input
                              type="text"
                              placeholder="Enter Text Here"
                              value={input.superAdminCity}
                              name="superAdminCity"
                              onChange={handleInput}
                            />
                            {error.userCity && (
                              <div className={styles.error}>
                                <ErrorOutlineIcon className={styles.errIcon} />
                                {error.userCity}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.state}>
                          <label>State :</label>
                          <div className={styles.inputWerror}>
                            <input
                              type="text"
                              placeholder="Enter Text Here"
                              value={input.superAdminState}
                              name="superAdminState"
                              onChange={handleInput}
                            />
                            {error.userState && (
                              <div className={styles.error}>
                                <ErrorOutlineIcon className={styles.errIcon} />
                                {error.userState}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.userrow}>
                        <div className={styles.postcode}>
                          <label>Postcode :</label>
                          <div className={styles.inputWerror}>
                            <input
                              type="text"
                              placeholder="Enter Text Here"
                              value={input.superAdminPostcode}
                              name="superAdminPostcode"
                              onChange={handleInput}
                            />
                            {error.userPostcode && (
                              <div className={styles.error}>
                                <ErrorOutlineIcon className={styles.errIcon} />
                                {error.userPostcode}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles.country}>
                          <label>Country :</label>
                          <div className={styles.inputWerror}>
                            <input
                              type="text"
                              placeholder="Enter Text Here"
                              value={input.superAdminCountry}
                              name="superAdminCountry"
                              onChange={handleInput}
                            />
                            {error.userCountry && (
                              <div className={styles.error}>
                                <ErrorOutlineIcon className={styles.errIcon} />
                                {error.userCountry}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </form>
      </div>
      <div className={styles.modalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            {addUser ? (
              <>
                {""}
                {!currentStep ? (
                  <Button className={styles.sendBtn} variant="contained" onClick={handleNext}>
                    Next
                  </Button>
                ) : (
                  <>
                    <Button
                      className={styles.sendBtn}
                      variant="contained"
                      onClick={() => {
                        setCurrentStep(false);
                      }}
                    >
                      Back
                    </Button>
                    <Button className={styles.sendBtn} variant="contained" onClick={handleAddOrg}>
                      Submit
                    </Button>
                  </>
                )}
              </>
            ) : (
              <Button className={styles.sendBtn} variant="contained" onClick={handleAddOrg}>
                Submit
              </Button>
            )}
          </Stack>
        </div>
      </div>
    </div>
  );
}
