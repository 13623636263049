import { useEffect, useState } from "react";
import styles from "./budget.module.scss";
// import { option } from "./budgetConfig";
import { Select, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchActivityAction, fetchUserListAction, fetchWalletListAction } from "./budgetSlice";
import { Wallet } from "../wallets/walletSlice";
import { Transaction } from "../transactions/transactionSlice";
import { getDecimalNumber, getTransactionValue } from "src/utils/utils";

// type Transaction = {
//   id: number;
//   name: string;
//   amount: number;
// };

// type Wallet = {
//   id: number;
//   walletname: string;
//   amountToSpend: number;
//   recentTransaction: Transaction[];
// };

// type Participant = {
//   id: number;
//   participant: string;
//   wallet: Wallet[];
// };

export default function Budget() {
  const dispatch = useAppDispatch();
  const { users, wallets, transactions } = useAppSelector((state) => state.budget);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [selectedParticipantId, setSelectedParticipantId] = useState<number | string>("");
  const [selectedWalletId, setSelectedWalletId] = useState<number | string>("");
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);

  const handleParticipantChange = (event: SelectChangeEvent<number | string>) => {
    const participantId = event.target.value as number;

    setSelectedParticipantId(participantId);
    setSelectedWalletId("");
    setSelectedWallet(null);
  };

  const handleWalletChange = (event: SelectChangeEvent<number | string>) => {
    const walletId = event.target.value as number;

    setSelectedWalletId(walletId);
    const wallet = wallets?.list.find((w) => w.id === walletId) || null;

    setSelectedWallet(wallet);
  };

  const renderParticipantValue = (selected: number | string) => {
    if (selected === "") {
      return <Typography sx={{ color: "grey" }}>Select Participant</Typography>;
    }
    const participant = users?.list?.find((p) => p.userId === selected);

    return participant ? participant.firstName + " " + participant.lastName : "Select Participant";
  };

  const renderWalletValue = (selected: number | string) => {
    if (selected === "") {
      return <Typography sx={{ color: "grey" }}>Select Wallet</Typography>;
    }
    const wallet = wallets?.list?.find((w) => w.id === selected);

    return wallet ? wallet?.name : "Select Wallet";
  };

  useEffect(() => {
    dispatch(fetchUserListAction({ size: 50, from: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (users.list.length === 1) {
      setSelectedParticipantId(users?.list[0]?.userId);
    }
  }, [users]);

  useEffect(() => {
    if (selectedParticipantId) {
      dispatch(fetchWalletListAction(String(selectedParticipantId)));
    }
  }, [selectedParticipantId]);

  useEffect(() => {
    if (selectedWallet) {
      dispatch(
        fetchActivityAction({
          size: 5,
          from: 0,
          filter: {
            activityName: "NOVATTI_CARD_TRANSACTION",
            walletIds: [String(selectedWallet?.id)]
          },
          userFilter: {
            email: String(userInfo?.email)
          }
        })
      );
    }
  }, [dispatch, selectedWallet, userInfo?.email]);

  return (
    <div className={styles.container}>
      <div className={styles.walletContainer}>
        <div className={styles.selectContainer}>
          <div className={styles.participant}>
            <label>Select participant</label>
            <Select
              value={selectedParticipantId}
              onChange={handleParticipantChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={styles.select}
              renderValue={renderParticipantValue}
              sx={{ maxHeight: "100px" }}
            >
              <MenuItem value="" sx={{ color: "grey" }}>
                Select Participant
              </MenuItem>
              {users &&
                users?.list?.map((participant) => (
                  <MenuItem key={participant.userId} value={participant.userId}>
                    {`${participant.firstName} ${participant.lastName}`}
                  </MenuItem>
                ))}
            </Select>
          </div>
          {selectedParticipantId && (
            <div className={styles.wallet}>
              <label>Select Wallet</label>

              <Select
                value={selectedWalletId}
                onChange={handleWalletChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className={styles.select}
                renderValue={renderWalletValue}
              >
                <MenuItem value="" sx={{ color: "grey" }}>
                  Select Wallet
                </MenuItem>
                {wallets?.list?.map((wallet: Wallet) => (
                  <MenuItem key={wallet.id} value={wallet.id}>
                    {wallet?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
        {selectedWallet && (
          <div className={styles.amountCard}>
            <div className={styles.title}>Amount To Spend</div>
            <div className={styles.amount}>${getDecimalNumber(selectedWallet?.balance)}</div>
          </div>
        )}
      </div>
      {selectedWallet && (
        <div className={styles.lastTransaction}>
          <h3>Last 5 Transactions</h3>
          <div className={styles.transaction}>
            {transactions?.list?.map((transaction: Transaction) => (
              <div className={styles.transCard} key={transaction.id}>
                <div className={styles.payment}>
                  {getTransactionValue(transaction)?.value}
                  <div
                    className={styles.amount}
                    style={{
                      color: getTransactionValue(transaction)?.amountColor
                    }}
                  >
                    {getTransactionValue(transaction)?.amount}
                  </div>
                </div>
                <div className={styles.paymentdata}>
                  <div
                    className={styles.type}
                    style={{
                      color: transaction?.activityStatus === "APPROVED" ? "green" : "red"
                    }}
                  >
                    {transaction?.activityStatus}
                  </div>
                  <div className={styles.participant}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {transaction?.data?.transaction_user
                      ? "Made by " +
                        transaction?.data?.transaction_user?.attributes?.first_name +
                        " " +
                        transaction?.data?.transaction_user?.attributes?.last_name
                      : transaction?.data?.user
                        ? "Made by " +
                          transaction?.data?.user?.attributes?.first_name +
                          " " +
                          transaction?.data?.user?.attributes?.last_name
                        : "---"}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
