import Highlighter from "react-highlight-words";
import styles from "./highLighter.module.scss";

interface HighlighterInterface {
  searchText: string;
  text: string;
}

export default function HighLighter({ searchText, text }: HighlighterInterface) {
  return (
    <Highlighter
      highlightClassName={styles.highlight}
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={`${text}`}
    />
  );
}
