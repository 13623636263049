import { createSlice } from "@reduxjs/toolkit";
import store, { StoreDispatch } from "../store";
import {
  SearchUserRequest,
  getCardSetting,
  getUserCard,
  getUserWallets,
  searchUser
} from "src/network/graphql/userService";
import { initialWalletDetail } from "../walletDetail/walletDetailSlice";
import { getWalletDetail } from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";
import { SinglePayRequest, singlePay } from "src/network/graphql/paymentService";
import swal from "sweetalert2";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";
import { Transaction } from "../transactions/transactionSlice";
import { Users } from "../users/usersSlice";
import { PARTICIPANT, SUPPORTER } from "src/components/constant/constant";

export type CardSetting = {
  cardId: string;
  ppan: string;
  cardType: string;
  cardSetting: {
    cardId: string;
    ppan: string;
    walletId: string;
    expireAt: string;
    cardStatus: string;
    cardSettingId: string;
  };
};

// interface UserCardsState {
//   private: CardSetting | null;
//   shared: CardSetting | null;
// }

// const initialUserCard: UserCardsState = {
//   private: null,
//   shared: null
// };
// const initialUserCard: CardSetting = {
//   cardId: "",
//   ppan: "",
//   walletId: "",
//   expireAt: "",
//   cardStatus: ""
// };

const initialUserCard: CardSetting[] = [];

const initialWallets: Wallet[] = [];
const initialTransaction: Transaction[] = [];
const initialUsers: Users[] = [];

const homeSlice = createSlice({
  name: "home",
  initialState: {
    userCardLoading: false,
    activityLoading: false,
    userLoading: false,
    error: null,
    userCards: initialUserCard,
    walletDetail: initialWalletDetail,
    wallets: {
      list: initialWallets,
      total: 0
    },
    transactions: {
      list: initialTransaction,
      total: 0
    },
    users: {
      list: initialUsers,
      total: 0
    }
  },
  reducers: {
    getUserCardStart: (state) => {
      state.userCardLoading = true;
    },
    getUserCardSuccess: (state, action) => {
      state.userCardLoading = false;
      // state.userCards.private = action.payload?.private;
      // state.userCards.shared = action.payload?.shared;
      state.userCards = action.payload ?? [];
      state.error = null;
    },
    getUserCardFail: (state, action) => {
      state.userCardLoading = false;
      state.error = action.payload;
    },
    fetchWalletDetailStart: (state) => {
      state.userCardLoading = true;
    },
    fetchWalletDetailSuccess: (state, action) => {
      state.userCardLoading = false;
      state.walletDetail.wallet = action.payload.wallet ?? initialWalletDetail.wallet;
    },
    fetchWalletDetailFail: (state, action) => {
      state.userCardLoading = false;
      state.walletDetail = initialWalletDetail;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.userCardLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.userCardLoading = false;
      state.wallets = action.payload;
    },
    fetchWalletsFail: (state, action) => {
      state.userCardLoading = false;
      state.error = action.payload;
    },
    singlePayStart: (state) => {
      state.userCardLoading = true;
    },
    singlePaySuccess: (state) => {
      state.userCardLoading = false;
      state.error = null;
    },
    singlePayFail: (state, action) => {
      state.userCardLoading = false;
      state.error = action.payload;
    },
    fetchActivityStart: (state) => {
      state.activityLoading = true;
    },
    fetchActivitySuccess: (state, action) => {
      state.activityLoading = false;
      state.transactions = action.payload;
    },
    fetchActivityFail: (state, action) => {
      state.activityLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    }
  }
});

const {
  getUserCardStart,
  getUserCardSuccess,
  getUserCardFail,
  fetchWalletDetailStart,
  fetchWalletDetailSuccess,
  fetchWalletDetailFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  singlePayStart,
  singlePaySuccess,
  singlePayFail,
  fetchActivityStart,
  fetchActivitySuccess,
  fetchActivityFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail
} = homeSlice.actions;

export const getUserCardAction = (userId: string, organisationId: string, userRole: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getUserCardStart());
    try {
      const { auth } = store.getState();
      const loggedInUserId = auth.userInfo?.id;
      const response = await getUserCard(userId, organisationId);
      const cardSetting = await getCardSetting(String(auth?.userInfo?.id));

      const validCardSettingIds = cardSetting.data?.get_card_settings.map((setting: any) => setting?.card_setting_id);

      let cards = [];

      if (userRole === PARTICIPANT) {
        cards = response.data.get_user_cards?.cards
          .filter(
            (card: any) =>
              card?.card_setting !== null && validCardSettingIds.includes(card?.card_setting?.card_setting_id)
          )
          .map((card: any) => ({
            cardId: card?.card_id,
            ppan: card?.ppan,
            cardSetting: {
              cardId: card?.card_setting?.card_id,
              ppan: card?.card_setting?.ppan,
              walletId: card?.card_setting?.wallet_id,
              cardSettingId: card?.card_setting?.card_setting_id,
              expireAt: card?.card_setting?.expires_at
            }
          }));
      } else if (userRole === SUPPORTER) {
        if (userId === loggedInUserId) {
          cards = response.data.get_user_cards?.cards
            .filter(
              (card: any) =>
                card?.card_setting !== null && validCardSettingIds.includes(card?.card_setting?.card_setting_id)
            )
            .map((card: any) => ({
              cardId: card?.card_id,
              ppan: card?.ppan,
              cardSetting: {
                cardId: card?.card_setting?.card_id,
                ppan: card?.card_setting?.ppan,
                walletId: card?.card_setting?.wallet_id,
                cardSettingId: card?.card_setting?.card_setting_id,
                expireAt: card?.card_setting?.expires_at
              }
            }));
        } else {
          cards = response.data.get_user_cards?.cards
            .filter(
              (card: any) =>
                card?.card_setting !== null &&
                card?.card_type === "SHARED" &&
                validCardSettingIds.includes(card?.card_setting?.card_setting_id)
            )
            .map((card: any) => ({
              cardId: card?.card_id,
              ppan: card?.ppan,
              cardSetting: {
                cardId: card?.card_setting?.card_id,
                ppan: card?.card_setting?.ppan,
                walletId: card?.card_setting?.wallet_id,
                cardSettingId: card?.card_setting?.card_setting_id,
                expireAt: card?.card_setting?.expires_at
              }
            }));
        }
      }

      // if (cards) {
      dispatch(getUserCardSuccess(cards));
      if (cb) cb();
      // }
    } catch (err) {
      dispatch(getUserCardFail(err));
    }
  };
};

export const fetchWalletDetailAction = (data: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletDetailStart());
    try {
      const walletResponse = await getWalletDetail(data);
      const wallet = walletResponse.data.get_wallet;

      const structuredWalletResponse = {
        name: wallet.name,
        description: wallet.description,
        id: wallet.wallet_id,
        balance: wallet.balance,
        participant: {
          id: wallet.user.user_id,
          firstName: wallet.user.attributes.first_name,
          lastname: wallet.user.attributes.last_name
        }
      };

      dispatch(
        fetchWalletDetailSuccess({
          wallet: structuredWalletResponse
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(fetchWalletDetailFail(error));
    }
  };
};

export const fetchWalletListAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await getUserWallets(userId);

      const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        // status: wallet.wallet_status,
        name: wallet.name
        // participant: { role: "PARTICIPANT", fullName: wallet.description }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const singlePayAction = (data: SinglePayRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(singlePayStart());
    try {
      const response = await singlePay(data);

      dispatch(singlePaySuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Success! You're ready to pay",
        showConfirmButton: false,
        timer: 2500
      });
      return response;
    } catch (err) {
      dispatch(singlePayFail(err));
    }
  };
};

export const fetchActivityAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchActivityStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        data: JSON.parse(data.data)
      }));

      dispatch(
        fetchActivitySuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchActivityFail(err));
    }
  };
};

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        // user_filter: {
        //   is_active: data.isActive,
        //   email: data.email,
        //   first_name: data.firstName,
        //   last_name: data.lastName,
        //   organisation_id: data.organisationId
        // },
        user_filter: data.user_filter,
        size: data.size,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing
        .filter((user: any) => user.current_organisation_role === PARTICIPANT)
        .map((user: any) => ({
          id: user.display_id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.email,
          role: "not available",
          status: user.is_active,
          userId: user.user_id
        }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export default homeSlice.reducer;
