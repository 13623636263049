import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { immediateToast } from "izitoast-react";
import { AddOrgRequest, getOrganisationDetail, updateOrganisation } from "src/network/graphql/organisationService";
import { searchUser } from "src/network/graphql/userService";
import { Users } from "src/page/users/usersSlice";
import { cardSearch, CardSearchRequest } from "src/network/graphql/cardService";
import { Card } from "../cards/cardSlice";
import { SearchWalletRequest, searchWallet } from "src/network/graphql/walletService";
import { Transaction } from "../transactions/transactionSlice";

type orgDetail = {
  organisation: {
    id: number;
    organisationId: string;
    name: string;
    abn: string;
    address: string;
    phone: string;
    countryCode: string;
    website: string;
    facebook: string;
    linkedIn: string;
    instagram: string;
    referenceName: string;
    referenceRole: string;
    referenceCountryCode: string;
    referencePhone: string;
    referenceEmail: string;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    active: boolean;
  };
  users: {
    id: number;
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    guardianId: number;
    accessCode: string;
    fullName: string;
  }[];
  wallets: {
    id: number;
    balance: number;
    description: string;
    participant: {
      id: number;
      active: boolean;
      email: string;
      firstName: string;
      lastName: string;
      role: string;
      guardianId: number;
      accessCode: string;
      fullName: string;
    };
  }[];
  cards: {
    id: number;
    cardNumber: string;
    cardStatus: string;
    accessType: string;
    accountNumber: string;
    user: {
      id: number;
      fullName: string;
      email: string;
      role: string;
      firstName: string;
      lastName: string;
    };
  }[];
};
const initialOrgDetail: orgDetail = {
  organisation: {
    id: 0,
    organisationId: "",
    name: "",
    abn: "",
    address: "",
    phone: "",
    countryCode: "",
    website: "",
    facebook: "",
    linkedIn: "",
    instagram: "",
    referenceName: "",
    referenceRole: "",
    referenceCountryCode: "",
    referencePhone: "",
    referenceEmail: "",
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updatedOn: "",
    active: false
  },
  users: [],
  wallets: [],
  cards: []
};

const initialOrgUser: Users[] = [];

const initialTransaction: Transaction[] = [];

const orgDetailSlice = createSlice({
  name: "organisationDetails",
  initialState: {
    orgWalletLoading: false,
    orgCardLoading: false,
    organisationDetailLoading: false,
    orgUserLoading: false,
    transLoading: false,
    cardConnectLoading: false,
    error: null,
    orgDetail: initialOrgDetail,
    orgUser: initialOrgUser,
    transList: {
      trans: initialTransaction,
      total: 0
    }
  },
  reducers: {
    getOrgDetailStart: (state) => {
      state.organisationDetailLoading = true;
    },
    getOrgDetailSuccess: (state, action) => {
      state.orgDetail.organisation = action.payload ?? initialOrgDetail;
      state.organisationDetailLoading = false;
    },
    getOrgDetailFail: (state, action) => {
      state.organisationDetailLoading = false;
      state.orgDetail = initialOrgDetail;
      state.error = action.payload;
    },
    getOrgUserStart: (state) => {
      state.orgUserLoading = true;
    },
    getOrgUserSuccess: (state, action) => {
      state.orgUserLoading = false;
      state.orgUser = action.payload ?? initialOrgUser;
    },
    getOrgUserFail: (state, action) => {
      state.orgUserLoading = false;
      state.orgUser = initialOrgUser;
      state.error = action.payload;
    },
    getOrgWalletStart: (state) => {
      state.orgWalletLoading = true;
    },
    getOrgWalletSuccess: (state, action) => {
      state.orgWalletLoading = false;
      state.orgDetail.wallets = action.payload ?? [];
    },
    getOrgWalletFail: (state, action) => {
      state.orgWalletLoading = false;
      state.orgDetail.wallets = [];
      state.error = action.payload;
    },
    getOrgCardStart: (state) => {
      state.orgCardLoading = true;
    },
    getOrgCardSuccess: (state, action) => {
      state.orgCardLoading = false;
      state.orgDetail.cards = action.payload ?? [];
    },
    getOrgCardFail: (state, action) => {
      state.orgCardLoading = false;
      state.orgDetail.cards = [];
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    updateOrgStart: (state) => {
      state.organisationDetailLoading = true;
    },
    updateOrgSuccess: (state) => {
      state.organisationDetailLoading = false;
    },
    updateOrgFail: (state, action) => {
      state.organisationDetailLoading = false;
      state.error = action.payload;
    },
    addCardStart: (state) => {
      state.cardConnectLoading = true;
    },
    addCardSuccess: (state) => {
      state.cardConnectLoading = false;
      state.error = null;
    },
    addCardFail: (state, action) => {
      state.cardConnectLoading = false;
      state.error = action.payload;
    }
  }
});
const {
  getOrgDetailStart,
  getOrgDetailSuccess,
  getOrgDetailFail,
  getOrgUserStart,
  getOrgUserSuccess,
  getOrgUserFail,
  updateOrgStart,
  updateOrgSuccess,
  updateOrgFail,
  getOrgCardStart,
  getOrgCardSuccess,
  getOrgCardFail,
  getOrgWalletStart,
  getOrgWalletSuccess,
  getOrgWalletFail
} = orgDetailSlice.actions;

export const getOrgDetailAction = (id: number | string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgDetailStart());
    try {
      const response = await getOrganisationDetail(id);

      const organisation = response.data.get_organisation;
      const structuredOrganisation = {
        id: organisation.display_id,
        organisationId: organisation.organisation_id,
        name: organisation.name,
        phone: organisation.phone,
        abn: organisation.abn,
        referenceRole: organisation.contact.role,
        address: organisation.address.city,
        active: organisation.is_active,
        referencePhone: organisation.contact.phone,
        referenceEmail: organisation.contact.email,
        refereneRole: organisation.contact.role,
        referenceName: organisation.contact.name,
        linkedIn: organisation.social?.linkedin,
        facebook: organisation.social?.facebook,
        instagram: organisation.social?.instagram,
        website: organisation.social?.website,
        city: organisation.address.city,
        line1: organisation.address.line_1,
        line2: organisation.address.line_2,
        state: organisation.address.state,
        postcode: organisation.address.post_code,
        country: organisation.address.country
      };

      dispatch(getOrgDetailSuccess(structuredOrganisation));
    } catch (error) {
      dispatch(getOrgDetailFail(error));
    }
  };
};

export const orgCardListAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgCardStart());
    try {
      const response = await cardSearch(data);
      const structuredResponse = response.data.search_card.card_listing?.map(
        (card: any) =>
          ({
            id: card.card_id,
            bsb: card.bsb,
            cardStatus: card.card_status,
            accessType: card.card_type,
            cardNumber: card.ppan,
            cardLimit: card.card_limit,
            user: {
              id: card.user_id,
              fullName: card.holder_name,
              firstName: card.holder_name,
              lastName: ""
            }
          }) as Card
      );

      dispatch(getOrgCardSuccess(structuredResponse));
    } catch (error) {
      dispatch(getOrgCardFail(error));
    }
  };
};

export const orgWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgWalletStart());
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        description: wallet.description,
        balance: wallet.balance,
        participant: {
          role: "PARTICIPANT",
          firstName: wallet.name,
          lastName: ""
        }
      }));

      dispatch(getOrgWalletSuccess(structuredResponse));
    } catch (error) {
      dispatch(getOrgWalletFail(error));
    }
  };
};

export const orgUserListAction = (organisationDisplayId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgUserStart());
    try {
      const response = await searchUser({
        user_filter: {
          organisationDisplayId
          // is_active: data.isActive
          // TODO - remove this after BE changes
          // organisation_id: data.organisationId
        },
        size: 5,
        from: 0
      });

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user.user_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        role: "not available",
        status: user.is_active,
        userId: user.user_id
      }));

      dispatch(getOrgUserSuccess(structuredResponse));
    } catch (error) {
      dispatch(getOrgUserFail(error));
    }
  };
};

export const fetchTransListAction = (data: any) => {
  return data;
};

export const updateOrgAction = (data: AddOrgRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateOrgStart());
    try {
      await updateOrganisation(data);

      dispatch(updateOrgSuccess());
      immediateToast("success", {
        message: "Organisation Info Updated Successfully.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
    } catch (error) {
      dispatch(updateOrgFail(error));
    }
  };
};

export const addCardAction = (...args: any[]) => {
  return Promise.resolve(args);
};

export default orgDetailSlice.reducer;
