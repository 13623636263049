import { useState } from "react";
import styles from "./recentrans.module.scss";
import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./transTable/column";
import { Link } from "react-router-dom";
import TransactionEditModal from "../transactionList/transactionEditModal/TransactionEditModal";
import Modalui from "../UI/modal/Modalui";
import { CAN_EDIT_TRANSACTION } from "../constant/constant";
import { Transaction } from "src/page/transactions/transactionSlice";
import { checkPermission } from "src/utils/utils";

// interface Transaction {
//   id: number;
//   wallet: { id: number; description: string; participant: { fullName: string } };
//   amount: number;
//   transactionDate?: number;
//   updatedAt?: number;
//   createdAt?: number;
//   merchantName?: string;
//   date?: number;
// }

interface RecentTransProps {
  transList: Transaction[];
  setSearchText?: (_value: string) => void;
  setReceiptPresent: (_value: null | boolean) => void;
  setIsReviewed: (_value: null | boolean) => void;
  userId?: string;
  walletId?: string;
  organisationId?: string;
  role?: string;
  recentTransOver?: string;
  subTitle?: string;
  page?: string;
}
function RecentTrans({
  transList,
  // setSearchText,
  // setReceiptPresent,
  // setIsReviewed
  userId,
  walletId,
  organisationId,
  role,
  recentTransOver,
  subTitle,
  page
}: RecentTransProps) {
  // const [selectedButton, setSelectedButton] = useState(1);
  const [selectedTranId, setSelectedTransId] = useState<Transaction>();
  const [open, setOpen] = useState(false);

  let linkPath = "/transactions";

  if (userId) {
    linkPath = `/users/${userId}/transactions`;
  } else if (walletId) {
    linkPath = `/wallets/${walletId}/transactions`;
  } else if (organisationId) {
    linkPath = `/organisation/${organisationId}/transactions`;
  }

  // const handleSearch = (value: string) => {
  //   setSearchText(value);
  // };

  // const handleButtonClick = (index: number) => {
  //   setSelectedButton(index);
  //   switch (index) {
  //     case 1:
  //       setReceiptPresent(null);
  //       setIsReviewed(null);
  //       break;
  //     case 2:
  //       setReceiptPresent(false);
  //       setIsReviewed(null);
  //       break;
  //     case 3:
  //       setReceiptPresent(null);
  //       setIsReviewed(true);

  //       break;
  //     default:
  //       setReceiptPresent(null);
  //       setIsReviewed(null);
  //   }
  // };

  const handleOpen = (data: Transaction) => {
    setOpen(true);
    setSelectedTransId(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let operation;

  if (page != "Dashboard" && checkPermission(CAN_EDIT_TRANSACTION)) {
    operation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: Transaction) => (
        <div onClick={() => handleOpen(record)} className={styles.operation}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      width: 100
    };
  } else {
    operation = {};
  }

  const updatedColumns = [...columns({ walletId, userId }), operation];

  return (
    <>
      {selectedTranId && (
        <Modalui open={open} handleClose={handleClose}>
          <TransactionEditModal transaction={selectedTranId} handleClose={handleClose} />
        </Modalui>
      )}
      <div className={`${styles.recentTrans} ${recentTransOver}`}>
        <div className={styles.recentTransIn}>
          <div className={styles.recentTransHead}>
            <div className={styles.recentTransHeadIn}>
              <div className={styles.recentTransHeadD}>
                <h3>Recent Transactions</h3>
                <p>{subTitle}</p>
              </div>
              <Link to={linkPath} state={{ role: role }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#1e293b",
                    height: "34px",
                    width: "82px",
                    fontSize: "13px",
                    fontWeight: "600",
                    lineHeight: "normal",
                    margin: "0",
                    padding: "0"
                  }}
                >
                  View All
                </Button>
              </Link>
            </div>
            {/* <div className={styles.recentTransFilter}> */}
            {/* <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button
                  style={{
                    color: selectedButton === 1 ? "white" : "",
                    backgroundColor: selectedButton === 1 ? "#0f172ae8" : ""
                  }}
                  onClick={() => handleButtonClick(1)}
                >
                  All
                </Button>
                <Button
                  style={{
                    color: selectedButton === 2 ? "white" : "",
                    backgroundColor: selectedButton === 2 ? "#0f172ae8" : ""
                  }}
                  onClick={() => handleButtonClick(2)}
                >
                  No Reciept
                </Button>
                <Button
                  style={{
                    color: selectedButton === 3 ? "white" : "",
                    backgroundColor: selectedButton === 3 ? "#0f172ae8" : ""
                  }}
                  onClick={() => handleButtonClick(3)}
                >
                  Reviewed
                </Button>
              </ButtonGroup> */}
            {/* <div className={styles.recentTranSearch}>
              <SearchBar onSearch={handleSearch} />
            </div> */}
            {/* </div> */}
          </div>
          <div className={styles.recentTranTable}>
            {transList && transList.length > 0 ? (
              <Table columns={updatedColumns} data={transList} />
            ) : (
              <div className={styles.noData}>
                <p>No Transaction</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentTrans;
