import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./connectWalletModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchOrgListAction,
  fetchWalletConnectListAction,
  setOrgListAction,
  setWalletConnectAction
} from "src/page/wallets/walletSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";

export type WalletIn = {
  walletId: number;
  organisationId: number;
};
interface ConnectWalletModalProps {
  handleClose: () => void;
  onConnectWallet: (_addWallet: WalletIn, _close: boolean) => void;
}

export default function ConnectWalletModal({ handleClose, onConnectWallet }: ConnectWalletModalProps) {
  const { walletConnectList, orgLists } = useAppSelector((Rstate) => Rstate.wallet);
  const { config } = useAppSelector((Rstate) => Rstate.auth);
  const dispatch = useAppDispatch();
  const { organisationId } = useParams();

  const [walletId, setWalletId] = useState<number>(0);
  const [walletDesc, setWalletDesc] = useState<string>("");
  const [orgId, setOrgId] = useState<number>(0);
  const [orgName, setOrgName] = useState<string>("");
  const [error, setError] = useState({ walletDesc: "", orgName: "" });

  const handleCancel = () => {
    handleClose();
    if (!config?.currentOrganisation?.id || !organisationId) {
      dispatch(setWalletConnectAction([]));
      dispatch(setOrgListAction([]));
    }
  };

  const handleSave = () => {
    if (!walletDesc.trim() || !orgName.trim()) {
      setError({
        walletDesc: walletDesc ? "" : "Wallet is required",
        orgName: orgName ? "" : "Organisation is required"
      });
      return;
    }

    const walletData = {
      walletId,
      organisationId: orgId
    };

    onConnectWallet(walletData, true);
  };

  const fetchWalletOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];
    const data = {
      page: 0,
      limit: 20,
      searchText: inputValue
    };

    dispatch(fetchWalletConnectListAction(data));
  }, 500);

  const handleWalletInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchWalletOptions(newInputValue.toUpperCase());
  };
  const fetchOrgOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];
    const data = {
      page: 0,
      limit: 20,
      searchText: inputValue
    };

    dispatch(fetchOrgListAction(data));
  }, 500);

  const handleOrgInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchOrgOptions(newInputValue.toUpperCase());
  };

  useEffect(() => {
    dispatch(setWalletConnectAction([]));
    dispatch(setOrgListAction([]));

    if (organisationId) {
      dispatch(fetchOrgListAction({ page: 0 }));
    }
  }, [dispatch, organisationId]);

  useEffect(() => {
    if (organisationId && orgLists.list.length > 0) {
      const matchingOrg = orgLists.list.find((org) => org.id === Number(organisationId));

      if (matchingOrg) {
        setOrgId(matchingOrg.id);
        setOrgName(matchingOrg.name);
      }
    } else if (config?.currentOrganisation?.id) {
      setOrgId(Number(config?.currentOrganisation?.id));
      setOrgName(config?.currentOrganisation?.name);
    }
  }, [orgLists.list, organisationId, config?.currentOrganisation?.id]);

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect Wallet</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.text}>
            <label>Wallet</label>
            <Autocomplete
              options={walletConnectList.list}
              getOptionLabel={(option) => `${option?.description}`}
              onInputChange={handleWalletInputChange}
              onChange={(event, newValue) => {
                setWalletId(newValue?.id || 0);
                setWalletDesc(newValue?.description || "");
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    {option?.id}
                    {") "}
                    <div>
                      <div>{option?.participant?.fullName}</div>
                      <div>{option?.description}</div>
                    </div>
                  </div>
                </li>
              )}
              ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
            />
            {error.walletDesc && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.walletDesc}
              </div>
            )}
          </div>
          <div className={styles.participant}>
            <label>Organisation</label>
            {config?.currentOrganisation?.id || organisationId ? (
              <input
                type="text"
                placeholder="Enter Text Here"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                style={{
                  backgroundColor: "#8080803b"
                }}
                disabled
              />
            ) : (
              <Autocomplete
                options={orgLists.list}
                getOptionLabel={(option) => `${option?.name}`}
                onInputChange={handleOrgInputChange}
                onChange={(event, newValue) => {
                  setOrgId(newValue?.id || 0);
                  setOrgName(newValue?.name || "");
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>
                      {option?.id}
                      {") "}
                      {option?.name}
                    </span>
                  </li>
                )}
                ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
              />
            )}
            {error.orgName && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.orgName}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleSave}>
              Connect
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
