import type { CognitoConfig, HostPrefix } from "./Config";

const cognitoConfig: {
  [key in HostPrefix]: CognitoConfig;
} = {
  sso: {
    Auth: {
      clientId: "5g1lkqmpgob8n7vdibqb2kjk4r",
      clientSecret: "1cv08uf1tma9ahbjqab0ucl446dag4sdkdq4v2a9mr6jbgut8u9f",
      basic: "Basic NWcxbGtxbXBnb2I4bjd2ZGlicWIya2prNHI6"
    },
    Pool: {
      poolId: "ap-southeast-2_v0iGo3F8T"
    },
    baseUrl: "https://sso-sandbox-2.auth.ap-southeast-2.amazoncognito.com",
    redirectUrl: "https://sso.gimmeit.net.au/auth-sign-in",
    oauthProvider:
      "https://sso-sandbox-2.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=5g1lkqmpgob8n7vdibqb2kjk4r&response_type=code&redirect_uri=https%3A%2F%2Fsso.gimmeit.net.au%2Fauth-sign-in"
  },
  ssoLocal: {
    Auth: {
      clientId: "5g1lkqmpgob8n7vdibqb2kjk4r",
      clientSecret: "1cv08uf1tma9ahbjqab0ucl446dag4sdkdq4v2a9mr6jbgut8u9f",
      basic: "Basic NWcxbGtxbXBnb2I4bjd2ZGlicWIya2prNHI6"
    },
    Pool: {
      poolId: "ap-southeast-2_v0iGo3F8T"
    },
    baseUrl: "https://sso-sandbox-2.auth.ap-southeast-2.amazoncognito.com",
    redirectUrl: "http://localhost:3000/auth-sign-in",
    oauthProvider:
      "https://sso-sandbox-2.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=5g1lkqmpgob8n7vdibqb2kjk4r&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth-sign-in"
  },
  possibility: {
    Auth: {
      clientId: "7vvhcl9o0p63ha7d6hvbcl2mkq",
      clientSecret: "hto9v19em67rot46upthb01c8ciuj10mlh06g5mt9bts0dc1dfv",
      basic:
        "Basic N3Z2aGNsOW8wcDYzaGE3ZDZodmJjbDJta3E6aHRvOXYxOWVtNjdyb3Q0NnVwdGhiMDFjOGNpdWoxMG1saDA2ZzVtdDlidHMwZGMxZGZ2"
    },
    Pool: {
      poolId: "ap-southeast-2_r94GM8hmq"
    },
    baseUrl: "https://cognito.gimmeit.net.au",
    redirectUrl: "http://localhost:3000",
    oauthProvider:
      "https://cognito.gimmeit.net.au/oauth2/authorize?client_id=7vvhcl9o0p63ha7d6hvbcl2mkq&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000"
  },
  hub: {
    Auth: {
      clientId: "7vvhcl9o0p63ha7d6hvbcl2mkq",
      clientSecret: "hto9v19em67rot46upthb01c8ciuj10mlh06g5mt9bts0dc1dfv",
      basic:
        "Basic N3Z2aGNsOW8wcDYzaGE3ZDZodmJjbDJta3E6aHRvOXYxOWVtNjdyb3Q0NnVwdGhiMDFjOGNpdWoxMG1saDA2ZzVtdDlidHMwZGMxZGZ2"
    },
    Pool: {
      poolId: "ap-southeast-2_r94GM8hmq"
    },
    baseUrl: "https://cognito.gimmeit.net.au",
    redirectUrl: "http://localhost:3000",
    oauthProvider:
      "https://cognito.gimmeit.net.au/oauth2/authorize?client_id=7vvhcl9o0p63ha7d6hvbcl2mkq&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000"
  },
  production: {
    Auth: {
      clientId: "7vvhcl9o0p63ha7d6hvbcl2mkq",
      clientSecret: "hto9v19em67rot46upthb01c8ciuj10mlh06g5mt9bts0dc1dfv",
      basic:
        "Basic N3Z2aGNsOW8wcDYzaGE3ZDZodmJjbDJta3E6aHRvOXYxOWVtNjdyb3Q0NnVwdGhiMDFjOGNpdWoxMG1saDA2ZzVtdDlidHMwZGMxZGZ2"
    },
    Pool: {
      poolId: "ap-southeast-2_r94GM8hmq"
    },
    baseUrl: "https://cognito.gimmeit.net.au",
    redirectUrl: "http://localhost:3000",
    oauthProvider:
      "https://cognito.gimmeit.net.au/oauth2/authorize?client_id=7vvhcl9o0p63ha7d6hvbcl2mkq&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000"
  }
};

export default cognitoConfig;
