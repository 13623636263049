import styles from "./newPayment.module.scss";
// import GroupsIcon from "@mui/icons-material/Groups";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TollIcon from "@mui/icons-material/Toll";
import { useState } from "react";
import { PARTICIPANT } from "src/components/constant/constant";
import SinglePay from "src/components/newPaymentCards/SinglePay";
import { useAppSelector } from "../store";
import InternalPay from "src/components/newPaymentCards/InternalPay";
// import GroupPay from "src/components/newPaymentCards/GroupPay";

export default function NewPayments() {
  const { userInfo, cognitoConfig } = useAppSelector((state) => state.auth);
  const [singlePay, setSinglePay] = useState<boolean>(false);
  // const [groupPay, setGroupPay] = useState<boolean>(false);
  const [internalPay, setInternalPay] = useState<boolean>(false);

  const handleSinglePay = (value: boolean) => {
    setSinglePay(value);
  };

  const handleInternalPay = () => {
    setInternalPay(false);
  };

  return (
    <div className={styles.container}>
      {singlePay || internalPay ? (
        <>
          {singlePay && <SinglePay onClose={handleSinglePay} />}
          {/* {groupPay && <GroupPay />} */}
          {internalPay && <InternalPay onClose={handleInternalPay} />}
        </>
      ) : (
        <div className={styles.newPaymentButton}>
          <div
            className={styles.singlePayOp}
            onClick={() => {
              setSinglePay(true);
            }}
          >
            <CreditCardIcon />
            <p>{userInfo?.role === PARTICIPANT ? "Pay for a Expense" : "Pay for a participant / Expense"}</p>
          </div>
          {/* <div
            className={styles.groupPayOp}
            onClick={() => {
              setGroupPay(true);
            }}
          >
            <GroupsIcon />
            <p>Pay for a group of participants</p>
          </div> */}
          {cognitoConfig?.params?.payment_template === "PAYMENT_V1" && (
            <div
              className={styles.moneyBtwWallet}
              onClick={() => {
                setInternalPay(true);
              }}
            >
              <TollIcon />
              <p>Transfer money between wallets</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
