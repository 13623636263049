import { CircularProgress } from "@mui/material";
import styles from "./circularLoader.module.scss";

function CircularLoader() {
  return (
    <td className={styles.progress}>
      <CircularProgress />
    </td>
  );
}

export default CircularLoader;
