import { useState, useEffect, ChangeEvent } from "react";
import styles from "./walletDetail.module.scss";
// import SettingCard from "src/components/SettingCard/SettingCard";
import ConnectCard from "src/components/connectCard/ConnectCard";
import RecentTrans from "src/components/recentTrans/RecentTrans";
import WalletTransCard from "src/components/walletTransCard/WalletTransCard";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Input, MenuItem, Select, SelectChangeEvent, Slider } from "@mui/material";
import Modalui from "src/components/UI/modal/Modalui";
import SupporterModal from "src/components/connectCard/supporterModal/SupporterModal";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchTransListAction,
  fetchWalletDetailAction,
  fetchWalletTransactionAction,
  addWalletSupporterAction,
  deleteWalletSupporterAction,
  addCardAction,
  updateWalletAction,
  deleteWalletCardsAction,
  changePreferenceAction
} from "./walletDetailSlice";
import { walletTransCardLogo, whiteCardLogo, whiteFourPillarLogo, receiptLogo } from "src/components/nav/logo";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
// import DepositCard from "src/components/depositCard/DepositeCard";
// import { CAN_CONNECT_USER_TO_WALLET, CAN_CONNECT_CARD_TO_WALLET } from "src/components/constant/constant";
import AttachCard from "src/components/attachCard/AttachCard";
import IOSSwitch from "src/components/SettingCard/IOSSwitch";
import { toggleDefaultWallet, toggleWalletActivation } from "src/network/graphql/walletService";
import { SpendFrequency } from "src/types/commonTypes";
import {
  CAN_CONNECT_CARD_TO_WALLET,
  CAN_CONNECT_USER_TO_WALLET,
  CAN_UPDATE_WALLET_SETTINGS
} from "src/components/constant/constant";
import { immediateToast } from "izitoast-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { checkPermission, getDecimalNumber } from "src/utils/utils";

export default function WalletPage() {
  const { transList, walletDetail, walletTransaction, walletDetailLoading } = useAppSelector(
    (state) => state.walletDetail
  );
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [editWalletInfo, setEditWalletInfo] = useState<boolean>(false);
  const [cardModal, setCardModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const { walletId } = useParams();

  const [isDefaultWallet, setIsDefaultWallet] = useState<boolean>(false);
  const [isActiveChecked, setIsActiveChecked] = useState<boolean>(false);
  const [spendFreq, setSpendFreq] = useState<SpendFrequency>("NONE");
  const [allowedLimit, setAllowedLimit] = useState<number>(0);
  const [spendLimit, setSpendLimit] = useState<number>(0);
  const [editPreference, setEditPreference] = useState<boolean>(false);
  const [cashWithdrwawal, setCashWithdrawal] = useState<boolean>(false);
  const [walletPreferenceError, setWalletPreferenceError] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (walletId) {
      dispatch(fetchWalletDetailAction(walletId));
    }
  }, [dispatch, walletId, isActiveChecked]);

  useEffect(() => {
    if (walletDetail) {
      setName(walletDetail?.wallet?.name);
      setDescription(walletDetail?.wallet?.description);
      setIsActiveChecked(walletDetail?.wallet?.status);
      setIsDefaultWallet(walletDetail?.wallet?.defaultWallet);
      setSpendFreq((walletDetail?.wallet?.preferences?.spendFrequency as SpendFrequency) || "NONE");
      setAllowedLimit(walletDetail?.wallet?.preferences?.maxAllowedLimit);
      setSpendLimit(walletDetail?.wallet?.preferences?.spendLimit);
      setCashWithdrawal(walletDetail?.wallet?.preferences?.cashWithdrawalAllowed || false);
    }
  }, [walletDetail]);

  useEffect(() => {
    if (walletDetail) {
      dispatch(
        fetchTransListAction({
          size: 5,
          from: 0,
          filter: {
            walletIds: [String(walletDetail?.wallet?.id)]
          }
        })
      );
    }
  }, [searchText, receiptPresent, isReviewed, dispatch, walletDetail]);

  useEffect(() => {
    if (walletId) {
      fetchWalletTransactionAction({ walletIds: [Number(walletId)] });
    }
  }, [walletId]);

  const handleDefaultWalletToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsDefaultWallet(event.target.checked);
    if (walletId) {
      if (!isDefaultWallet) {
        if (!walletDetail?.wallet?.status) {
          setIsDefaultWallet(!event.target.checked);
          immediateToast("error", {
            message: "You cannot set an inactive wallet as the default",
            timeout: 3000,
            position: "topCenter"
          });
        } else {
          const response = await toggleDefaultWallet(walletId);

          if (response.data.errors) {
            setIsDefaultWallet(!event.target.checked);
          } else {
            setIsDefaultWallet(response.data.set_default_wallet.is_default ?? false);
          }
        }
      } else {
        setIsDefaultWallet(!event.target.checked);
        immediateToast("error", {
          message: "Can't disable default wallet ",
          timeout: 3000,
          position: "topCenter"
        });
      }
    }
  };

  const handleActiveToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsActiveChecked(event.target.checked);
    if (walletId) {
      const response = await toggleWalletActivation(walletId, event.target.checked);

      if (response.data.errors) {
        setIsActiveChecked(!event.target.checked);
      } else {
        setIsActiveChecked(response.data.toggle_wallet_activation.wallet_status === "ACTIVE");
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardModal = () => {
    setCardModal(!cardModal);
  };

  const handleAddSupporter = (email: string, userId: string, close: boolean, applyToAll: boolean) => {
    if (walletId) {
      dispatch(addWalletSupporterAction(walletId, userId, applyToAll)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });

      if (close) {
        handleClose();
      }
    }
  };

  const handleAddCard = (cardNumber: string, cardId: string, close: boolean, applyToAll: boolean) => {
    if (walletId) {
      dispatch(addCardAction(walletId, cardId, applyToAll)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });

      if (close) {
        setCardModal(false);
      }
    }
  };

  const handleDeleteSupporter = (email?: string, supporterId?: string, removeItFromAll?: boolean) => {
    if (walletId && supporterId) {
      dispatch(deleteWalletSupporterAction(walletId, supporterId, removeItFromAll || false)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });
    }
  };

  const handleDeleteCard = (cardNumber?: string, cardId?: string, removeItFromAll?: boolean) => {
    if (walletId && cardId) {
      dispatch(deleteWalletCardsAction(walletId, cardId, removeItFromAll)).then(() => {
        dispatch(fetchWalletDetailAction(walletId!));
      });
    }
  };

  const WalletTransCardOver = styles.WalletTransCardOver;
  const balCardOver = styles.balCardOver;

  const walletConnectHeading = "Users Connect";
  const deleteCardInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Wallet Card?"
  };

  const deleteSupporterInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Supporter?"
  };

  const handleWalletUpdate = () => {
    setEditWalletInfo(false);
    if (walletId) {
      dispatch(
        updateWalletAction({
          description,
          name,
          wallet_id: walletId
        })
      );
    }
  };

  const handleCashWithdrawalToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    setCashWithdrawal(event.target.checked);
  };

  const handleSpenFreqChange = (event: SelectChangeEvent) => {
    setSpendFreq(event.target.value as SpendFrequency);
  };

  const handleAllowedLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllowedLimit(parseInt(event.target.value));
  };

  const handleSpendLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSpendLimit(parseInt(event.target.value));
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setAllowedLimit(newValue as number);
  };

  const handleSliderLimitChange = (event: Event, newValue: number | number[]) => {
    setSpendLimit(newValue as number);
  };

  const handleBlur = () => {
    if (allowedLimit < 0) {
      setAllowedLimit(0);
    } else if (allowedLimit > 1000) {
      setAllowedLimit(1000);
    }
  };

  const handleLimitBlur = () => {
    if (allowedLimit < 0) {
      setSpendLimit(0);
    } else if (allowedLimit > 1000) {
      setSpendLimit(1000);
    }
  };

  const handleWalletPreference = () => {
    if (walletId) {
      if (!allowedLimit || !spendLimit) {
        setWalletPreferenceError("Please set all spending limits before submitting.");
        return;
      }

      const data = {
        cashWithdrawalAllowed: cashWithdrwawal,
        maxAllowedLimit: allowedLimit,
        spendFrequency: spendFreq,
        spendLimit: spendLimit,
        walletId
      };

      dispatch(changePreferenceAction(data)).then(() => {
        dispatch(fetchWalletDetailAction(walletId));
        setWalletPreferenceError("");
        setEditPreference(false);
      });
    }
  };

  return (
    <>
      <Modalui open={open} handleClose={handleClose}>
        <SupporterModal
          handleClose={handleClose}
          participantName={walletDetail.wallet.description}
          organisationId={walletDetail?.wallet?.organisationId}
          onAddSupporter={handleAddSupporter}
        />
      </Modalui>
      <Modalui open={cardModal} handleClose={handleCardModal}>
        <AttachCard
          handleClose={handleCardModal}
          participantName={walletDetail.wallet.description}
          organisationId={walletDetail?.wallet?.organisationId}
          onAddSupporter={handleAddCard}
        />
      </Modalui>
      <div className={styles.walletPage}>
        {walletDetailLoading ? <LoadingModal /> : ""}
        <div className={styles.walletPageWelCard}>
          {walletDetail && walletDetail?.wallet && (
            <PersonWelCard
              id={walletDetail.wallet.id}
              img={walletDetail.wallet?.name?.charAt(0).toLocaleUpperCase()}
              name={walletDetail.wallet?.name}
              email={walletDetail.wallet.participant?.firstName + " " + walletDetail.wallet.participant?.lastname} // name
              firstName={""}
              lastName={""}
            />
          )}
        </div>
        <div className={styles.userPageHead}>
          <div className={styles.infoCard}>
            <div className={styles.head}>
              <div className={styles.headL}>
                <h3>Wallet information</h3>
                <p>Edit the information about your wallet.</p>
              </div>
              {editWalletInfo ? (
                <Button className={styles.saveBtn} variant="contained" onClick={handleWalletUpdate}>
                  Save
                </Button>
              ) : (
                <div className={styles.headR} onClick={() => setEditWalletInfo(true)}>
                  <EditIcon className={styles.editIcon} />
                </div>
              )}
            </div>
            <div className={styles.walletInfo}>
              <div className={styles.label}>
                Name :
                <span className={styles.value}>
                  {editWalletInfo ? <input type="text" value={name} onChange={(e) => setName(e.target.value)} /> : name}
                </span>
              </div>
              <div className={styles.label}>
                Description :
                <span className={styles.value}>
                  {editWalletInfo ? (
                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                  ) : (
                    description
                  )}
                </span>
              </div>
              <div className={styles.label}>
                Balance :<span className={styles.value}>${getDecimalNumber(walletDetail?.wallet?.balance)}</span>
              </div>
              <div className={styles.label}>
                Active :
                <FormGroup className={styles.formGroup} style={{ paddingTop: "4px", marginLeft: "4px" }}>
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={<IOSSwitch sx={{ m: 1 }} checked={isActiveChecked} onChange={handleActiveToggle} />}
                    label=""
                  />
                </FormGroup>
              </div>
              <div className={styles.label}>
                Default :
                <FormGroup className={styles.formGroup} style={{ paddingTop: "4px", marginLeft: "4px" }}>
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={<IOSSwitch sx={{ m: 1 }} checked={isDefaultWallet} onChange={handleDefaultWalletToggle} />}
                    label=""
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className={styles.preferenceCard}>
            <div className={styles.head}>
              <div className={styles.headL}>
                <h3>Wallet preference</h3>
                <p>Edit the prefernce fo your wallet.</p>
              </div>
              {editPreference ? (
                <Button className={styles.saveBtn} variant="contained" onClick={handleWalletPreference}>
                  Save
                </Button>
              ) : (
                <div className={styles.headR}>
                  {checkPermission(CAN_UPDATE_WALLET_SETTINGS) && (
                    <EditIcon
                      className={styles.editIcon}
                      onClick={() => {
                        setEditPreference(true);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className={styles.preferenceInfo}>
              <div className={styles.label}>
                Cash Withdrawal :
                <FormGroup className={styles.formGroup} style={{ paddingTop: "4px", marginLeft: "4px" }}>
                  <FormControlLabel
                    disabled={!editPreference}
                    className={styles.formControlLabel}
                    control={
                      <IOSSwitch sx={{ m: 1 }} checked={cashWithdrwawal} onChange={handleCashWithdrawalToggle} />
                    }
                    label=""
                  />
                </FormGroup>
              </div>
              <div className={styles.label}>
                Spend Frequency :
                {editPreference ? (
                  <Select
                    value={spendFreq}
                    onChange={handleSpenFreqChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 40,
                      color: {
                        WEEKLY: "",
                        BIWEEKLY: "",
                        MONTHLY: "",
                        NONE: "grey",
                        default: "grey"
                      }[spendFreq]
                    }}
                  >
                    <MenuItem value="NONE" sx={{ color: "grey" }}>
                      None
                    </MenuItem>
                    <MenuItem value="WEEKLY">Weekly</MenuItem>
                    <MenuItem value="BIWEEKLY">Biweekly</MenuItem>
                    <MenuItem value="MONTHLY">Monthly</MenuItem>
                  </Select>
                ) : (
                  <span className={styles.value}>{spendFreq}</span>
                )}
              </div>
              <div className={styles.allowedLimit}>
                <div className={`${styles.label} ${editPreference ? styles.sliderInput : ""}`}>
                  <span>Max. Allowed Limit:</span>
                  {editPreference ? (
                    <Input
                      type="number"
                      value={allowedLimit}
                      onChange={handleAllowedLimitChange}
                      onBlur={handleBlur}
                      inputProps={{ min: 0, max: 1000 }}
                      aria-labelledby="input-slider"
                    />
                  ) : (
                    <span className={styles.value}>{allowedLimit}</span>
                  )}
                </div>
                {editPreference && (
                  <div className={styles.slider}>
                    <Slider
                      value={typeof allowedLimit === "number" ? allowedLimit : 0}
                      valueLabelDisplay="auto"
                      min={0}
                      max={1000}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      aria-label="custom thumb label"
                      sx={{ color: "#1e293b" }}
                    />
                    <div className={styles.maxmin}>
                      <span>
                        0 <em> (Min)</em>
                      </span>
                      <span>
                        1000 <em> (Max)</em>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.spendLimit}>
                <div className={`${styles.label} ${editPreference ? styles.sliderInput : ""}`}>
                  <span>Spend Limit:</span>
                  {editPreference ? (
                    <Input
                      type="number"
                      value={spendLimit}
                      onChange={handleSpendLimitChange}
                      onBlur={handleLimitBlur}
                      inputProps={{ min: 0, max: 1000 }}
                      aria-labelledby="input-slider"
                    />
                  ) : (
                    <span className={styles.value}>{spendLimit}</span>
                  )}
                </div>
                {editPreference && (
                  <div className={styles.slider}>
                    <Slider
                      value={typeof spendLimit === "number" ? spendLimit : 0}
                      valueLabelDisplay="auto"
                      min={0}
                      max={1000}
                      onChange={handleSliderLimitChange}
                      aria-labelledby="input-slider"
                      aria-label="custom thumb label"
                      sx={{ color: "#1e293b" }}
                    />
                    <div className={styles.maxmin}>
                      <span>
                        0 <em> (Min)</em>
                      </span>
                      <span>
                        1000 <em> (Max)</em>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {walletPreferenceError && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {walletPreferenceError}
                </div>
              )}
            </div>
          </div>
          <div className={styles.settingCard}>
            <ConnectCard
              walletUsers={walletDetail.supporter}
              heading={walletConnectHeading}
              onDeleteSupporter={handleDeleteSupporter}
              connectedTo={"Wallet"}
              deleteInput={deleteSupporterInput}
            >
              {checkPermission(CAN_CONNECT_USER_TO_WALLET) && (
                <AddIcon className={styles.editIcon} onClick={handleOpen} />
              )}
            </ConnectCard>
          </div>
        </div>
        <div className={styles.userPageMid}>
          <div className={styles.connectCard}>
            <ConnectCard
              walletUsers={walletDetail.cards}
              heading={"Card Connect"}
              onDeleteSupporter={handleDeleteCard}
              connectedTo={"WalletCards"}
              deleteInput={deleteCardInput}
            >
              {checkPermission(CAN_CONNECT_CARD_TO_WALLET) && (
                <AddIcon className={styles.editIcon} onClick={handleCardModal} />
              )}
            </ConnectCard>
          </div>
          <div className={styles.depositCard}>
            {/* <DepositCard /> */}
            <RecentTrans
              transList={transList.trans}
              setSearchText={setSearchText}
              setReceiptPresent={setReceiptPresent}
              setIsReviewed={setIsReviewed}
              walletId={String(walletId)}
              role={"WalletId"}
              recentTransOver={styles.recentTransOver}
              subTitle={"These are the details of the transactions made by this wallet"}
            />
          </div>
          {walletTransaction.length > 0 && (
            <div className={styles.transCard}>
              {Object.entries(walletTransaction).map(([key, transItem]) => {
                let title;
                let icon;

                if (key === "totalWalletBalance") {
                  title = "Wallet Balance";
                  icon = walletTransCardLogo;
                } else if (key === "totalCardBalance") {
                  title = "Card Balance";
                  icon = whiteCardLogo;
                } else if (key === "totalTransactionsWithoutReceipts") {
                  title = "Transactions without receipt (30 days)";
                  icon = receiptLogo;
                } else if (key === "totalTransactions") {
                  title = "Transactions (30 Days)";
                  icon = whiteFourPillarLogo;
                }

                return (
                  [
                    "totalWalletBalance",
                    "totalCardBalance",
                    "totalTransactionsWithoutReceipts",
                    "totalTransactions"
                  ].includes(key) &&
                  transItem && (
                    <WalletTransCard
                      key={transItem.id}
                      icon={icon}
                      title={title}
                      value={
                        title && ["Wallet Balance", "Card Balance"].includes(title)
                          ? `$${Number(transItem.current).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })}`
                          : transItem.current
                      }
                      growthval={transItem.previous !== null ? Number(transItem.diff) : undefined}
                      growthind={transItem.previous !== null ? Boolean(transItem.positive) : undefined}
                      from={transItem.previous !== null ? `from ${transItem.previous}` : undefined}
                      WalletTransCardClass={WalletTransCardOver}
                      balCardClass={balCardOver}
                    />
                  )
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.userPageBottom}>
          {/* <RecentTrans
            transList={transList.trans}
            setSearchText={setSearchText}
            setReceiptPresent={setReceiptPresent}
            setIsReviewed={setIsReviewed}
            walletId={String(walletId)}
            role={"WalletId"}
            recentTransOver={styles.recentTransOver}
            subTitle={"These are the details of the transactions made by this wallet"}
          /> */}
        </div>
      </div>
    </>
  );
}
