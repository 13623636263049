import { useState, ChangeEvent } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./addWalletModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { fetchUserListAction } from "src/page/wallets/walletSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { PARTICIPANT } from "src/components/constant/constant";
import { User } from "src/types/commonTypes";

export type AddWalletIn = {
  walletName: string;
  description: string;
  participantId: string;
  defaultWallet?: boolean;
};
interface AddUserModalProps {
  handleClose: () => void;
  isWalletListEmpty: boolean;
  onAddWallet: (_addWallet: AddWalletIn, _close: boolean) => void;
}

export default function AddUserModal({ handleClose, isWalletListEmpty, onAddWallet }: AddUserModalProps) {
  const { users } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const [walletName, setWalletName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [participant, setParticipant] = useState<User | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  // const [defaultWallet, setDefaultWallet] = useState<boolean>(false);
  const [error, setError] = useState({
    description: "",
    //  participant: "",
    wallet: ""
  });

  const handleCancel = () => {
    handleClose();
  };

  const fetchOption = debounce((inputValue: string) => {
    if (!inputValue && inputValue?.length < 2) return [];

    const data = {
      user_filter: {
        full_name: inputValue,
        role: PARTICIPANT
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleInputChange = (event: ChangeEvent<object>, newInputValue: string) => {
    fetchOption(newInputValue);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
    setIsSaveDisabled(!event.target.value || !walletName);
  };

  const handleWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWalletName(event.target.value);
    setIsSaveDisabled(!event.target.value || !description);
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: User | null) => {
    setParticipant(value || null);
    // setIsSaveDisabled(!description || !value);
  };

  // const handleDefaultWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDefaultWallet(event.target.checked);
  // };

  const handleSave = () => {
    if (
      !description.trim() ||
      !walletName.trim()
      // || !participant
    ) {
      setError({
        description: description ? "" : "Description is required",
        // participant: participant ? "" : "Participant is required",
        wallet: walletName ? "" : "Wallet name is required"
      });
      return;
    }

    // if (participant) {
    let walletData;

    if (isWalletListEmpty) {
      walletData = {
        participantId: participant?.id ?? "",
        walletName: walletName,
        description: description
        // defaultWallet: isWalletListEmpty
      };
    } else {
      walletData = {
        participantId: participant?.id ?? "",
        walletName: walletName,
        description: description
        // defaultWallet: defaultWallet
      };
    }

    onAddWallet(walletData, true);
    // }
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Create Wallet</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.text}>
            <label>Wallet Name</label>
            <input
              type="text"
              id="walletname"
              placeholder="Enter wallet name here"
              value={walletName}
              onChange={handleWalletChange}
              required
            />
            {error.wallet && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.wallet}
              </div>
            )}
          </div>
          <div className={styles.text}>
            <label>Description</label>
            <input
              type="text"
              id="description"
              placeholder="Enter description here"
              value={description}
              onChange={handleDescriptionChange}
              required
            />
            {error.description && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.description}
              </div>
            )}
          </div>
          <div className={styles.participant}>
            <label>Participant</label>
            <Autocomplete
              className={styles.autoComplete}
              options={users.list}
              getOptionLabel={(option: any) => option?.firstName ?? option}
              renderInput={(params) => <TextField {...params} placeholder="Andy Baker" />}
              value={participant}
              onChange={handleParticipantChange}
              onInputChange={handleInputChange}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                  }}
                >
                  <span>{`${option.firstName} ${option.lastName}`}</span>
                  <span style={{ color: "#64748B" }}>{option.email}</span>
                </li>
              )}
            />
            {/* {error.participant && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.participant}
              </div>
            )} */}
          </div>

          {/* {isWalletListEmpty ? (
            <div className={styles.checkbox}>
              <input type="checkbox" checked disabled />
              <label>Default Wallet</label>
            </div>
          ) : (
            <div className={styles.checkbox}>
              <input type="checkbox" checked={defaultWallet} onChange={handleDefaultWalletChange} />{" "}
              <label>Default Wallet</label>
            </div>
          )} */}
          <div className={styles.switch}></div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className={styles.sendBtn}
              variant="contained"
              // disabled={isSaveDisabled}
              onClick={handleSave}
              style={{
                backgroundColor: isSaveDisabled ? "#9f9f9f" : undefined,
                color: isSaveDisabled ? "white" : undefined
              }}
            >
              Save
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
