import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./supportModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { debounce } from "lodash";
// import { roleMapping } from "src/components/constant/constant";
// import { roleColors } from "src/variable/randomColor";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { fetchWalletListAction } from "src/page/userDetail/userDetailSlice";
// import { getRoleForOrganisation } from "src/utils/roleUtils";

interface Iprops {
  handleClose: () => void;
  participantName: string;
  organisationId?: number;
  onAddSupporter: (_email: string, _userId: string, _close: boolean, _connectedToParticipant: boolean) => void;
}

export default function WalletUserModal({ handleClose, participantName, onAddSupporter }: Iprops) {
  const [supporterEmail, setSupporterEmail] = useState<string>("");
  const [supporterEmailId, setSupporterEmailId] = useState<string>("");
  //   const [connectToParticipant, setConnectToParticipant] = useState<boolean>(false);
  const [error, setError] = useState({ email: "" });

  //   const { cognitoConfig } = useAppSelector((state) => state.auth);
  const { walletList } = useAppSelector((state) => state.userDetail);
  const dispatch = useAppDispatch();

  const handleAddAndClose = () => {
    if (!supporterEmail.trim()) {
      setError({
        email: supporterEmail ? "" : "User email is required"
      });
      return;
    }
    onAddSupporter(supporterEmail, supporterEmailId, true, false);
  };

  const handleAddAndStay = () => {
    if (!supporterEmail.trim()) {
      setError({
        email: supporterEmail ? "" : "User email is required"
      });
      return;
    }
    onAddSupporter(supporterEmail, supporterEmailId, false, false);
  };

  const handleCancelClose = () => {
    handleClose();
  };

  //   const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //     setConnectToParticipant(e.target.value === "Yes");
  //   };

  const fetchOptions = debounce(() => {
    const data = {
      from: 0,
      size: 100,
      isActive: true,
      walletFilter: {}
      // organisationId,
      // email: inputValue
    };

    dispatch(fetchWalletListAction(data));
  }, 500);

  const handleInputChange = () => {
    fetchOptions();
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect Wallet to {participantName}</h5>
            <button onClick={handleCancelClose}>
              <CloseIcon />
            </button>
          </div>

          <div className={styles.email}>
            <label>Wallet Name</label>
            {/* {organisationId ? ( */}
            <Autocomplete
              options={walletList?.list}
              // getOptionLabel={(option) => option.email}
              getOptionLabel={(option) => option.name || ""}
              style={{ width: 300 }}
              onInputChange={handleInputChange}
              onChange={(event, newValue) => {
                setSupporterEmailId(newValue?.id + "" || "");
                setSupporterEmail(newValue?.name || "");
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
              //   renderOption={(props, option) => (
              //     <li
              //       {...props}
              //       style={{
              //         display: "flex",
              //         flexDirection: "column",
              //         alignItems: "flex-start"
              //       }}
              //     >
              //       <span>{`${option.firstName} ${option.lastName}`}</span>
              //       <span>{option.email}</span>
              //     </li>
              //   )}
              ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
            />
            {/* ) : (
              <input
                type="email"
                placeholder="example@gmail.com"
                value={supporterEmail}
                onChange={(e) => setSupporterEmail(e.target.value)}
                required
              />
            )} */}
            {error.email && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.email}
              </div>
            )}
          </div>
          {/* <div className={styles.RadioInput}>
            <label>Connect to all of this participant’s wallet?</label>
            <div className={styles.inputs}>
              <div className={styles.inputYes}>
                <input type="radio" name="connectOption" value="Yes" id="yes" onChange={handleRadioChange} />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className={styles.inputNo}>
                <input type="radio" name="connectOption" value="No" id="No" onChange={handleRadioChange} />
                <label htmlFor="No">No</label>
              </div>
            </div>
          </div> */}
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleAddAndClose}>
              Connect & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddAndStay}>
              Connect & Add Another Wallet
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
