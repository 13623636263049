// import { GetAllCardsRequest } from "../services/cardService";
import type { CardAccessType, CardEventAction, CardStatus } from "src/types/commonTypes";
import client from "../client";

// export const cardFilter = (data: GetAllCardsRequest) => {
//   return client({
//     query: `
//     query myQuery ($input: GetUserCardsInput) {
//         get_user_cards(input: $input) {
//             private {
//                 card_id
//                 card_status
//                 card_type
//                 message
//                 ppan
//                 name_on_card
//                 expiry
//                 activation_date
//                 account_number
//             }
//             shared {
//                 card_id
//                 card_status
//                 card_type
//                 message
//                 ppan
//                 name_on_card
//                 expiry
//                 activation_date
//                 account_number
//             }
//         }
//     }`,
//     variables: {
//       input: {
//         user_id: "0fc9c373-d582-42a9-bf69-06d9d3fe59a2"
//       }
//     }
//   });
// };

export type CardSearchRequest = {
  card_filter?: {
    card_status?: CardStatus;
    card_type?: CardAccessType;
    user_id?: string;
    user_ids?: string[];
    wallet_ids?: string[];
    ppan?: string;
  };
  org_filter?: {
    organisationDisplayId?: string;
  };
  wallet_filter?: {
    display_id?: string;
  };
  size?: number;
  from?: number;
};
export const cardSearch = (data: CardSearchRequest) => {
  return client({
    query: `
    query SearchCard($input: SearchInput) {
      search_card(input: $input) {
        total_pages
        total_size
        card_listing {
          card_id
          card_status
          card_type
          clientId
          bsb
          activation_date
          created_at
          expiry
          holder_name
          novatti_card_status
          novatti_card_type
          order_id
          ppan
          updated_at
          user_id
          version
          user {
            email
            attributes {
              first_name
              last_name
            }
          }
        }
      }
    }
    `,
    variables: {
      input: {
        filter: {
          card_filter: data.card_filter,
          organisation_filter: {
            display_id: data?.org_filter?.organisationDisplayId
          },
          wallet_filter: data?.wallet_filter
        },
        size: data.size,
        from: data.from
      }
    }
  });
};

export type CreateCardRequest = {
  user_id: string;
  card_type: CardAccessType;
  first_name?: string;
  last_name?: string;
  organisation_id: string;
  address?: {
    line_1: string;
    line_2?: string;
    city: string;
    post_code: string;
  };
};

export const createCard = (data: CreateCardRequest) => {
  return client({
    query: `
    mutation CreateCard($input: CreateCardInput) {
      create_card(input: $input) {
        card_id
      }
    }`,
    variables: {
      input: data
    }
  });
};

export type UpdateCardRequest = {
  card_id: string;
  card_type: string;
  holder_name: string;
  user_id: string;
};

export const updateCard = (data: UpdateCardRequest) => {
  return client({
    query: `
      mutation UpdateCard($input: UpdateCardInput) {
        update_card(input: $input) {
          card_id
        }
      }
    `,
    variables: {
      input: data
    }
  });
};

export const cardEvent = (cardId: string, action: CardEventAction) => {
  return client({
    query: `
      mutation CardEvent($input: CardEventInput) {
        card_event(input: $input) {
          code
          message
        }
      }
    `,
    variables: {
      input: {
        action,
        card_id: cardId
      }
    }
  });
};

export const changeCardPin = (cardId: string, CardNewPin: string) => {
  return client({
    query: `
    mutation ChangeCardPin($input: ChangeCardPinInput) {
      change_card_pin(input: $input) {
        code
        message
      }
    }`,
    variables: {
      input: {
        card_id: cardId,
        pin: CardNewPin
      }
    }
  });
};
