import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch, RootState } from "../store";
import { Transaction } from "../transactions/transactionSlice";
import { TransactionRequest, deletePhoto, getAllTransaction } from "src/network/graphql/transactionService";
import { immediateToast } from "izitoast-react";
import { Users } from "../users/usersSlice";
import { Wallet } from "../wallets/walletSlice";
import { SearchUserRequest, getUserWallets, searchUser } from "src/network/graphql/userService";
import { PARTICIPANT } from "src/components/constant/constant";

const initialTransaction: Transaction[] = [];
const initialUsers: Users[] = [];
const initialWallets: Wallet[] = [];

const activitySlice = createSlice({
  name: "activity",
  initialState: {
    activityLoading: false,
    deletePhotoLoading: false,
    userLoading: false,
    walletLoading: false,
    error: false,
    transactions: {
      list: initialTransaction,
      total: 0
    },
    users: {
      list: initialUsers,
      total: 0
    },
    wallets: {
      list: initialWallets,
      total: 0
    }
  },
  reducers: {
    fetchActivityStart: (state) => {
      state.activityLoading = true;
    },
    fetchActivitySuccess: (state, action) => {
      state.activityLoading = false;
      state.transactions = action.payload;
    },
    fetchActivityFail: (state, action) => {
      state.activityLoading = false;
      state.error = action.payload;
    },
    clearTransactions: (state) => {
      state.transactions.list = [];
      state.transactions.total = 0;
    },
    deletePhotoStart: (state) => {
      state.deletePhotoLoading = true;
    },
    deletePhotoSuccess: (state) => {
      state.deletePhotoLoading = false;
    },
    deletePhotoFail: (state, action) => {
      state.deletePhotoLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = action.payload?.list ?? [];
      state.wallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.wallets.list = [];
      state.wallets.total = 0;
      state.error = action.payload;
    }
  }
});

const {
  fetchActivityStart,
  fetchActivitySuccess,
  fetchActivityFail,
  clearTransactions,
  deletePhotoStart,
  deletePhotoSuccess,
  deletePhotoFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail
} = activitySlice.actions;

const idsAlreadyExist = (newTransactions: Transaction[], existingIds: string[]) => {
  return newTransactions.some((t) => existingIds.includes(t.id));
};

export const fetchActivityAction = (data: TransactionRequest, shouldClearList: boolean = false) => {
  return async (dispatch: StoreDispatch, getState: () => RootState) => {
    if (shouldClearList) {
      // Clear the transactions list if the filter is applied
      dispatch(clearTransactions());
    }
    dispatch(fetchActivityStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        data: JSON.parse(data.data)
      }));

      const existingTransactions = getState().activity.transactions.list;
      const existingIds = existingTransactions.map((t) => t.id);

      if (!idsAlreadyExist(structuredResponse, existingIds)) {
        const updatedTransactions = [...existingTransactions, ...structuredResponse];

        dispatch(
          fetchActivitySuccess({
            list: updatedTransactions,
            total: response.data.search_activity.total_size
          })
        );
        return response;
      }
    } catch (err) {
      dispatch(fetchActivityFail(err));
    }
  };
};

export const deleteFile = (assetId: string, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deletePhotoStart());
    try {
      await deletePhoto(assetId);

      immediateToast("success", {
        message: "photo successfully deleted.",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(deletePhotoSuccess());
      if (cb) cb();
    } catch (err) {
      dispatch(deletePhotoFail(err));
    }
  };
};

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        // user_filter: {
        //   is_active: data.isActive,
        //   email: data.email,
        //   first_name: data.firstName,
        //   last_name: data.lastName,
        //   organisation_id: data.organisationId
        // },
        user_filter: data.user_filter,
        size: data.size,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing
        .filter((user: any) => user.current_organisation_role === PARTICIPANT)
        .map((user: any) => ({
          id: user.display_id,
          firstName: user.attributes.first_name,
          lastName: user.attributes.last_name,
          email: user.email,
          role: "not available",
          status: user.is_active,
          userId: user.user_id
        }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchWalletListAction = (userId: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await getUserWallets(userId);

      const structuredResponse = response.data.get_user_wallets.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        // status: wallet.wallet_status,
        name: wallet.name
        // participant: { role: "PARTICIPANT", fullName: wallet.description }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: structuredResponse.length
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export default activitySlice.reducer;
