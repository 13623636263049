import { PropsWithChildren } from "react";
import styles from "./connectCard.module.scss";
import ConnectMember from "./connectMember/ConnectMember";
import { bgColorbyName } from "src/variable/randomColor";
import { useAppSelector } from "src/page/store";
import { getRoleForOrganisation } from "src/utils/roleUtils";
// import { Link } from "react-router-dom";

type WalletUser = {
  id: number | string;
  email?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  role?: {
    organisation_id: string;
    value: string;
  }[];
  participant?: {
    id?: number | string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    role?: string;
  };
  description?: string;
  name?: string;
  cardNumber?: string;
  cardHolderName?: string;
  user?: {
    id?: number;
    fullName?: string;
    email?: string;
    role?: string;
    firstName?: string;
    lastName?: string;
  };
  cardStatus?: string;
};

interface Iprops extends PropsWithChildren {
  walletUsers?: WalletUser[] | undefined;
  heading: string;
  connectedTo: string;
  onDeleteSupporter?: () => void;
  walletMemberOver?: string;
  connectCardOver?: string;
  deleteInput?: {
    buttonInfo: string;
    whomToRemove: string;
    headInfo: string;
  };
  modalOf?: "UNLINK" | "LINK";
}

export default function ConnectCard({
  walletUsers,
  heading,
  children,
  onDeleteSupporter,
  connectedTo,
  walletMemberOver,
  connectCardOver,
  deleteInput,
  modalOf
}: Iprops) {
  const { cognitoConfig } = useAppSelector((state) => state.auth);

  return (
    <div className={`${styles.walletConnect} ${connectCardOver}`}>
      <div className={styles.walletHead}>
        <div className={styles.headL}>{heading}</div>
        {connectedTo === "Wallet" ||
        connectedTo === "OrgUser" ||
        connectedTo === "OrgWallet" ||
        connectedTo === "WalletCards" ||
        connectedTo === "User" ||
        connectedTo === "OrgCards" ? (
          <div className={styles.headR}>{children}</div>
        ) : null}
      </div>
      <div className={styles.walletInfo}>
        <div className={`${styles.walletMember} ${walletMemberOver}`} id={styles.scrollBar}>
          {(connectedTo === "Wallet" || connectedTo === "OrgUser") &&
            (walletUsers && walletUsers.length > 0 ? (
              walletUsers.map((user) => (
                <ConnectMember
                  key={user.id}
                  img={user?.firstName?.charAt(0).toLocaleUpperCase() || ""}
                  name={user?.firstName + " " + user?.lastName}
                  walletName={user.email || ""}
                  email={""}
                  supporterId={user.id}
                  bg={bgColorbyName(user?.firstName || "")}
                  onDeleteSupporter={onDeleteSupporter || (() => {})}
                  connectTo={connectedTo}
                  deleteInput={deleteInput}
                  role={
                    getRoleForOrganisation(user?.role, cognitoConfig?.currentOrganisation?.organisation_id) ?? "NOROLE"
                  }
                />
              ))
            ) : (
              <p>No connected wallet</p>
            ))}

          {(connectedTo === "User" || connectedTo === "OrgWallet") &&
            (walletUsers && walletUsers.length > 0 ? (
              walletUsers.map((user) => (
                <ConnectMember
                  key={user.id}
                  img={user?.name?.charAt(0).toLocaleUpperCase() || ""}
                  name={user.name || ""}
                  walletName={user.participant?.fullName || ""}
                  email={""}
                  supporterId={user.id}
                  bg={bgColorbyName(user.name || "")}
                  onDeleteSupporter={onDeleteSupporter || (() => {})}
                  connectTo={connectedTo}
                  deleteInput={deleteInput}
                  modalOf={modalOf}
                />
              ))
            ) : (
              <p>No connected wallet</p>
            ))}

          {(connectedTo === "WalletCards" || connectedTo === "OrgCards") &&
            (walletUsers && walletUsers.length > 0 ? (
              walletUsers.map((data) => (
                <ConnectMember
                  key={data.id}
                  img={data?.cardHolderName?.charAt(0).toLocaleUpperCase() || ""}
                  name={data?.cardHolderName || ""}
                  walletName={"xxxx-xxxx-xxxx-" + data?.cardNumber?.slice(-4) || ""}
                  email={""}
                  supporterId={data.id}
                  bg={bgColorbyName(data?.cardHolderName || "")}
                  onDeleteSupporter={onDeleteSupporter || (() => {})}
                  connectTo={connectedTo}
                  deleteInput={deleteInput}
                  role={data?.user?.role}
                  cardActive={data?.cardStatus === "ACTIVE"}
                  nonClickable={true}
                />
              ))
            ) : (
              <p>No connected cards</p>
            ))}
        </div>
      </div>
    </div>
  );
}
