import { createSlice } from "@reduxjs/toolkit";
import { CardAccessType, CardStatus, User } from "src/types/commonTypes";
import { Wallet } from "./wallets/walletSlice";

const initialTransactionFilters: {
  wallet: Wallet[] | null;
  user: User[] | null;
  startDate: string | null;
  endDate: string | null;
  minAmount: string | null;
  maxAmount: string | null;
  activityName: string | null;
} = {
  wallet: null,
  user: null,
  startDate: null,
  endDate: null,
  minAmount: null,
  maxAmount: null,
  activityName: null
};

const initialWalletFilters: {
  startDate: string | null;
  endDate: string | null;
  minAmount: string | null;
  maxAmount: string | null;
  status: string | null;
  type: string | null;
  user: User[] | null;
} = {
  startDate: null,
  endDate: null,
  minAmount: null,
  maxAmount: null,
  status: "ACTIVE",
  type: "GENERAL",
  user: null
};

const initialCardFilters: {
  status: CardStatus | null;
  type: CardAccessType | null;
  wallet: Wallet[] | null;
  user: User[] | null;
} = {
  status: null,
  type: null,
  wallet: null,
  user: null
};

const initialState = {
  transaction: initialTransactionFilters,
  wallet: initialWalletFilters,
  card: initialCardFilters
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetFilters: () => {
      return { ...initialState };
    },
    setTransactionFilter: (state, action) => {
      state.transaction = { ...state.transaction, ...action.payload };
    },
    resetTransactionFilters: (state) => {
      state.transaction = initialTransactionFilters;
    },
    setWalletFilters: (state, action) => {
      state.wallet = { ...state.wallet, ...action.payload };
    },
    resetWalletFilters: (state) => {
      state.wallet = initialWalletFilters;
    },
    setCardFilters: (state, action) => {
      state.card = { ...state.card, ...action.payload };
    },
    resetCardFilters: (state) => {
      state.card = initialCardFilters;
    }
  }
});

export const {
  setTransactionFilter,
  resetFilters,
  resetTransactionFilters,
  setWalletFilters,
  resetWalletFilters,
  setCardFilters,
  resetCardFilters
} = filterSlice.actions;

export default filterSlice.reducer;
