import client from "../client";
import { PaginationRequest } from "src/types/commonTypes";

export interface TransactionRequest extends PaginationRequest {
  filter?: {
    activityName?: string;
    activityStatus?: string;
    destinationWalletId?: string;
    walletIds?: string[];
    userIds?: string[];
    created_at?: {
      min?: string;
      max?: string;
    };
  };
  userFilter?: {
    email?: string;
  };
  walletFilter?: {
    displayId?: string;
  };
  sort?: string;
}

export const getAllTransaction = (data: TransactionRequest) => {
  return client({
    query: `query SearchActivity($input: SearchInput) { 
      search_activity(input: $input) { 
        total_pages 
        total_size 
        activity_listing { 
          data 
          activity_id 
          activity_name 
          activity_status 
          process_status 
          end_timestamp 
          start_timestamp
          created_at 
          owner_id
          receipt_available
        } 
      } 
    }`,
    variables: {
      input: {
        from: data.from,
        size: data.size,
        sort: data?.sort,
        filter: {
          activity_filter: {
            activity_name: data?.filter?.activityName,
            activity_status: data?.filter?.activityStatus,
            destination_wallet_id: data?.filter?.destinationWalletId,
            user_ids: data?.filter?.userIds,
            wallet_ids: data?.filter?.walletIds,
            created_at: data?.filter?.created_at
          },
          // user_filter: {
          //   email: data?.userFilter?.email
          // },
          wallet_filter: {
            display_id: data?.walletFilter?.displayId
          }
        }
      }
    }
  });
};

export type TransactionDownloadRequest = {
  filter?: {
    organisation_id?: string;
    wallet_ids?: string[];
    user_ids?: string[];
    activity_name?: string;
    created_at?: {
      min?: string;
      max?: string;
    };
  };
  timeZone?: string;
};

export const downloadTransaction = (data: TransactionDownloadRequest) => {
  return client({
    query: `mutation download_transactions ($input: DownloadTransactionsInput) {
      download_transactions(input: $input) {
        code
        count
        request_id
        download_url
      }
    }`,
    variables: {
      input: {
        activity_filter: data?.filter,
        timezone: data?.timeZone
      }
    }
  });
};

export const mockCardPurchase = (cardId: string, amount: number) => {
  return client({
    query: `mutation MockCardPurchase($input: MockCardPurchaseInput) {
      mock_card_purchase(input: $input) {
        code
        message
      }
    }`,
    variables: {
      input: {
        card_id: cardId,
        amount
      }
    }
  });
};

export const listNote = (transactionId: string) => {
  return client({
    query: `query ListNotes($input: ListNotesInput) { 
      list_notes(input: $input, limit: 1) { 
        note { 
          note_id 
          object_id 
          payload { 
            message 
            subject 
          } 
          updated_at
        } 
      } 
    }`,
    variables: {
      input: {
        note_type: "BASIC",
        object_id: transactionId
      }
    }
  });
};

export const createNote = (transactionId: string, note: string) => {
  return client({
    query: `mutation CreateNote($input: CreateNoteInput) {
      create_note(input: $input) {
        note_id
        payload {
          message
          subject
        }
      }
    }`,
    variables: {
      input: {
        payload: {
          message: note,
          subject: ""
        },
        note_type: "BASIC",
        object_id: transactionId
      }
    }
  });
};

export const updateNote = (noteId: string, note: string) => {
  return client({
    query: `mutation UpdateNote($input: UpdateNoteInput) {
      update_note(input: $input) {
        note_id
        payload {
          message
          subject
        }
      }
    }`,
    variables: {
      input: {
        note_id: noteId,
        payload: {
          message: note,
          subject: ""
        }
      }
    }
  });
};

export const transactionPhoto = (assetType: string, transactionId: string) => {
  return client({
    query: `query ListAssets($input: ListAssetsInput) {
      list_assets(input: $input) {
        next_token
        asset {
          asset_id
          attributes {
            bucket_name
            key
          }
          asset_type
          asset_status
        }
      }
    }`,
    variables: {
      input: {
        asset_type: assetType,
        object_id: transactionId
      }
    }
  });
};

export const deletePhoto = (assetId: string) => {
  return client({
    query: `mutation DeleteAsset($input: DeleteAssetInput) {
      delete_asset(input: $input) {
        asset_id
        object_id
      }
    }`,
    variables: {
      input: {
        asset_id: assetId
      }
    }
  });
};
