import { switchOrganisationAction } from "src/page/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { refreshSession } from "src/utils/utils";
import Button from "@mui/material/Button";
import ChangeOrgModal from "../changeOrgModal/ChangeOrgModal";
import Modalui from "../UI/modal/Modalui";
import { useState } from "react";

interface Iprops {
  orgId: string;
}

function AdminSwitchOrg({ orgId }: Iprops) {
  const [openChangeOrgConfirm, setOpenChangeOrgConfirm] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { userInfo, cognitoRefreshToken } = useAppSelector((state) => state.auth);
  const handleSwitchOrganisation = () => {
    if (!userInfo || !cognitoRefreshToken) return;
    dispatch(
      switchOrganisationAction(
        {
          organisationId: orgId,
          userId: userInfo.id
        },
        () => {
          refreshSession(cognitoRefreshToken);
          setOpenChangeOrgConfirm(false);
        }
      )
    );
  };

  const handleCloseConfirmationModal = () => {
    setOpenChangeOrgConfirm(false);
  };

  return (
    <>
      <Modalui open={openChangeOrgConfirm} handleClose={handleCloseConfirmationModal}>
        <ChangeOrgModal handleClose={handleCloseConfirmationModal} onConfirm={handleSwitchOrganisation} />
      </Modalui>
      <Button
        variant="contained"
        sx={{
          color: "#fff",
          bgcolor: "#232325",
          display: "flex",
          flexWrap: "nowrap",
          height: "35px",
          // fontSize: "11px",
          fontWeight: "600",
          padding: "5px",
          textTransform: "none"
        }}
        onClick={() => setOpenChangeOrgConfirm(true)}
      >
        Switch
      </Button>
    </>
  );
}

export default AdminSwitchOrg;
