import { Autocomplete, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import styles from "./newPaymentCards.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { Users } from "src/page/users/usersSlice";
import { Wallet } from "src/page/wallets/walletSlice";
import { Card } from "src/page/cards/cardSlice";
import {
  fetchCardListAction,
  fetchUserListAction,
  fetchWalletListAction,
  resetCardListAction,
  resetwalletListAction,
  singlePayAction
} from "src/page/newPayment/newPaymentSlice";
import moment from "moment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface singlePayProp {
  onClose: (_value: boolean) => void;
}

export default function SinglePay({ onClose }: singlePayProp) {
  const dispatch = useAppDispatch();
  const { users, wallets, cards } = useAppSelector((state) => state.newPayment);
  const [selectedParticipant, setSelectedParticipant] = useState<Users | null>(null);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [timeDuration, setTimeDuration] = useState("");
  const [error, setError] = useState({
    participant: "",
    wallet: "",
    card: "",
    time: ""
  });

  useEffect(() => {
    dispatch(fetchUserListAction({ size: 100, from: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (users.list.length === 1) {
      setSelectedParticipant(users.list[0]);
    }
  }, [users.list]);

  useEffect(() => {
    setSelectedWallet(null);
    setSelectedCard(null);
    setTimeDuration("");
    if (selectedParticipant) {
      dispatch(fetchWalletListAction(String(selectedParticipant?.userId)));
    } else {
      dispatch(resetwalletListAction({ list: [] }));
    }
  }, [selectedParticipant]);

  useEffect(() => {
    setSelectedCard(null);
    setTimeDuration("");
    if (selectedWallet) {
      dispatch(fetchCardListAction(String(selectedWallet?.id)));
    } else {
      dispatch(resetCardListAction({ list: [] }));
    }
  }, [selectedWallet]);

  const handleBack = () => {
    onClose(false);
  };
  const handleConfirm = () => {
    if (!selectedParticipant?.id || !selectedWallet?.id || !selectedCard?.id || !timeDuration) {
      setError({
        participant: selectedParticipant?.id ? "" : "Select a participant",
        wallet: selectedWallet?.id ? "" : "Selected a wallet",
        card: selectedCard?.id ? "" : "Select a card",
        time: timeDuration ? "" : "Select a time duration"
      });
      return;
    }

    let data;

    if (timeDuration !== "ONCE") {
      data = {
        cardId: String(selectedCard?.id),
        expireAt: moment()
          .add(Number(timeDuration || 3), "hours")
          .toISOString(),
        walletId: String(selectedWallet?.id)
      };
    } else {
      data = {
        cardId: String(selectedCard?.id),
        linkType: "ONCE",
        expireAt: moment().add(3, "hours").toISOString(),
        walletId: String(selectedWallet?.id)
      };
    }
    dispatch(
      singlePayAction(data, () => {
        setSelectedParticipant(null);
        setSelectedWallet(null);
        setSelectedCard(null);
        setTimeDuration("");
        // onClose(false);
      })
    );
  };

  return (
    <div className={styles.newPaymentCard}>
      <h1>Add New Payment</h1>
      <div>
        <Autocomplete
          options={users?.list}
          getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
          value={selectedParticipant}
          onChange={(event, newValue) => {
            setSelectedParticipant(newValue);
            setError({
              ...error,
              participant: ""
            });
          }}
          renderInput={(params) => <TextField {...params} label="Select Participant" />}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}
            >
              <span>{option?.firstName + " " + option?.lastName}</span>
              <span style={{ color: "#64748B" }}>{option?.email}</span>
            </li>
          )}
        />
        {error.participant && (
          <div className={styles.error}>
            <ErrorOutlineIcon className={styles.errIcon} />
            {error.participant}
          </div>
        )}
      </div>
      <div>
        <Autocomplete
          options={wallets?.list}
          getOptionLabel={(option) => option.name || ""}
          value={selectedWallet}
          onChange={(event, newValue) => {
            setSelectedWallet(newValue);
            setError({
              ...error,
              wallet: ""
            });
          }}
          renderInput={(params) => <TextField {...params} label="Select Wallet" />}
        />
        {error.wallet && (
          <div className={styles.error}>
            <ErrorOutlineIcon className={styles.errIcon} />
            {error.wallet}
          </div>
        )}
      </div>
      <div>
        <Autocomplete
          options={cards?.list}
          getOptionLabel={(option) => `${option?.user?.fullName}`}
          value={selectedCard}
          onChange={(event, newValue) => {
            setSelectedCard(newValue);
            setError({
              ...error,
              card: ""
            });
          }}
          renderInput={(params) => <TextField {...params} label="Select Card" />}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}
            >
              <span>{option?.user?.fullName}</span>
              <span>{option?.cardNumber ? "xxxx-xxxx-xxxx-" + option?.cardNumber?.slice(-4) : ""}</span>
            </li>
          )}
        />
        {error.card && (
          <div className={styles.error}>
            <ErrorOutlineIcon className={styles.errIcon} />
            {error.card}
          </div>
        )}
      </div>
      <div>
        <Select
          value={timeDuration}
          onChange={(e) => {
            setTimeDuration(e.target.value);
            setError({
              ...error,
              time: ""
            });
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className={styles.select}
          renderValue={
            timeDuration !== ""
              ? undefined
              : () => <Typography sx={{ color: "grey" }}>How long do you need the money?</Typography>
          }
        >
          <MenuItem value="ONCE">ONCE</MenuItem>
          <MenuItem value="2">2 Hours</MenuItem>
          <MenuItem value="4">4 Hours</MenuItem>
          <MenuItem value="8">8 Hours</MenuItem>
          <MenuItem value="12">12 Hours</MenuItem>
          <MenuItem value="24">24 Hours</MenuItem>
          <MenuItem value="48">2 Days</MenuItem>
          <MenuItem value="96">4 Days</MenuItem>
          <MenuItem value="168">1 Week</MenuItem>
        </Select>
        {error.time && (
          <div className={styles.error}>
            <ErrorOutlineIcon className={styles.errIcon} />
            {error.time}
          </div>
        )}
      </div>

      <div className={styles.buttons}>
        <Button variant="contained" className={styles.backBtn} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          className={styles.continueBtn}
          onClick={handleConfirm}
          // disabled={totalCard && editTotalCard}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
