import { useEffect, useState } from "react";
import styles from "./organisationDetail.module.scss";
// import ConnectCard from "src/components/connectCard/ConnectCard";
// import RecentTrans from "src/components/recentTrans/RecentTrans";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  getOrgDetailAction,
  // fetchTransListAction,
  addCardAction
  // orgUserListAction,
  // orgWalletListAction,
  // orgCardListAction
} from "./organisationDetailSlice";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import OrgInfoCard from "src/components/orgInfoCard/OrgInfoCard";
// import { Button } from "@mui/material";
import Modalui from "src/components/UI/modal/Modalui";
import AttachCard from "src/components/attachCard/AttachCard";
// import { CAN_CONNECT_CARD } from "src/components/constant/constant";

export default function OrganisationDetail() {
  // const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  // const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const [cardModal, setCardModal] = useState(false);
  const { organisationId } = useParams();
  const { orgDetail, organisationDetailLoading } = useAppSelector((state) => state.organisationDetail);
  // const { config } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // const data = {
    //   page: 0,
    //   limit: 5,
    //   // receiptPresent,
    //   // isReviewed,
    //   organisationIds: [parseInt(organisationId!)],
    //   statuses: ["Success", "Complete", "Hold"]
    // };

    if (organisationId) {
      dispatch(getOrgDetailAction(organisationId));
      // dispatch(orgUserListAction(String(orgDetail.organisation.id)));
      // dispatch(
      //   orgWalletListAction({
      //     from: 0,
      //     size: 10,
      //     orgFilter: {
      //       organisationDisplayId: String(orgDetail.organisation.id)
      //     }
      //   })
      // );
      // dispatch(
      //   orgCardListAction({
      //     from: 0,
      //     size: 10,
      //     card_filter: {
      //       card_status: "ACTIVE"
      //     },
      //     org_filter: {
      //       organisationDisplayId: String(orgDetail.organisation.id)
      //     }
      //   })
      // );
      // fetchTransListAction(data);
    }
  }, [dispatch, orgDetail.organisation.id, organisationId]);

  const handleCardModal = () => {
    setCardModal(!cardModal);
  };

  // const walletConnectHeading = "Wallet Connect";

  const handleAddCard = (cardNumber: string, cardId: string, close: boolean, connectedToParticipant: boolean) => {
    const data = {
      cardId: Number(cardId),
      applyToAll: connectedToParticipant,
      action: "LINK"
    };

    addCardAction(data, Number(organisationId)).then(() => {
      dispatch(getOrgDetailAction(Number(organisationId)));
    });

    if (close) {
      setCardModal(false);
    }
  };

  return (
    <>
      <Modalui open={cardModal} handleClose={handleCardModal}>
        <AttachCard
          handleClose={handleCardModal}
          participantName={orgDetail.organisation.name}
          organisationId={orgDetail?.organisation?.id}
          onAddSupporter={handleAddCard}
        />
      </Modalui>
      <div className={styles.orgPage}>
        {organisationDetailLoading ? <LoadingModal /> : null}
        <div className={styles.orgPageWelCard}>
          <PersonWelCard
            img={orgDetail?.organisation?.name?.charAt(0).toLocaleUpperCase()}
            firstName={orgDetail?.organisation?.name}
            lastName={""}
            email={orgDetail?.organisation?.abn}
            id={orgDetail?.organisation?.id}
            connectedTo={"Users"}
          />
        </div>
        <div className={styles.orgPageHead}>
          {orgDetail && <OrgInfoCard org={orgDetail?.organisation} />}
          {/* <ConnectCard
            walletUsers={orgDetail.wallets}
            heading={walletConnectHeading}
            connectedTo={"OrgWallet"}
            walletMemberOver={styles.walletMemberOver}
            connectCardOver={styles.walletConnectCardOver}
          >
            <Link to={`/organisation/${orgDetail?.organisation?.organisationId}/wallets`}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1e293b",
                  height: "29px",
                  width: "76px",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  margin: "0",
                  padding: "0"
                }}
              >
                View All
              </Button>
            </Link>
          </ConnectCard>
          <ConnectCard
            walletUsers={orgDetail.cards}
            heading={"Card Connect"}
            connectedTo={"OrgCards"}
            walletMemberOver={styles.walletMemberOver}
            connectCardOver={styles.walletConnectCardOver}
          >
            <Link to={`/organisation/${orgDetail?.organisation?.organisationId}/cards`}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1e293b",
                  height: "29px",
                  width: "76px",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  margin: "0",
                  padding: "0"
                }}
              >
                View All
              </Button>
            </Link>
          </ConnectCard> */}
        </div>
        <div className={styles.orgPageBottom}>
          <div className={styles.connectCards}>
            {/* <ConnectCard
              walletUsers={orgUser}
              heading={"User Connect"}
              connectedTo={"OrgUser"}
              walletMemberOver={styles.userMemberOver}
              connectCardOver={styles.userConnectCardOver}
            >
              <Link to={`/organisation/${orgDetail?.organisation?.organisationId}/users`}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#1e293b",
                    height: "29px",
                    width: "76px",
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "normal",
                    margin: "0",
                    padding: "0"
                  }}
                >
                  View All
                </Button>
              </Link>
            </ConnectCard> */}
            {/* <ConnectCard
              walletUsers={orgDetail?.users}
              heading={"Card Connect"}
              connectedTo={"OrgUser"}
              connectCardOver={styles.userConnectCardOver}
            >
              {config?.allowedPermissions.includes(CAN_CONNECT_CARD) && (
                <AddIcon className={styles.editIcon} onClick={handleCardModal} />
              )}
            </ConnectCard> */}
          </div>
          {/* <RecentTrans
            transList={transList?.trans}
            setReceiptPresent={setReceiptPresent}
            setIsReviewed={setIsReviewed}
            organisationId={Number(organisationId)}
            role={"OrganisationId"}
            recentTransOver={styles.recentTransOver}
            subTitle={"These are the details of the transactions made by this organisation"}
          /> */}
        </div>
      </div>
    </>
  );
}
