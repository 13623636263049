import styles from "./ledger.module.scss";
// import { Avatar } from "@mui/material";
// import { bgColorbyName } from "src/variable/randomColor";
import { Link } from "react-router-dom";
import { Transaction } from "src/page/transactions/transactionSlice";
import { dateTimeFormat, getTransactionValue } from "src/utils/utils";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    Key: "id",
    render: (id: string) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Date / Time",
    dataIndex: "endDate",
    key: "endDate",
    width: 150,
    render: (endDate: string) => {
      const dateValue = endDate;

      if (dateValue === undefined || null) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }

      return (
        <>
          <div className={styles.tableDate}>{dateTimeFormat(dateValue)?.date}</div>
          <div className={styles.tableDate}>{dateTimeFormat(dateValue)?.time}</div>
        </>
      );
    }
  },
  {
    title: "Activity Name",
    dataIndex: "name",
    key: "name",
    render: (name: string) => <span className={styles.activityname}>{name}</span>
  },
  {
    title: "Name",
    dataIndex: "merchant",
    key: "merchant",
    // width: 150,
    render: (merchantName: string, data: Transaction) => (
      <span className={styles.tableMerchent}>{getTransactionValue(data)?.value}</span>
    )
  },
  {
    title: "Wallet",
    dataIndex: "wallet",
    key: "wallet",
    render: (wallet: string, data: Transaction) => (
      <div className={styles.tableWallet}>
        {data?.data?.wallet_id ? (
          <Link to={`/wallets/${data?.data?.wallet_id}`}>
            {data?.data?.wallet ? data?.data?.wallet?.name : data?.data?.wallet_id}
          </Link>
        ) : (
          "---"
        )}
      </div>
    )
  },
  {
    title: "Activity Status",
    dataIndex: "activityStatus",
    key: "activityStatus",
    render: (activityStatus: string) => <div className={styles.activityStatus}>{activityStatus}</div>
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount: number, data: Transaction) => (
      <div className={styles.tableAmount} style={{ color: getTransactionValue(data)?.amountColor }}>
        {getTransactionValue(data)?.amount}
      </div>
    )
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance: number, data: Transaction) => (
      <div className={styles.tableBalance}>${data?.data?.wallet?.balance ? data?.data?.wallet?.balance : 0}</div>
    )
  }
];

export default columns;
