import { useEffect, useState } from "react";
import styles from "./home.module.scss";
import { MenuItem, Select, SelectChangeEvent, Button, CircularProgress } from "@mui/material";
import RecentActivity from "src/components/recentActivity/RecentActivity";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import {
  fetchActivityAction,
  fetchUserListAction,
  // CardSetting,
  fetchWalletDetailAction,
  fetchWalletListAction,
  getUserCardAction,
  singlePayAction
} from "./homeSlice";
import moment from "moment";
// import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import { SUPPORTER } from "src/components/constant/constant";

import { dateTimeFormat, getDecimalNumber } from "src/utils/utils";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [card, setCard] = useState<CardSetting | null>();
  // const [wallet, setWallet] = useState<Wallet[]>([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const { userInfo, cognitoConfig } = useAppSelector((state) => state.auth);
  const { userCards, wallets, walletDetail, transactions, users, userCardLoading } = useAppSelector(
    (state) => state.home
  );

  const handleOrgSelect = (event: SelectChangeEvent) => {
    setSelectedWalletId(event.target.value);
    const walletId = event.target.value;

    if (userCards[0]?.cardSetting?.cardId) {
      const data = {
        expireAt: moment().add(3, "hours").toISOString(),
        cardId: userCards[0]?.cardSetting?.cardId,
        walletId
      };

      dispatch(singlePayAction(data)).then(() => {
        if (selectedUser) {
          dispatch(
            getUserCardAction(
              String(selectedUser),
              String(cognitoConfig?.currentOrganisation?.organisation_id),
              String(userInfo?.role)
            )
          );
        } else {
          dispatch(
            getUserCardAction(
              String(userInfo?.id),
              String(cognitoConfig?.currentOrganisation?.organisation_id),
              String(userInfo?.role)
            )
          );
        }
      });
    }
  };

  const handleUserSelect = (event: SelectChangeEvent) => {
    const userId = event.target.value as string;

    setSelectedUser(userId);
    const sendUserId = userId === "" ? userInfo?.id : userId;

    dispatch(
      getUserCardAction(
        String(sendUserId),
        String(cognitoConfig?.currentOrganisation?.organisation_id),
        String(userInfo?.role)
      )
    );
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getUserCardAction(
          String(userInfo?.id),
          String(cognitoConfig?.currentOrganisation?.organisation_id),
          String(userInfo?.role)
        )
      );
    }
  }, [dispatch, cognitoConfig?.currentOrganisation?.organisation_id, userInfo]);

  useEffect(() => {
    dispatch(
      fetchActivityAction({
        size: 1,
        from: 0,
        filter: {
          activityName: "NOVATTI_CARD_TRANSACTION",
          activityStatus: "APPROVED"
        },
        userFilter: {
          email: String(userInfo?.email)
        }
      })
    );
  }, [dispatch, userInfo?.email]);

  useEffect(() => {
    if (userCards[0]?.cardSetting?.walletId) {
      setSelectedWalletId(userCards[0]?.cardSetting?.walletId);
      dispatch(fetchWalletDetailAction(userCards[0]?.cardSetting.walletId));
    }
  }, [dispatch, userCards]);

  useEffect(() => {
    if (walletDetail?.wallet?.participant?.id) {
      dispatch(fetchWalletListAction(String(walletDetail.wallet.participant.id)));
    }
  }, [dispatch, walletDetail]);

  useEffect(() => {
    if (userInfo?.role === SUPPORTER) {
      const data = {
        size: 25,
        from: 0
        // userFilter: {
        //   role: PARTICIPANT
        //   // email: userInfo?.email
        // }
      };

      dispatch(fetchUserListAction(data));
    }
  }, [dispatch, userInfo?.email, userInfo?.role]);

  return (
    <div className={styles.home}>
      {/* {userCardLoading ? <LoadingModal /> : ""} */}
      {userCardLoading ? (
        <div className={styles.CircularProgress}>
          <CircularProgress sx={{ color: "#0f172a" }} />
        </div>
      ) : (
        <div className={styles.cardContainer}>
          <div className={styles.card}>
            <div className={styles.indicator}>
              <div className={`${styles.greenCircle} ${userCards[0]?.cardId ? styles.greenactive : ""}`}></div>
              <div className={` ${styles.redCircle} ${!userCards[0]?.cardId ? styles.redactive : ""}`}></div>
            </div>
            <div className={styles.cardContent}>
              {userCards[0]?.cardId ? (
                <>
                  <div className={styles.walletName}>Paying for {walletDetail?.wallet?.name}</div>
                  <div className={styles.balance}>${getDecimalNumber(walletDetail?.wallet?.balance)} Available</div>
                  <div className={styles.balance}>
                    {walletDetail?.wallet?.participant?.firstName + " " + walletDetail?.wallet?.participant?.lastname}
                  </div>
                  <div className={styles.cardno}>Card ends in {userCards[0]?.ppan?.slice(-4)}</div>
                </>
              ) : (
                <>
                  <div className={styles.balance}>No wallet selected</div>
                  <div className={styles.cardno}>Please add a wallet to pay</div>
                </>
              )}
            </div>
            {userCards[0]?.cardSetting?.expireAt && (
              <div className={styles.caution}>
                <span>
                  {`*Money Available On Card Until: `}
                  {dateTimeFormat(Number(userCards[0]?.cardSetting?.expireAt)).datetime}
                </span>
              </div>
            )}
          </div>

          {userInfo?.role === SUPPORTER ? (
            <div className={styles.wallet}>
              <Select
                className={styles.selectOption}
                value={selectedUser}
                onChange={handleUserSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                // renderValue={renderParticipantValue}
                sx={{
                  height: 50,
                  minWidth: 150
                }}
                // disabled={wallets?.list?.length <= 0} // Uncomment if you want to disable the select when there are no options
              >
                <MenuItem value="" sx={{ color: "grey" }}>
                  Select Participant
                </MenuItem>
                {users?.list?.map((option) => (
                  <MenuItem
                    key={option.userId}
                    value={option.userId.toString()}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>{option?.firstName + " " + option?.lastName}</span>
                    <span style={{ color: "#64748B" }}>{option?.email}</span>
                  </MenuItem>
                ))}
              </Select>
              {selectedUser && userCards[0]?.cardSetting?.cardId && (
                <Select
                  className={styles.selectOption}
                  value={selectedWalletId}
                  onChange={handleOrgSelect}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  // renderValue={renderWalletValue}
                  sx={{
                    height: 50,
                    minWidth: 150
                  }}
                  // disabled={wallets?.list?.length <= 0} // Uncomment if you want to disable the select when there are no options
                >
                  {wallets?.list?.map((option) => (
                    <MenuItem key={option.id} value={option.id.toString()}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          ) : (
            <div className={styles.wallet}>
              {userCards[0]?.cardId ? (
                <>
                  <span className={styles.label}>Select a different wallet</span>
                  <Select
                    className={styles.selectOption}
                    value={selectedWalletId}
                    onChange={handleOrgSelect}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 50,
                      minWidth: 150
                    }}
                    // disabled={wallets?.list?.length <= 0} // Uncomment if you want to disable the select when there are no options
                  >
                    {wallets?.list?.map((option) => (
                      <MenuItem key={option.id} value={option.id.toString()}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <Button
                  variant="contained"
                  className={styles.btn}
                  onClick={() => {
                    navigate("/newPayment");
                  }}
                >
                  Select Wallet To Pay
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.recentActivityContainer}>
        <h3>Recent Activity</h3>
        <div className={styles.recentActivity}>
          {transactions?.list?.map((wallet) => <RecentActivity card={wallet} />)}
        </div>
      </div>
    </div>
  );
}
