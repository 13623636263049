import CardEdit from "src/components/cardEdit/CardEdit";
import { User } from "src/types/commonTypes";
import styles from "./cards.module.scss";
import { Card } from "./cardSlice";

const columns = (onUpdate: () => void, showActions: boolean = true) => {
  const baseColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Card No.",
      dataIndex: "cardNumber",
      key: "cardNumber",
      render: (text: string, card: Card) => (
        <div>
          {card?.cardHolderName ? card?.cardHolderName + "-" : ""}{" "}
          {card.cardNumber ? "****" + card.cardNumber.slice(-4) : ""}
        </div>
      )
    },
    {
      title: "Client Id",
      dataIndex: "clientId",
      key: "clientId"
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId"
    },
    {
      title: "Status",
      dataIndex: "cardStatus",
      key: "cardStatus",
      render: (cardStatus: string) => (
        <div className={styles.tableCardStatus}>
          <div className={`${cardStatus === "ACTIVE" ? styles.green : styles.grey}`}>{cardStatus}</div>
        </div>
      )
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user?: User) => (
        <div>
          <div>{user?.firstName + " " + user?.lastName}</div>
          <div>{user?.email}</div>
        </div>
      )
    },
    {
      title: "Access Type",
      dataIndex: "accessType",
      key: "accessType"
    }
  ];

  if (showActions) {
    baseColumns.push({
      title: "",
      dataIndex: "",
      key: "operations",
      render: (text: any, card: Card) => <CardEdit onUpdate={onUpdate} card={card} />
    });
  }

  return baseColumns;
};

export default columns;
