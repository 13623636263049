import { createSlice } from "@reduxjs/toolkit";
import { TransactionRequest, getAllTransaction } from "src/network/graphql/transactionService";
import { StoreDispatch } from "../store";
import { Transaction } from "../transactions/transactionSlice";

const initialTransaction: Transaction[] = [];

const ledgerSlice = createSlice({
  name: "transactions",
  initialState: {
    transLoading: false,
    error: null,
    transList: {
      list: initialTransaction,
      total: 0
    }
  },
  reducers: {
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.list = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.list = [];
      state.transList.total = 0;
      state.error = action.payload;
    }
  }
});

export const { fetchTransListStart, fetchTransListSuccess, fetchTransListFail } = ledgerSlice.actions;

export const fetchTransListAction = (data: TransactionRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await getAllTransaction(data);
      const structuredResponse = response.data.search_activity.activity_listing.map((data: any) => ({
        id: data.activity_id,
        name: data.activity_name,
        endDate: data.end_timestamp,
        startDate: data.start_timestamp,
        ownerId: data.owner_id,
        activityStatus: data.activity_status,
        data: JSON.parse(data?.data)
      }));

      dispatch(
        fetchTransListSuccess({
          list: structuredResponse,
          total: response.data.search_activity.total_size
        })
      );
      return response;
    } catch (err) {
      dispatch(fetchTransListFail(err));
    }
  };
};

export default ledgerSlice.reducer;
