import { createBrowserRouter } from "react-router-dom";
import Users from "src/page/users/Users";
import Wallet from "src/page/wallets/Wallets";
import Transactions from "src/page/transactions/Transactions";
import UserDetail from "src/page/userDetail/UserDetail";
import WalletDetail from "src/page/walletDetail/WalletDetail";
import Dashboard from "src/page/dashboard/Dashboard";
import Login from "./auth/auth";
import { lazy } from "react";
import { useAppSelector } from "./store";
import Cards from "./cards/cards";
import Organisation from "./organisation/Organisation";
import OrganisationDetail from "./organisationDetail/OrganisationDetail";
import Reports from "./report/Report";
import SuperLedgers from "./superLedger/SuperLedgers";
import Home from "./home/Home";
import Activity from "./activity/Activity";
import Budget from "./budget/Budget";
import NewPayment from "./newPayment/NewPayment";
import ChangePin from "./changePin/ChangePin";
import Deposit from "./deposit/Deposit";
import Ledgers from "./ledger/Ledger";
import InternalPayment from "src/components/newPaymentCards/InternalPay";
// import SSOAuth from "./SSOAuthPage/SSOAuth";

const Layout = lazy(() => import("src/components/layout/Layout"));

function MainPage() {
  const menuList = useAppSelector((state) => state.auth.menuList);
  const menuItem = menuList.find((item) => item.id === "HOME" || item.id === "DASHBOARD");

  switch (menuItem?.id) {
    case "HOME":
      return <Home />;
      break;
    case "DASHBOARD":
      return <Dashboard />;
      break;
    default:
      return null;
      break;
  }
}

const router = createBrowserRouter([
  {
    path: "/sign-in",
    element: <Login />
  },
  {
    path: "/auth-sign-in",
    element: <Login />
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: "/users",
        element: <Users />
      },
      {
        path: "/wallets",
        element: <Wallet />
      },
      {
        path: "/transactions",
        element: <Transactions />
      },
      {
        path: "/users/:userId",
        element: <UserDetail />
      },
      {
        path: "/wallets/:walletId",
        element: <WalletDetail />
      },
      {
        path: "/wallets/:walletId/transactions",
        element: <Transactions />
      },
      {
        path: "/users/:userId/transactions",
        element: <Transactions />
      },
      {
        path: "/cards",
        element: <Cards />
      },
      {
        path: "/deposit",
        element: <Deposit />
      },
      {
        path: "/organisation",
        element: <Organisation />
      },
      {
        path: "/organisation/:organisationId",
        element: <OrganisationDetail />
      },
      {
        path: "/organisation/:organisationId/transactions",
        element: <Transactions />
      },
      {
        path: "/organisation/:organisationId/users",
        element: <Users />
      },
      {
        path: "/organisation/:organisationId/wallets",
        element: <Wallet />
      },
      {
        path: "/organisation/:organisationId/cards",
        element: <Cards />
      },
      {
        path: "/reports",
        element: <Reports />
      },
      {
        path: "/superLedgers",
        element: <SuperLedgers />
      },
      {
        path: "/activity",
        element: <Activity />
      },
      {
        path: "/budget",
        element: <Budget />
      },
      {
        path: "/newPayment",
        element: <NewPayment />
      },
      {
        path: "/changePin",
        element: <ChangePin />
      },
      {
        path: "/ledgers",
        element: <Ledgers />
      },
      {
        path: "/transferfunds",
        element: <InternalPayment />
      }
    ]
  }
]);

export default router;
