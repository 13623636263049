import styles from "./personWelCard.module.scss";
import { Avatar } from "@mui/material";
import { bgColorbyName } from "src/variable/randomColor";

type Iprops = {
  id?: number | string;
  img?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  connectedTo?: string;
  welCardLOver?: string;
  status?: boolean;
};

export default function PersonWelCard({ img, firstName, lastName, name, email, id, welCardLOver }: Iprops) {
  return (
    <div className={styles.welCard}>
      <div className={styles.welCardL}>
        <div className={styles.userImage}>
          <Avatar
            id="av"
            className={`${styles.av} ${welCardLOver}`}
            aria-label="Recipe"
            style={{ backgroundColor: bgColorbyName(firstName || "") }}
          >
            {img}
          </Avatar>
        </div>
        <div className={styles.userName}>
          <h3>{`${firstName} ${lastName}`}</h3>
          <h3>{name}</h3>
          <p>
            {email} | ID: {id}
          </p>
        </div>
      </div>
      <div className={styles.welCardR}></div>
    </div>
  );
}
