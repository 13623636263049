import client from "../client";

export type AddFundsRequest = {
  wallet_id: string;
  amount: number;
  remark: string;
};
export const addFunds = (data: AddFundsRequest) => {
  return client({
    query: `
    mutation AddFunds($input: WalletFundsInput) {
      add_funds_to_wallet(input: $input){
        balance
      }
    }
    `,
    variables: {
      input: data
    }
  });
};

export const revokeFunds = (data: AddFundsRequest) => {
  return client({
    query: `
    mutation RevokeFunds($input: WalletFundsInput) {
      adjust_funds_in_wallet(input: $input) {
        balance
      }
    }    
    `,
    variables: {
      input: data
    }
  });
};
