import { createSlice } from "@reduxjs/toolkit";

type Wallet = {
  id: number;
  participant: { fullName: string; role: string; firstName: string; lastName: string };
  description: string;
};
const initialWallets: Wallet[] = [];

const reportSlice = createSlice({
  name: "reports",
  initialState: {
    walletLoading: false,
    downloadReportLoading: false,
    error: null,
    walletList: {
      list: initialWallets,
      total: 0
    }
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    downloadReportStart: (state) => {
      state.downloadReportLoading = true;
    },
    downloadReportSuccess: (state) => {
      state.downloadReportLoading = false;
    },
    downloadReportFail: (state, action) => {
      state.downloadReportLoading = false;
      state.error = action.payload;
    }
  }
});

export const fetchWalletListAction = (data: any) => {
  return data;
};

export const downloadReportAction = (data: any) => {
  return data;
};

export default reportSlice.reducer;
