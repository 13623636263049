export const WAL_START_DATE = "walstartdate";
export const WAL_END_DATE = "walenddate";
export const WAL_MIN_AMT = "walminamt";
export const WAL_MAX_AMT = "walmaxamt";
export const WAL_SUPPORTERS = "walsupporters";
export const WAL_PARTICIPANTS = "walparticipants";
export const WAL_PAGE = "walpage";
export const WAL_LIMIT = "wallimit";

export const TXN_START_DATE = "txnstartdate";
export const TXN_END_DATE = "txnenddate";
export const TXN_MIN_AMT = "txnminamt";
export const TXN_MAX_AMT = "txnmaxamt";
export const TXN_SUPPORTERS = "txnsupporters";
export const TXN_PARTICIPANTS = "txnparticipants";
export const TXN_WALLETS = "txnwallets";
export const TXN_ORGANISATIONS = "txnorganisations";
export const TXN_PAGE = "txnpage";
export const TXN_LIMIT = "txnlimit";
export const TXN_RECIEPT = "txnreceipt";
export const TXN_REVIEWED = "txnreviewed";
export const TXN_ACTIVITYNAME = "txnactivityname";

export const ORG_START_DATE = "orgstartdate";
export const ORG_END_DATE = "orgenddate";
export const ORG_STATUS = "orgstatus";
export const ORG_SEARCHBY = "orgsearchby";
export const ORG_PAGE = "orgpage";
export const ORG_LIMIT = "orglimit";
export const SEARCH_TYPE = "searchtype";

export const RPRT_START_DATE = "rprtstartdate";
export const RPRT_END_DATE = "rprtenddate";
export const RPRT_TYPE = "rprttype";
export const RPRT_WALLETS = "rprtwallet";

export const CARD_PAGE = "cardpage";
export const CARD_LIMIT = "cardlimit";
export const CARD_ACCESS_TYPE = "cardaccesstype";
export const CARD_STATUS = "cardstatus";
export const CARD_SEARCH_TEXT = "cardsearchtext";
export const CARD_WALLET_ID = "cardwalletid";

export const SUPERLEDGER_PAGE = "sprldgrpage";
export const SUPERLEDGER_LIMIT = "sprldgrlimit";
export const SUPERLEDGER_START_DATE = "sprldgrstartdate";
export const SUPERLEDGER_END_DATE = "sprldgrenddate";
export const SUPERLEDGER_WALLETID = "sprldgrwalletid";
export const SUPERLEDGER_USERID = "sprldgruserid";
export const SUPERLEDGER_TRANSACTIONTYPE = "sprldgrtranstype";
export const SUPERLEDGER_PAYMENTTYPE = "sprldgrpaymenttype";

export const LEDGER_PAGE = "ledgerPage";
export const LEDGER_LIMIT = "ledgerLimit";
