import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./superledgerFilter.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField, FormControl, MenuItem, Select } from "@mui/material";
import { useAppSelector } from "src/page/store";
import { useSearchParams } from "react-router-dom";
import {
  SUPERLEDGER_START_DATE,
  SUPERLEDGER_END_DATE,
  SUPERLEDGER_WALLETID,
  SUPERLEDGER_USERID,
  SUPERLEDGER_TRANSACTIONTYPE,
  SUPERLEDGER_PAYMENTTYPE
} from "src/components/constant/queryConstants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { debounce } from "lodash";
import { fetchUserListAction, fetchWalletListAction } from "src/page/superLedger/superLedgerSlice";

interface FilterTransModalProps {
  handleClose: () => void;
  handleFilter: () => void;
}

export default function SuperledgerFilter({ handleClose, handleFilter }: FilterTransModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get(SUPERLEDGER_START_DATE) || "";
  const endDate = searchParams.get(SUPERLEDGER_END_DATE) || "";
  const walletId = searchParams.get(SUPERLEDGER_WALLETID) || "";
  const userId = searchParams.get(SUPERLEDGER_USERID) || "";
  const transactionType = searchParams.get(SUPERLEDGER_TRANSACTIONTYPE) || "";
  const paymentType = searchParams.get(SUPERLEDGER_PAYMENTTYPE) || "";
  const [error, setError] = useState({ Amt: "", Date: "" });
  //   const [selectedWallet, setSelectedWallet] = useState<Wallet | null>(null);
  //   const [selectedUser, setSelectedUser] = useState<UserList | null>(null);

  const { users, wallets } = useAppSelector((state) => state.superledger);

  const handleApplyFilter = () => {
    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
      return;
    }

    setSearchParams(searchParams);
    handleFilter();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleReset = () => {
    setSearchParams({});
    setError({ Amt: "", Date: "" });
    handleFilter();
    // handleClose();
  };

  useEffect(() => {
    if (walletId) {
      fetchWalletListAction();
    }
    if (userId) {
      fetchUserListAction();
    }
  }, []);

  useEffect(() => {
    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
    } else {
      setError({
        ...error,
        Date: ""
      });
    }
  }, [startDate, endDate, error]);

  const fetchWalletOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];

    fetchWalletListAction();
  }, 500);
  const handleWalletInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchWalletOptions(newInputValue.toUpperCase());
  };

  const fetchUserOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];
    fetchUserListAction();
  }, 500);
  const handleUserInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchUserOptions(newInputValue.toUpperCase());
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Filter Super Ledger</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.filterByDate}>
            <div className={styles.head}>Filter by Date</div>
            <div className={styles.date}>
              <div className={styles.startDate}>
                <label>Start Date</label>
                <TextField
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    searchParams.set(SUPERLEDGER_START_DATE, e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className={styles.endDate}>
                <label>End Date</label>
                <TextField
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    searchParams.set(SUPERLEDGER_END_DATE, e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "MuiInputLabel-outlined"
                    }
                  }}
                />
              </div>
            </div>
            {error.Date && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Date}
              </div>
            )}
          </div>
          <div className={styles.addUser}>
            <div className={styles.byWallet}>
              <label>By Wallet</label>

              <Autocomplete
                options={wallets?.list}
                getOptionLabel={(option) => `${option?.description}`}
                style={{ width: 245 }}
                onInputChange={handleWalletInputChange}
                value={
                  wallets?.list.find((wallet) => wallet.id.toString() === searchParams.get(SUPERLEDGER_WALLETID)) ||
                  null
                }
                onChange={(event, newValue) => {
                  if (newValue?.id) {
                    searchParams.set(SUPERLEDGER_WALLETID, newValue?.id.toString());
                  } else {
                    searchParams.delete(SUPERLEDGER_WALLETID);
                  }
                }}
                renderInput={(params) => <TextField {...params} placeholder="Wallet" variant="outlined" />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontSize: "14px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px"
                      }}
                    >
                      {option?.id}
                      {") "}
                      <div>
                        <div>{option?.participant?.fullName}</div>
                        <div>{option?.description}</div>
                      </div>
                    </div>
                  </li>
                )}
                ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
              />
            </div>
            <div className={styles.byUser}>
              <div className={styles.byUserHead}>
                <label>By User</label>
              </div>
              <Autocomplete
                options={users?.list}
                getOptionLabel={(option) => option?.email}
                style={{ width: 245 }}
                onInputChange={handleUserInputChange}
                value={users?.list.find((user) => user.id.toString() === searchParams.get(SUPERLEDGER_USERID)) || null}
                onChange={(event, newValue) => {
                  if (newValue?.id) {
                    searchParams.set(SUPERLEDGER_USERID, newValue?.id.toString());
                  } else {
                    searchParams.delete(SUPERLEDGER_USERID);
                  }
                }}
                renderInput={(params) => <TextField {...params} placeholder="participants" variant="outlined" />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontSize: "14px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px"
                      }}
                    >
                      {option?.id}
                      {") "}
                      <div>
                        <div>{option?.fullName}</div>
                        <div>{option?.email}</div>
                      </div>
                    </div>
                  </li>
                )}
                ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
              />
            </div>
          </div>
          <div className={styles.categoryFilter}>
            <div className={styles.byCategory}>
              <div className={styles.head}>Transaction Type</div>

              <FormControl>
                <Select
                  value={transactionType || "transactionType"}
                  onChange={(e) => {
                    if (e.target.value != "transactionType") {
                      searchParams.set(SUPERLEDGER_TRANSACTIONTYPE, e.target.value);
                    } else {
                      searchParams.delete(SUPERLEDGER_TRANSACTIONTYPE);
                    }
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 65,
                    color: {
                      deposit: "",
                      credit: "",
                      transactionType: "grey",
                      default: "grey"
                    }[transactionType ?? "default"]
                  }}
                >
                  <MenuItem value="transactionType" sx={{ color: "grey" }}>
                    Transaction Type
                  </MenuItem>
                  <MenuItem value="deposit">DEPOSIT</MenuItem>
                  <MenuItem value="credit">CREDIT</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.byCategory}>
              <div className={styles.head}>Payment Type</div>

              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={paymentType || "paymenttype"}
                  onChange={(e) => {
                    if (e.target.value != "paymentType") {
                      searchParams.set(SUPERLEDGER_PAYMENTTYPE, e.target.value);
                    } else {
                      searchParams.delete(SUPERLEDGER_PAYMENTTYPE);
                    }
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 65,
                    color: {
                      depsoit: "",
                      paymentType: "grey",
                      default: "grey"
                    }[paymentType != null ? paymentType + "" : "default"]
                  }}
                >
                  <MenuItem value="paymenttype" sx={{ color: "grey" }}>
                    Payment Type
                  </MenuItem>
                  <MenuItem value="deposit">DEPOSIT</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleReset}>
              Reset Filter
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
