import { filterLogo } from "src/components/nav/logo";
import styles from "./activity.module.scss";
import Button from "@mui/material/Button";
import ActivityCard from "src/components/activityCard/ActivityCard";
import { useCallback, useEffect, useState } from "react";
// import { data } from "./activityConfig";
import Modalui from "src/components/UI/modal/Modalui";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { fetchActivityAction, fetchUserListAction, fetchWalletListAction } from "./activitySlice";
import { useLocation } from "react-router-dom";

// import { CircularProgress } from "@mui/material";

// const walletOptions = ["Wallet 1", "Wallet 2", "Wallet 3"];
// const receiptOptions = ["Receipt 1", "Receipt 2", "Receipt 3"];
// const photoOptions = ["Photo 1", "Photo 2", "Photo 3"];

const PAGE_SIZE = 15;

export default function Activity() {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { transactions, users, wallets } = useAppSelector((state) => state.activity);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [selectFilter] = useState<string>("wallet");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedWallet, setSelectedWallet] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const location = useLocation();

  const handleClose = () => setOpen(false);

  const handleSelectCard = (id: string) => {
    setSelectedCardId(id);
  };

  const handleResetSelection = () => {
    setSelectedCardId(null);
  };

  // const handleFilterChange = (event: SelectChangeEvent<string>) => {
  //   const filterType = event.target.value as string;

  //   setSelectedOption("");
  //   setSelectFilter(filterType);
  // };

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    const participantId = event.target.value as string;

    if (participantId) {
      setSelectedOption(participantId);
      dispatch(fetchWalletListAction(participantId));
    }
  };

  const handleWalletChange = (event: SelectChangeEvent<string>) => {
    const walletId = event.target.value as string;

    if (walletId) {
      setSelectedWallet(walletId);
    }
  };

  const renderSecondSelect = () => {
    let options = [];
    let label = "";

    switch (selectFilter) {
      case "wallet":
        options = users?.list;
        label = "Select Participant";
        break;
      // case "needRecipt":
      //   options = receiptOptions;
      //   label = "Select Receipt";
      //   break;
      // case "needphoto":
      //   options = photoOptions;
      //   label = "Select Photo";
      //   break;
      default:
        return null;
    }

    return (
      <>
        <Select
          className={styles.selectOption}
          value={selectedOption}
          onChange={handleOptionChange}
          displayEmpty
          inputProps={{ "aria-label": label }}
          sx={{
            height: 50,
            minWidth: 200,
            color: {
              "": "grey",
              default: "grey"
            }[selectedOption != null ? selectedOption + "" : "default"]
          }}
        >
          <MenuItem value="" disabled>
            {label}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option?.userId} value={option?.userId}>
              {option?.firstName + " " + option?.lastName}
            </MenuItem>
          ))}
        </Select>
        {selectFilter === "wallet" && selectedOption && (
          <Select
            className={styles.selectOption}
            value={selectedWallet}
            onChange={handleWalletChange}
            displayEmpty
            inputProps={{ "aria-label": label }}
            sx={{
              height: 50,
              minWidth: 200,
              color: {
                "": "grey",
                default: "grey"
              }[selectedWallet != null ? selectedWallet + "" : "default"]
            }}
          >
            <MenuItem value="" disabled>
              Select Wallet
            </MenuItem>
            {wallets?.list.map((option) => (
              <MenuItem key={option?.id} value={option?.id}>
                {option?.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </>
    );
  };

  const fetchActivity = useCallback(() => {
    dispatch(
      fetchActivityAction({
        size: PAGE_SIZE,
        from: page * PAGE_SIZE,
        filter: {
          activityName: "NOVATTI_CARD_TRANSACTION"
        },
        userFilter: {
          email: String(userInfo?.email)
        }
      })
    );
  }, [dispatch, page, userInfo?.email]);

  useEffect(() => {
    fetchActivity();
  }, [dispatch, location.pathname, fetchActivity]);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    // if (selectFilter === "wallet") {
    dispatch(fetchUserListAction({ size: 50, from: 0 }));
    // }
  }, [dispatch]);

  const handleFilterConfirm = () => {
    dispatch(
      fetchActivityAction(
        {
          size: PAGE_SIZE,
          from: page * PAGE_SIZE,
          filter: {
            activityName: "NOVATTI_CARD_TRANSACTION",
            walletIds: [selectedWallet]
          },
          userFilter: {
            email: String(userInfo?.email)
          }
        },
        true
      )
    );
    setOpen(false);
  };

  const handleClearFilter = () => {
    setSelectedOption("");
    setSelectedWallet("");
    dispatch(
      fetchActivityAction(
        {
          size: PAGE_SIZE,
          from: page * PAGE_SIZE,
          filter: {
            activityName: "NOVATTI_CARD_TRANSACTION"
          },
          userFilter: {
            email: String(userInfo?.email)
          }
        },
        true
      )
    );
    setOpen(false);
  };

  return (
    <>
      <Modalui open={open} handleClose={handleClose} modaluiOver={styles.editModalOver}>
        <div className={styles.filterContainer}>
          <h3>Filter Activity</h3>
          <div className={styles.filterContent}>
            <Select
              className={styles.selectOption}
              value={selectFilter}
              // onChange={handleFilterChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: 50,
                minWidth: 200,
                color: {
                  needRecipt: "",
                  needphoto: "",
                  wallet: "",
                  selectFilter: "grey",
                  default: "grey"
                }[selectFilter != null ? selectFilter + "" : "default"]
              }}
            >
              <MenuItem value="selectFilter" sx={{ color: "gray" }}>
                Select Filter
              </MenuItem>
              {/* <MenuItem value="needRecipt">Need Reciept</MenuItem>
              <MenuItem value="needphoto">Needs Photo</MenuItem> */}
              <MenuItem value="wallet">Wallet</MenuItem>
            </Select>
            {renderSecondSelect()}
          </div>
          <div className={styles.bottom}>
            <Button variant="outlined" className={styles.clearBtn} onClick={handleClearFilter}>
              Clear
            </Button>
            <Button variant="contained" className={styles.orderCardBtn} onClick={handleFilterConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </Modalui>
      <div className={styles.activity}>
        <div className={styles.header}>
          <div className={styles.title}>
            <h3>Activity</h3>
          </div>
          {!selectedCardId && (
            <div className={styles.filter}>
              <Button
                variant="contained"
                className={styles.filterBtn}
                onClick={() => {
                  setOpen(true);
                }}
              >
                <div>Filter</div>
                <div>{filterLogo}</div>
              </Button>
            </div>
          )}
        </div>
        <div className={styles.content}>
          {transactions?.list?.map((card: any) =>
            selectedCardId === null || selectedCardId === card.id ? (
              <ActivityCard
                key={card.id}
                card={card}
                onSelectedCard={handleSelectCard}
                onResetSelection={handleResetSelection}
                isSelected={selectedCardId === card.id}
              />
            ) : null
          )}
        </div>
        {!selectedCardId && (
          <>
            {""}
            {transactions?.list?.length % PAGE_SIZE === 0 ? (
              <div className={styles.loadMoreContainer}>
                {/* {activityLoading ? (
              <CircularProgress sx={{ color: "#0f172a" }} />
            ) : ( */}
                <Button variant="contained" onClick={handleLoadMore}>
                  Load More
                </Button>

                {/* )} */}
              </div>
            ) : (
              "No more Activity"
            )}
          </>
        )}
      </div>
    </>
  );
}
