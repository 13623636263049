import styles from "./photoCard.module.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useState } from "react";
import Modalui from "../UI/modal/Modalui";
import { Button, Stack } from "@mui/material";
import pdfLogo from "src/assets/images/pdf.png";
import CloseIcon from "@mui/icons-material/Close";

export interface AssetUrl {
  id: string;
  url: string;
  assetId: string;
}

interface PhotoCardProps {
  data: AssetUrl;
  onDelete: (_assetId: string) => void;
}

export default function PhotoCard({ data, onDelete }: PhotoCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const isPdf = data.id.toLowerCase().endsWith(".pdf");
  const googleViewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(data.url)}`;

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onDelete(data.assetId);
    setOpen(false);
  };

  return (
    <>
      <Modalui
        open={open}
        handleClose={() => setOpen(false)}
        modaluiOver={`${styles.modalOver} ${isPdf ? styles.modalOverPdf : ""}`}
      >
        <div className={styles.close}>
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <CloseIcon
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            />
          </button>
        </div>
        {!isPdf ? (
          <img src={data.url} alt={data.id} className={styles.modalImage} />
        ) : (
          <iframe src={googleViewerUrl} title="PDF Viewer" style={{ border: "none" }}></iframe>
        )}
        <div className={`${styles.modalBtn} ${isPdf ? styles.modalBtnPdf : ""}`}>
          <Stack spacing={2} direction="row">
            <a href={data.url} download className={styles.cancelBtn}>
              Download
            </a>
            <Button className={styles.deleteBtn} variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </Stack>
        </div>
      </Modalui>
      <div className={styles.card}>
        {!isPdf ? (
          <img src={data.url} alt={data.id} className={styles.image} />
        ) : (
          <img className={styles.pdfLogo} src={pdfLogo} alt="logo" />
        )}

        <div className={styles.btn}>
          <button
            className={styles.expandIcon}
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            <InfoOutlinedIcon className={styles.icon} />
          </button>
          <a href={data.url} download className={styles.expandIcon}>
            <FileDownloadOutlinedIcon className={styles.icon} />
          </a>
          <button
            className={styles.deleteIcon}
            onClick={(e) => {
              e.preventDefault();
              onDelete(data.assetId);
            }}
          >
            <DeleteOutlinedIcon className={styles.icon} />
          </button>
        </div>
      </div>
    </>
  );
}
