import { Avatar } from "@mui/material";
import styles from "../orgListCard.module.scss";
import { bgColorbyName } from "src/variable/randomColor";
import AdminSwitchOrg from "src/components/adminSwitchOrg/AdminSwitchOrg";
import { dateTimeFormat } from "src/utils/utils";

interface Org {
  id: number;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
}

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    Key: "id",
    render: (id: number) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Company Name",
    dataIndex: "name",
    key: "name",
    // width: 150,
    render: (name: string, data: Org) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberImg}>
          {/* <img src={} alt="Member" /> */}
          <Avatar id="av" aria-label="Recipe" style={{ backgroundColor: bgColorbyName(name) }}>
            {/* {this.props.userName.charAt(0).toLocaleUpperCase()} */}
            {name?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>{name}</div>
          <div className={styles.tableMemberEmail}>{data?.abn}</div>
        </div>
      </div>
    )
  },
  {
    title: "Date / Time",
    dataIndex: "createdAt",
    key: "createdAt",
    // width: 150,
    render: (createdAt: string) => {
      if (createdAt === undefined) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }

      return (
        <>
          <div>{dateTimeFormat(createdAt)?.date}</div>
          <div>{dateTimeFormat(createdAt)?.time}</div>
        </>
      );
    }
  },

  {
    title: "Name",
    dataIndex: "referenceName",
    key: "referenceName",
    // width: 100,
    render: (referenceName: string) => (
      <div className={styles.tableRole}>
        <div className={styles.tableRoleName}>{referenceName}</div>
      </div>
    )
  },
  {
    title: "Organisation Contact",
    dataIndex: "phone",
    key: "phone",
    // width: 100,
    render: (phone: string) => (
      <div className={styles.tableRole}>
        <div className={styles.tableRoleName}>{phone}</div>
      </div>
    )
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (active: boolean) => (
      <div className={styles.tableStatus}>
        <div className={`${active ? styles.green : styles.grey}`}>{active ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (data: { organisationId: string }) => {
      return <AdminSwitchOrg orgId={data.organisationId} />;
    }
    // width: 100,
  }
];

export default columns;
