import React, { useState, useEffect, useCallback } from "react";
import styles from "./UserListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./listTable/userListTable";
import Modalui from "src/components/UI/modal/Modalui";
import AddUserModal from "./addUserModal/AddUserModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchUserListAction,
  addUserAction,
  connectUserAction,
  suspendUserAction,
  Users
} from "src/page/users/usersSlice";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { CAN_ADD_USER, CAN_CONNECT_USER_TO_ORGANISATION, roleMapping } from "../constant/constant";
import AttachUser from "./attachUser/AttachUser";
import DeleteSettingModal from "../connectCard/connectMember/deleteSupporterModal/DeleteSupporterModal";
import { CreateUserRequest } from "src/network/graphql/userService";
import { getRoleForOrganisation } from "src/utils/roleUtils";
import { checkPermission, removeNull } from "src/utils/utils";
import CircularLoader from "../UI/circularLoader/CircularLoader";

function UserListCard() {
  const [open, setOpen] = useState(false);
  const [attachUser, setAttachUser] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [status, setStatus] = useState<boolean | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [unlinkModal, setUnlinkModal] = useState(false);
  const [unlinkData, setUnlinkData] = useState({ id: "", name: "", img: "", email: "", role: "" });
  const [searchText, setSearchText] = useState("");
  const { users, userLoading } = useAppSelector((state) => state.user);
  const { cognitoConfig } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChange = (event: SelectChangeEvent<string>) => {
  //   const value = event.target.value;

  //   if (value === "active") {
  //     setStatus(true);
  //   } else if (value === "inactive") {
  //     setStatus(false);
  //   } else {
  //     setStatus(null);
  //   }
  // };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value !== "Role") {
      setRole(e.target.value);
    } else {
      setRole(null);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setPage(0);
  };

  const fetchUserList = useCallback(() => {
    const text = searchText.trim() === "" ? undefined : searchText.trim();
    const data = {
      limit: rowsPerPage,
      from: page * rowsPerPage,
      fullName: text,
      filterContext: role,
      // isActive: status,
      organisationId: undefined,
      role
    };

    dispatch(fetchUserListAction(removeNull(data)));
  }, [dispatch, page, role, rowsPerPage, searchText]);

  const handleAddUser = (userData: CreateUserRequest, close: boolean, cb?: () => void) => {
    dispatch(
      addUserAction(userData, () => {
        fetchUserList();
        if (close) {
          handleClose();
        }
        if (cb) {
          cb();
        }
      })
    );
  };

  const handleAttachUser = () => {
    setAttachUser(!attachUser);
  };

  const onAttachUser = (userId: string | number, Role: string, close: boolean) => {
    if (cognitoConfig.currentOrganisation?.organisation_id) {
      const data = {
        userId: userId.toString(),
        roleName: Role,
        organisationId: cognitoConfig.currentOrganisation?.organisation_id
      };

      dispatch(connectUserAction(data)).then(() => {
        fetchUserList();
        if (close) {
          handleAttachUser();
        }
      });
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  const handleUnlinkClose = () => {
    setUnlinkModal(false);
  };

  const handleUnlinkWallet = (data: Users) => {
    setUnlinkData({
      id: data.userId,
      name: `${data.firstName} ${data.lastName}`,
      img: data.firstName?.charAt(0).toLocaleUpperCase(),
      email: data.email,
      role: getRoleForOrganisation(data?.role, cognitoConfig?.currentOrganisation?.organisation_id) ?? "NOROLE"
    });
    setUnlinkModal(true);
  };

  let unlinkOperation;

  if (checkPermission(CAN_CONNECT_USER_TO_ORGANISATION)) {
    unlinkOperation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: Users) => (
        <div onClick={() => handleUnlinkWallet(record)} className={styles.unlinkOperation}>
          <Tooltip title="Unlink" arrow>
            <LinkOffIcon />
          </Tooltip>
        </div>
      ),
      width: 100
    };
  } else {
    unlinkOperation = {};
  }

  const updatedColumns = [...columns(searchText), unlinkOperation];

  const onUnlinkWallet = (email: string, supporterId: number | string, removeItFromAll?: boolean, cb?: () => void) => {
    const data = {
      organisationId: cognitoConfig.currentOrganisation?.organisation_id ?? "",
      userId: String(supporterId)
    };

    dispatch(suspendUserAction(data)).then(() => {
      fetchUserList();
    });
    if (cb) {
      cb();
    }
  };

  const deleteInput = {
    buttonInfo: "Unlink",
    whomToRemove: "user's",
    headInfo: "Are you sure, you want to unlink this user?"
  };

  return (
    <>
      <Modalui open={attachUser} handleClose={handleAttachUser}>
        <AttachUser handleClose={handleAttachUser} onConnectUser={onAttachUser} />
      </Modalui>
      <Modalui open={unlinkModal} handleClose={handleUnlinkClose}>
        <DeleteSettingModal
          img={unlinkData.img}
          name={unlinkData.name}
          email={unlinkData.email}
          supporterId={unlinkData.id}
          handleClose={handleUnlinkClose}
          onDeleteSupporter={onUnlinkWallet}
          deleteInput={deleteInput}
          modalOf={"UNLINK"}
        />
      </Modalui>
      <div className={styles.userList}>
        <div className={styles.userListHead}>
          <div className={styles.userListHeadIn}>
            <div className={styles.userListHeadD}>
              <h3>Users list</h3>
              <p>See information about all of your connected users</p>
            </div>
            <div className={styles.userListHeadBtn}>
              {checkPermission(CAN_CONNECT_USER_TO_ORGANISATION) && (
                <Button variant="outlined" className={styles.filterBtn} onClick={handleAttachUser}>
                  Connect User
                </Button>
              )}
              {checkPermission(CAN_ADD_USER) && (
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  className={styles.addUserBtn}
                  startIcon={<PersonAddAlt1Icon />}
                >
                  Add User
                </Button>
              )}
              <Modalui open={open} handleClose={handleClose} modaluiOver={styles.addUserModal}>
                <AddUserModal handleClose={handleClose} onAddUser={handleAddUser} />
              </Modalui>
            </div>
          </div>
          <div className={styles.userListFilter}>
            <div className={styles.filterSearch}>
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={role ? role : "Role"}
                  onChange={handleRoleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  <MenuItem value="Role">Role</MenuItem>
                  {cognitoConfig?.rolesAllowedToAdd &&
                    cognitoConfig?.rolesAllowedToAdd.map((roles) => (
                      <MenuItem value={roles}>{roleMapping[roles]}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={status === null ? "status" : status ? "active" : "inactive"}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  <MenuItem value="status">Status</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl> */}
            </div>
            <div className={styles.userListSearch}>
              <div className={styles.searchBar}>
                <SearchBar onSearch={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.userListTable}>
          <Table
            columns={updatedColumns}
            className={styles.tableIn}
            data={userLoading ? [] : users.list}
            components={
              userLoading
                ? {
                    body: {
                      cell: CircularLoader
                    }
                  }
                : undefined
            }
          />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}

export default UserListCard;
