import { useState, useEffect } from "react";
import styles from "./userDetail.module.scss";
import UserInfocard from "src/components/userInfoCard/UserInfocard";
import ConnectCard from "src/components/connectCard/ConnectCard";
import RecentTrans from "src/components/recentTrans/RecentTrans";
import WalletTransCard from "src/components/walletTransCard/WalletTransCard";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchUserDetailAction,
  fetchWalletConnectAction,
  fetchWalletTransactionAction,
  fetchTransListAction,
  addWalletSupporterAction,
  deleteWalletSupporterAction,
  getAllCardsAction
} from "src/page/userDetail/userDetailSlice";
import { whiteFourPillarLogo, receiptLogo } from "src/components/nav/logo";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import AddIcon from "@mui/icons-material/Add";
import { checkPermission } from "src/utils/utils";
import { CAN_CONNECT_WALLET_TO_USER } from "src/components/constant/constant";
import Modalui from "src/components/UI/modal/Modalui";
import WalletUserModal from "src/components/connectCard/supporterModal/WalletUserModal";
import Table from "rc-table";
import columns from "../cards/cardsConfig";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button } from "@mui/material";
import OrderCardModal from "src/components/orderCardModal/OrderCardModal";

export default function UserPage() {
  const [walletOpen, setWalletOpen] = useState<boolean>(false);
  // const [searchText, setSearchText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const [openOrderCard, setOpenOrderCard] = useState(false);
  const { userId } = useParams();

  const { userDetail, WalletConnect, transList, walletTransaction, userDetailLoading, cards, loading } = useAppSelector(
    (state) => state.userDetail
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetailAction(userId)).then(() => {
        fetchWalletTransactionAction({ participantIds: [Number(userId)] });
        dispatch(
          getAllCardsAction({
            card_filter: {
              user_ids: [userId]
            },
            size: 100,
            from: 0
          })
        );
      });
    }
  }, [dispatch, userId]);

  const fetchCardsData = () => {
    if (userId) {
      dispatch(
        getAllCardsAction({
          card_filter: {
            user_ids: [userId]
          },
          size: 100,
          from: 0
        })
      );
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchWalletConnectAction({ userId }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userDetail) {
      const requestData: any = {
        size: 5,
        from: 0,
        filter: {
          userIds: [userId]
        }
      };

      dispatch(fetchTransListAction(requestData));
    }
  }, [receiptPresent, isReviewed, dispatch, userDetail, userId]);

  const handleAddWallet = (email: string, walletId: string, close: boolean, applyToAll: boolean) => {
    if (userId) {
      dispatch(addWalletSupporterAction(walletId, userId, applyToAll)).then(() => {
        dispatch(fetchUserDetailAction(userId));
        dispatch(fetchWalletConnectAction({ userId }));
      });

      if (close) {
        setWalletOpen(false);
      }
    }
  };

  const handleDeleteWallet = (email?: string, walletId?: string, removeItFromAll?: boolean) => {
    if (userId && walletId) {
      dispatch(deleteWalletSupporterAction(walletId, userId, removeItFromAll || false)).then(() => {
        dispatch(fetchUserDetailAction(userId));
        dispatch(fetchWalletConnectAction({ userId }));
      });
    }
  };

  const WalletTransCardOver = styles.WalletTransCardOver;
  const balCardOver = styles.balCardOver;

  // const userSettingTitle = "User settings";
  // const userSettingSubTitle = "Here you can set preferences.";
  const walletConnectHeading = "Wallet Connect";

  // const handleSetBudgetToggle = () => {};

  // const handleAllowRepeatToggle = () => {};

  const deleteWalletInput = {
    buttonInfo: "Remove from user",
    whomToRemove: "user's",
    headInfo: "Do you want to remove this Wallet?"
  };

  const handleUpdate = () => {};

  function handleOrderCardModal() {
    setOpenOrderCard(!openOrderCard);
  }

  return (
    <>
      <Modalui
        open={walletOpen}
        handleClose={() => {
          setWalletOpen(false);
        }}
      >
        <WalletUserModal
          handleClose={() => {
            setWalletOpen(false);
          }}
          participantName={
            userDetail?.user?.initial + " " + userDetail?.user?.firstName + " " + userDetail?.user?.lastName
          }
          onAddSupporter={handleAddWallet}
        />
      </Modalui>
      <Modalui open={openOrderCard} handleClose={handleOrderCardModal} modaluiOver={styles.modaluiOver}>
        <OrderCardModal
          onSuccess={fetchCardsData}
          handleClose={handleOrderCardModal}
          userDetail={userDetail}
          hideUserInput={true}
        />
      </Modalui>
      <div className={styles.userPage}>
        {userDetailLoading ? <LoadingModal /> : null}
        <div className={styles.userPageWelCard}>
          <PersonWelCard
            img={userDetail?.user?.firstName?.charAt(0).toLocaleUpperCase()}
            firstName={userDetail?.user?.initial + " " + userDetail?.user?.firstName}
            lastName={userDetail?.user?.lastName}
            email={userDetail?.user?.email}
            id={userDetail?.user?.displayId}
            connectedTo={"Users"}
            // welCardLOver={styles.welCardLOver}
            // status={userDetail.active}
          />
        </div>
        <div className={styles.userPageHead}>
          {/* <SettingCard
          userSettingTitle={userSettingTitle}
          userSettingSubTitle={userSettingSubTitle}
          settingOptions={settingOptions}
          onSetBudgetToggle={handleSetBudgetToggle}
          onAllowRepeatToggle={handleAllowRepeatToggle}
        /> */}
          {userDetail && <UserInfocard userId={userDetail} />}
          <ConnectCard
            walletUsers={WalletConnect.wallets}
            heading={walletConnectHeading}
            connectedTo={"User"}
            walletMemberOver={styles.walletMemberOver}
            onDeleteSupporter={handleDeleteWallet}
            deleteInput={deleteWalletInput}
            modalOf={"UNLINK"}
          >
            {checkPermission(CAN_CONNECT_WALLET_TO_USER) && (
              <AddIcon
                className={styles.editIcon}
                onClick={() => {
                  setWalletOpen(true);
                }}
              />
            )}
          </ConnectCard>
        </div>
        <div className={styles.userPageMid}>
          <RecentTrans
            transList={transList.trans}
            // setSearchText={setSearchText}
            setReceiptPresent={setReceiptPresent}
            setIsReviewed={setIsReviewed}
            userId={String(userId)}
            role={"PARTICIPANT"}
            recentTransOver={styles.recentTransOver}
            subTitle={"These are the details of the transactions made by this user"}
          />

          <div className={styles.transCards}>
            {Object.entries(walletTransaction).map(([key, transItem]) => {
              let title;
              let icon;

              if (key === "totalTransactions") {
                title = "Transaction (30 Days)";
                icon = whiteFourPillarLogo;
              } else if (key === "totalTransactionsWithoutReceipts") {
                title = "Transactions without receipt (30 days)";
                icon = receiptLogo;
              }
              // else if (key === "totalCardBalance") {
              //   title = "Card Balance";
              //   icon = whiteCardLogo;
              // }

              return (
                ["totalTransactionsWithoutReceipts", "totalTransactions"].includes(key) &&
                transItem && (
                  <WalletTransCard
                    key={transItem.id}
                    icon={icon}
                    title={title}
                    value={
                      title && ["Wallet Balance", "Card Balance"].includes(title)
                        ? `$${Number(transItem.current).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}`
                        : transItem.current
                    }
                    growthval={transItem.previous !== null ? Number(transItem.diff) : undefined}
                    growthind={transItem.previous !== null ? Boolean(transItem.positive) : undefined}
                    from={transItem.previous !== null ? `from ${transItem.previous}` : undefined}
                    WalletTransCardClass={WalletTransCardOver}
                    balCardClass={balCardOver}
                  />
                )
              );
            })}
          </div>
        </div>
        <div className={styles.userPageBottom}>
          <div className={styles.cardListContainer}>
            <div className={styles.cardListTable}>
              <div className={styles.cardListHead}>
                <div className={styles.cardListHeadD}>
                  <h3>{`${userDetail?.user?.firstName}'s Card`}</h3>
                  <p>List of users Card</p>
                </div>
                <Button variant="contained" className={styles.orderCardBtn} onClick={handleOrderCardModal}>
                  <div>
                    <ShoppingCartIcon />
                  </div>
                  <div>Order Card</div>
                </Button>
              </div>
              <Table
                columns={columns(handleUpdate, false)}
                data={loading ? [] : cards.list}
                className={styles.tableIn}
                rowKey="id"
                // components={
                //   loading
                //     ? {
                //         body: {
                //           cell: CircularLoader
                //         }
                //       }
                //     : undefined
                // }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
