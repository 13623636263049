import { roleMapping } from "src/components/constant/constant";

export type roleRequest = {
  organisation_id: string;
  value: string;
}[];

export const getRoleForOrganisation = (roles: roleRequest | null | undefined, orgId: string | undefined) => {
  if (orgId && Array.isArray(roles) && roles.length > 0) {
    const userRole = roles.find((role) => role?.organisation_id === orgId);

    return userRole ? roleMapping[userRole.value] : null;
  }
  return null;
};
