import { createSlice } from "@reduxjs/toolkit";
export type SuperLedger = {
  id: number;
  balance: number;
  txnType: string;
  ppan: string;
  participantName: string;
  walletId: number;
  transactionBy: string;
  transactionDate: number;
  amount: number;
  repeatPayBalance: number;
};

export type Wallet = {
  id: number;
  description: string;
  participant: { fullName: string };
};

export type UserList = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

const initialSuperledger: SuperLedger[] = [];
const initialWalletOptions: Wallet[] = [];
const initialUsers: UserList[] = [];

const superledgerSlice = createSlice({
  name: "superLedger",
  initialState: {
    superledgerLoading: false,
    walletOptionLoading: false,
    userOptionLoading: false,
    error: null,
    superledger: {
      list: initialSuperledger,
      total: 0
    },
    wallets: {
      list: initialWalletOptions
    },
    users: {
      list: initialUsers
    }
  },
  reducers: {
    fetchSuperledgerStart: (state) => {
      state.superledgerLoading = true;
      state.error = null;
    },
    fetchSuperledgerSuccess: (state, action) => {
      state.superledgerLoading = false;
      state.error = null;
      state.superledger = action.payload;
    },
    fetchSuperledgerFail: (state, action) => {
      state.superledgerLoading = false;
      state.error = action.payload;
    },
    fetchWalletOptionStart: (state) => {
      state.walletOptionLoading = true;
      state.error = null;
    },
    fetchWalletOptionSuccess: (state, action) => {
      state.walletOptionLoading = false;
      state.error = null;
      state.wallets = action.payload;
    },
    fetchWalletOptionFail: (state, action) => {
      state.walletOptionLoading = false;
      state.error = action.payload;
    },
    fetchUserOptionStart: (state) => {
      state.userOptionLoading = true;
      state.error = null;
    },
    fetchUserOptionSuccess: (state, action) => {
      state.userOptionLoading = false;
      state.error = null;
      state.users = action.payload;
    },
    fetchUserOptionFail: (state, action) => {
      state.userOptionLoading = false;
      state.error = action.payload;
    }
  }
});

export const fetchSuperledgerAction = (data: any) => {
  return data;
};

export const fetchWalletListAction = () => {};

export const fetchUserListAction = () => {};
export default superledgerSlice.reducer;
