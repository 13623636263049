import React, { useState, useEffect, useCallback, useMemo } from "react";
import styles from "./orgListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Button from "@mui/material/Button";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./listTable/orgListTable";
import Modalui from "src/components/UI/modal/Modalui";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { FormControl, MenuItem, Select } from "@mui/material";
import AddOrgModal, { orgList } from "./addOrgModal/AddOrgModal";
import { fetchOrgListAction, addOrgAction } from "src/page/organisation/organisationSlice";
// eslint-disable-next-line import/no-named-as-default
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
// eslint-disable-next-line import/no-unresolved
import "./react-daterange-picker.scss";
import { utc } from "moment";
import { useSearchParams } from "react-router-dom";
import {
  ORG_START_DATE,
  ORG_END_DATE,
  ORG_STATUS,
  ORG_PAGE,
  ORG_LIMIT,
  ORG_SEARCHBY
} from "src/components/constant/queryConstants";
// eslint-disable-next-line import/no-unresolved
// import { Value } from "react-calendar/dist/cjs/shared/types";
import LoadingModal from "../UI/loadingModal/LoadingModal";
import { CAN_ADD_ORGANSIATION } from "../constant/constant";
import CircularLoader from "../UI/circularLoader/CircularLoader";
import { checkPermission } from "src/utils/utils";
// import { CAN_ADD_ORGANSIATION } from "../constant/constant";

type DateRangePiece = Date | null;

type DateRangeType = [DateRangePiece, DateRangePiece];

export default function OrgListCard() {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get(ORG_STATUS) || null;
  const searchby = searchParams.get(ORG_SEARCHBY) || "orgName";
  // const [dateRange, setDateRange] = useState<DateRangeType>([null, null]);
  const startDate = searchParams.get(ORG_START_DATE) || null;
  const endDate = searchParams.get(ORG_END_DATE) || null;
  const { cognitoConfig } = useAppSelector((state) => state.auth);

  const dateRange: DateRangeType = useMemo(() => {
    if (startDate != null && endDate != null) {
      return [new Date(startDate), new Date(endDate)];
    }
    return [null, null];
  }, [startDate, endDate]);

  // const handleDateChange = (args: Value) => {
  //   if (args instanceof Array) {
  //     const StartDate = args[0] != null ? utc(args[0]).toISOString() : null;
  //     const EndDate = args[1] != null ? utc(args[1]).endOf("date").toISOString() : null;

  //     if (StartDate) {
  //       searchParams.set(ORG_START_DATE, StartDate);
  //     } else {
  //       searchParams.delete(ORG_START_DATE);
  //     }
  //     if (EndDate) {
  //       searchParams.set(ORG_END_DATE, EndDate);
  //     } else {
  //       searchParams.delete(ORG_END_DATE);
  //     }
  //     setSearchParams(searchParams);
  //   } else {
  //     searchParams.delete(ORG_START_DATE);
  //     searchParams.delete(ORG_END_DATE);
  //     setSearchParams(searchParams);
  //   }
  // };

  const [searchText, setSearchText] = useState("");
  const { orgLists, addOrgLoading, orgListLoading } = useAppSelector((state) => state.organisation);
  const { switchOrgLoading } = useAppSelector((state) => state.auth);
  // const { config } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const fetchOrganisationData = useCallback(() => {
    const orgLimit = searchParams.get(ORG_LIMIT) ?? 10;
    const orgPage = searchParams.get(ORG_PAGE) ?? 0;

    let StartDate = null;
    let EndDate = null;
    let statusPresent = null;

    if (searchParams.get(ORG_STATUS) === "true") {
      statusPresent = true;
    } else if (searchParams.get(ORG_STATUS) === "false") {
      statusPresent = false;
    } else {
      statusPresent = null;
    }

    if (dateRange instanceof Array) {
      StartDate = dateRange[0] != null ? utc(dateRange[0]).toISOString() : null;
      EndDate = dateRange[1] != null ? utc(dateRange[1]).endOf("date").toISOString() : null;
    }

    const text = searchText.trim().length > 0 ? searchText.trim() : undefined;

    const data = {
      page: orgPage ? Number(orgPage) : 0,
      limit: orgLimit ? Number(orgLimit) : 10,
      from: Number(orgPage) * Number(orgLimit),
      active: statusPresent,
      displayId: searchby === "orgId" ? text : undefined,
      searchName: searchby === "orgName" ? text : undefined,
      startDate: StartDate,
      endDate: EndDate
    };

    dispatch(fetchOrgListAction(data));
  }, [searchParams, dateRange, searchText, dispatch]);

  const handleOrg = () => {
    setOpen(!open);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(ORG_PAGE, newPage + "");
    // const orgLimit = searchParams.get(ORG_LIMIT) ? Number(searchParams.get(ORG_LIMIT)) : 10;
    // const newFrom = Number(orgLimit) + Number(from);

    // searchParams.set(ORG_FROM, newFrom.toString());

    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(ORG_LIMIT, event.target.value);
    searchParams.delete(ORG_PAGE);
    setSearchParams(searchParams);
  };

  const handleAddOrg = (input: orgList, cb?: () => void) => {
    dispatch(
      addOrgAction(input, () => {
        if (cb) cb();
        fetchOrganisationData();
      })
    );
  };

  useEffect(() => {
    fetchOrganisationData();
  }, [fetchOrganisationData]);

  return (
    <div className={styles.orgList}>
      {addOrgLoading || switchOrgLoading ? <LoadingModal /> : ""}

      <div className={styles.orgListHead}>
        <div className={styles.orgListHeadIn}>
          <div className={styles.orgListHeadD}>
            <h3>Organisation list</h3>
            <p>See information about all of your connected Organisation</p>
          </div>
          {checkPermission(CAN_ADD_ORGANSIATION) &&
            cognitoConfig.currentOrganisation?.organisation_id === cognitoConfig.defaultOrgId && (
              <div className={styles.orgListHeadBtn}>
                <Button
                  variant="contained"
                  onClick={handleOrg}
                  className={styles.addorgBtn}
                  startIcon={<PersonAddAlt1Icon />}
                >
                  Add Organisation
                </Button>
                <Modalui open={open} handleClose={handleOrg} modaluiOver={styles.modaluiOver}>
                  <AddOrgModal handleClose={handleOrg} onAddOrg={handleAddOrg} addUser={true} />
                </Modalui>
              </div>
            )}
        </div>
        <div className={styles.orgListFilter}>
          <div className={styles.filterSearch}>
            <FormControl sx={{ m: 1, margin: 0 }}>
              <Select
                value={status || "status"}
                onChange={(e) => {
                  searchParams.set(ORG_STATUS, e.target.value);
                  setSearchParams(searchParams);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: 40,
                  color: {
                    true: "",
                    false: "",
                    status: "grey",
                    default: "grey"
                  }[status ?? "default"]
                }}
              >
                <MenuItem value="status" sx={{ color: "grey" }}>
                  Status
                </MenuItem>
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
            {/* <DateRangePicker
              value={dateRange}
              onChange={handleDateChange}
              calendarIcon={null}
              dayPlaceholder="dd"
              yearPlaceholder="yyyy"
              monthPlaceholder="mm"
            /> */}
          </div>
          <div className={styles.orgListSearch}>
            <FormControl sx={{ m: 1, margin: 0 }}>
              <Select
                value={searchby || "orgName"}
                onChange={(e) => {
                  searchParams.set(ORG_SEARCHBY, e.target.value);
                  setSearchParams(searchParams);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: 40
                }}
              >
                <MenuItem value="orgName">Name</MenuItem>
                <MenuItem value="orgId">Id</MenuItem>
              </Select>
            </FormControl>
            <div className={styles.searchBar}>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.orgListTable}>
        <Table
          columns={columns}
          data={orgListLoading ? [] : orgLists.list}
          className={styles.tableIn}
          components={
            orgListLoading
              ? {
                  body: {
                    cell: CircularLoader
                  }
                }
              : undefined
          }
        />
      </div>
      <div className={styles.paginationWrapper}>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 50, 100, 500]}
          component="div"
          count={orgLists.total}
          page={searchParams.get(ORG_PAGE) ? Number(searchParams.get(ORG_PAGE)) : 0}
          onPageChange={handleChangePage}
          rowsPerPage={searchParams.get(ORG_LIMIT) ? Number(searchParams.get(ORG_LIMIT)) : 10}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
