import axios from "axios";
import store from "src/page/store";
// import { CognitoAccessToken } from "amazon-cognito-identity-js";
// import { logout } from "src/page/auth/loginSlice";
// import { now, unix } from "moment";
import { immediateToast } from "izitoast-react";
import { refreshSession } from "src/utils/utils";
import config from "src/config/Config";
import { logout } from "src/page/auth/loginSlice";

const api = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    partner_id: config.PARTNER_ID
  }
});

api.interceptors.request.use(async (configuration) => {
  try {
    const {
      auth: { cognitoIdToken }
    } = store.getState();
    // const dispatch = store.dispatch;

    // if (cognitoIdToken != null) {
    //   const accessToken = new CognitoAccessToken({
    //     AccessToken: cognitoIdToken
    //   });

    //   const expirationTime = unix(accessToken.getExpiration());

    //   if (expirationTime.isSameOrBefore(now())) {
    //     if (cognitoRefreshToken != null) {
    //       const session = await refreshSession(cognitoRefreshToken);

    //       configuration.headers["Authorization"] = `Bearer ${
    //         (session as unknown as { accessToken: string }).accessToken
    //       }`;
    //       return configuration;
    //     } else {
    //       dispatch(logout());
    //     }
    //   } else {
    //   }
    // }
    configuration.headers["Authorization"] = `Bearer ${cognitoIdToken}`;
  } catch (error) {
    console.error(error);
  }
  return configuration;
});

api.interceptors.response.use(
  (response) => {
    if (response.data.errors) {
      const errorMessage = response.data.errors[0].message;
      let messageObj: { message?: string } | undefined;

      try {
        messageObj = JSON.parse(errorMessage);
      } catch (_error) {
        messageObj = undefined;
      }

      immediateToast("error", {
        message: messageObj?.message ?? errorMessage,
        timeout: 3000,
        position: "topCenter"
      });
      throw new Error(response.data.errors[0]);
    }
    return response.data;
  },
  async function (error) {
    const originalRequest = error.config;
    const {
      auth: { cognitoRefreshToken }
    } = store.getState();
    const dispatch = store.dispatch;

    if (error.response.status === 401) {
      if (!originalRequest._retry || originalRequest._retry < 4) {
        originalRequest._retry = originalRequest._retry ? originalRequest._retry + 1 : 1;

        if (cognitoRefreshToken != null) {
          try {
            const session = await refreshSession(cognitoRefreshToken);

            axios.defaults.headers.common["Authorization"] = `Bearer ${
              (session as unknown as { idToken: string }).idToken
            }`;
            return api(originalRequest);
          } catch (err: any) {
            immediateToast("error", {
              message: err.message,
              timeout: 3000,
              position: "topCenter"
            });
            dispatch(logout());
          }
        }
      } else {
        immediateToast("error", {
          message: "your session expired, please login again",
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

const client = (data: { query: string; variables?: any }) => {
  return api.post("/graphql", data);
};

export default client;
