import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import styles from "./filterTag.module.scss";
import { filterTagColor } from "src/variable/randomColor";
import { User } from "src/types/commonTypes";
import { Wallet } from "src/page/wallets/walletSlice";

interface FilterTagProps {
  tagKey: string;
  label: string;
  onRemove: () => void;
  users?: User[] | null;
  wallets?: Wallet[] | null;
  removeUserWallet?: (_userId: string) => void;
}

export default function FilterTag({ tagKey, label, onRemove, users, wallets, removeUserWallet }: FilterTagProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div
      className={`${styles.filterTag} ${tagKey === "users" || tagKey === "wallets" ? styles.dropdownFiltertag : ""}`}
      style={{
        backgroundColor: `${filterTagColor[tagKey]}33`,
        borderColor: `${filterTagColor[tagKey]}73`
      }}
    >
      {(tagKey === "users" && users && users.length > 1) || (tagKey === "wallets" && wallets && wallets.length > 1) ? (
        <>
          <div
            className={`${styles.userDropdown} ${isDropdownOpen ? styles.userDropdownOpen : ""}`}
            onClick={toggleDropdown}
          >
            <span style={{ fontWeight: "500", fontSize: "12px" }}>{label}</span>
            {isDropdownOpen ? (
              <div className={styles.dropdownHeader}>
                <CloseIcon onClick={onRemove} className={styles.closeIcon} />
                <ArrowDropUpIcon className={styles.dropuparrow} />
              </div>
            ) : (
              <ArrowDropDownIcon className={styles.dropuparrow} />
            )}
          </div>
          {isDropdownOpen && (
            <>
              {tagKey === "users" && users && (
                <div
                  className={styles.dropdown}
                  style={{
                    backgroundColor: `#d4edda`,
                    borderColor: `${filterTagColor[tagKey]}73`
                  }}
                >
                  {users.map((user) => (
                    <div key={user.id} className={styles.userDropdownItem}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "13px" }}>{user?.firstName + " " + user?.lastName}</span>
                        <span style={{ fontSize: "13px", color: "#64748B" }}>{user?.email}</span>
                      </div>
                      <CloseIcon
                        onClick={() => removeUserWallet && removeUserWallet(user.id)}
                        className={styles.closeIcon}
                      />
                    </div>
                  ))}
                </div>
              )}
              {tagKey === "wallets" && wallets && (
                <div
                  className={styles.dropdown}
                  style={{
                    backgroundColor: `#cce5ff`,
                    borderColor: `${filterTagColor[tagKey]}73`
                  }}
                >
                  {wallets.map((wallet) => (
                    <div key={wallet.id} className={styles.userDropdownItem}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontSize: "13px" }}>{wallet?.name}</span>
                        <span style={{ fontSize: "13px", color: "#64748B" }}>{wallet?.participant?.email}</span>
                      </div>
                      <CloseIcon
                        onClick={() => removeUserWallet && removeUserWallet(String(wallet.id))}
                        className={styles.closeIcon}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <span style={{ fontWeight: "500", fontSize: "12px" }}>{label}</span>
          <CloseIcon onClick={onRemove} className={styles.closeIcon} />
        </>
      )}
    </div>
  );
}
