import { useState, lazy, Suspense } from "react";
import styles from "./connectMember.module.scss";
// import EditIcon from "@mui/icons-material/Edit";
import { Avatar } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";
import {
  CAN_CONNECT_CARD_TO_WALLET,
  CAN_CONNECT_USER_TO_WALLET,
  CAN_CONNECT_WALLET_TO_USER,
  roleMapping
} from "src/components/constant/constant";
import { roleColors } from "src/variable/randomColor";
import { checkPermission } from "src/utils/utils";
const Modalui = lazy(() => import("src/components/UI/modal/Modalui"));
const DeleteSettingModal = lazy(() => import("./deleteSupporterModal/DeleteSupporterModal"));

interface Iprops {
  img: string;
  name: string;
  walletName: string;
  email: string;
  bg?: string;
  supporterId: number | string;
  connectTo?: string;
  deletedby?: string;
  deleteInput?: {
    buttonInfo: string;
    whomToRemove: string;
    headInfo: string;
  };
  role?: string;
  onDeleteSupporter: (_email?: string, _supporterId?: number | string, _removeItFromAll?: boolean) => void;
  nonClickable?: boolean;
  cardActive?: boolean;
  modalOf?: "UNLINK" | "LINK";
}

export default function ConnectMember({
  img,
  name,
  walletName,
  supporterId,
  bg,
  onDeleteSupporter,
  connectTo,
  deletedby,
  deleteInput,
  role,
  nonClickable,
  cardActive,
  modalOf
}: Iprops) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleDeleteSupporter = () => {
  //   onDeleteSupporter("", supporterId);
  // };

  return (
    <>
      <div className={`${styles.walletMember} ${!nonClickable ? styles.clickable : ""}`}>
        {(connectTo === "Wallet" || connectTo === "OrgUser") && (
          <Link to={`/users/${supporterId}`}>
            <div className={styles.img}>
              <Avatar id="av" className={styles.av} aria-label="Recipe" style={{ backgroundColor: bg }}>
                {img}
              </Avatar>
            </div>
            <div className={styles.info}>
              <div className={styles.name}>{name}</div>
              <div className={styles.walletName}>{walletName}</div>
              {role && (
                <div
                  className={styles.role}
                  style={{ backgroundColor: `${roleColors[role]}33`, borderColor: `${roleColors[role]}73` }}
                >
                  {roleMapping[role]}
                </div>
              )}
            </div>
          </Link>
        )}

        {(connectTo === "WalletCards" || connectTo === "OrgCards") && (
          <Link to={``}>
            <div className={styles.img}>
              <Avatar id="av" className={styles.av} aria-label="Recipe" style={{ backgroundColor: bg }}>
                {img}
              </Avatar>
            </div>
            <div className={styles.info}>
              <div className={styles.name}>{name}</div>
              <div className={styles.walletName}>{walletName}</div>
              {(connectTo === "WalletCards" || connectTo === "OrgCards") && (
                <div
                  className={styles.role}
                  style={{
                    backgroundColor: cardActive ? "rgba(103, 194, 58, 0.1)" : "rgb(100 116 139 / 10%)",
                    borderColor: cardActive ? "rgba(103, 194, 58, 0.45);" : "#64748b",
                    color: cardActive ? "rgba(103, 194, 58, 0.45);" : "#64748b"
                  }}
                >
                  {cardActive ? "Active" : "Inactive"}
                </div>
              )}
            </div>
          </Link>
        )}

        {(connectTo === "User" || connectTo === "OrgWallet") && (
          <Link to={`/wallets/${supporterId}`}>
            <div className={styles.img}>
              <Avatar id="av" className={styles.av} aria-label="Recipe" style={{ backgroundColor: bg }}>
                {img}
              </Avatar>
            </div>
            <div className={styles.info}>
              <div className={styles.name}>{name}</div>
              <div className={styles.walletName}>{walletName}</div>
            </div>
          </Link>
        )}
        {(connectTo === "Wallet" || connectTo === "User" || connectTo === "WalletCards") && (
          <div className={styles.edit}>
            {connectTo === "Wallet" && deletedby === "budgetCard" && (
              <DeleteOutlineIcon className={styles.editIcon} onClick={handleOpen} />
            )}
            {connectTo === "WalletCards" &&
              deletedby !== "budgetCard" &&
              checkPermission(CAN_CONNECT_CARD_TO_WALLET) && (
                <DeleteOutlineIcon className={styles.editIcon} onClick={handleOpen} />
              )}
            {connectTo === "Wallet" && deletedby !== "budgetCard" && checkPermission(CAN_CONNECT_USER_TO_WALLET) && (
              <DeleteOutlineIcon className={styles.editIcon} onClick={handleOpen} />
            )}
            {connectTo === "User" && checkPermission(CAN_CONNECT_WALLET_TO_USER) && (
              // <Link to={`/wallets/${supporterId}`}>
              <DeleteOutlineIcon className={styles.editIcon} onClick={handleOpen} />
              // </Link>
            )}
          </div>
        )}
      </div>
      {open && (
        <Suspense fallback={null}>
          <Modalui open={open} handleClose={handleClose}>
            <DeleteSettingModal
              img={img}
              name={name}
              email={walletName}
              supporterId={supporterId}
              handleClose={handleClose}
              onDeleteSupporter={onDeleteSupporter}
              deleteInput={deleteInput}
              modalOf={modalOf}
            />
          </Modalui>
        </Suspense>
      )}
    </>
  );
}
