import { useEffect, useState } from "react";
import styles from "./orgInfoCard.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import AddOrgModal, { orgList } from "../orgListCard/addOrgModal/AddOrgModal";
import Modalui from "../UI/modal/Modalui";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "src/page/store";
import { getOrgDetailAction, updateOrgAction } from "src/page/organisationDetail/organisationDetailSlice";
import { toggleOrganisationActivation } from "src/network/graphql/organisationService";
import { immediateToast } from "izitoast-react";

const IOSSwitch = styled((props: SwitchProps & { checked?: boolean }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0f172a",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 16
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#0f172a5e" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

function ensureAbsoluteUrl(url: string) {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    return "http://" + url;
  }
  return url;
}

interface UserInfocardProps {
  org: {
    organisationId?: number | string;
    id: number;
    name: string;
    abn: string;
    address: string;
    phone: string;
    countryCode: string;
    website: string;
    facebook: string;
    linkedIn: string;
    instagram: string;
    referenceName: string;
    referenceRole: string;
    referenceCountryCode: string;
    referencePhone: string;
    referenceEmail: string;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    active: boolean;
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    postcode?: string;
    country?: string;
  };
}
export default function OrgInfoCard({ org }: UserInfocardProps) {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [userStatus, setUserStatus] = useState(org?.active);

  useEffect(() => {
    setUserStatus(org?.active);
  }, [org?.active]);

  const handleModal = () => {
    setOpen(!open);
  };

  const openInNewTab = (url: string) => {
    const absoluteUrl = ensureAbsoluteUrl(url);

    window.open(absoluteUrl, "_blank");
  };

  const handleUpdateOrg = (input: orgList, cb?: () => void) => {
    dispatch(
      updateOrgAction(input, () => {
        if (cb) cb();
        dispatch(getOrgDetailAction(org.organisationId ?? ""));
      })
    );
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserStatus(event.target.checked);

    const orgData = {
      organisationId: org.organisationId as string,
      isActive: !userStatus
    };

    toggleOrganisationActivation(orgData).then((response) => {
      const { errors } = response as unknown as { errors?: any[] };

      if (errors) {
        immediateToast("error", {
          message: "Failed to update the status of the organisation",
          timeout: 3000,
          position: "topCenter"
        });
        setUserStatus(!event.target.checked);
      }
    });
  };

  return (
    <>
      <Modalui open={open} handleClose={handleModal} modaluiOver={styles.modaluiOver}>
        <AddOrgModal handleClose={handleModal} onAddOrg={handleUpdateOrg} orgInfo={org} />
      </Modalui>
      <div className={styles.userHead}>
        <div className={styles.head}>
          <div className={styles.headL}>
            <h3>Organisation information</h3>
            <p>Edit the information about your Organisation.</p>
          </div>
          <div className={styles.headR} onClick={handleModal}>
            <EditIcon className={styles.editIcon} />
          </div>
        </div>
        <div className={styles.userInfo}>
          <div className={styles.label}>
            Organisation name:
            <span className={styles.value}>{org?.name}</span>
          </div>
          <div className={styles.label}>
            ABN:
            <span className={styles.value}>{org?.abn}</span>
          </div>
          <div className={styles.label}>
            Mobile:
            <span className={styles.value}>{org?.referencePhone}</span>
          </div>
          <div className={styles.label}>
            Role: <span className={styles.value}>{org?.referenceRole}</span>
          </div>
          <div className={styles.label}>
            Address:
            <span className={styles.value}>{org?.address}</span>
          </div>
          <div className={styles.label}>
            Active:
            <FormGroup className={styles.formGroup} style={{ marginTop: "-5px", marginLeft: "4px" }}>
              <FormControlLabel
                // key={[]}
                className={styles.formControlLabel}
                control={<IOSSwitch sx={{ m: 1 }} checked={userStatus} onChange={handleToggle} />}
                label=""
              />
            </FormGroup>
          </div>
          <div className={styles.bottomInfo}>
            {org?.website && (
              <div className={styles.label}>
                <span className={styles.value} onClick={() => openInNewTab(org.website)}>
                  <GoogleIcon />
                </span>
              </div>
            )}
            {org?.facebook && (
              <div className={styles.label}>
                <span className={styles.value} onClick={() => openInNewTab(org.facebook)}>
                  <FacebookIcon />
                </span>
              </div>
            )}
            {org?.linkedIn && (
              <div className={styles.label}>
                <span className={styles.value} onClick={() => openInNewTab(org.linkedIn)}>
                  <LinkedInIcon />
                </span>
              </div>
            )}
            {org?.instagram && (
              <div className={styles.label}>
                <span className={styles.value} onClick={() => openInNewTab(org.instagram)}>
                  <InstagramIcon />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
