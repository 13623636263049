import { useAppDispatch, useAppSelector } from "src/page/store";
import styles from "./setFirstTimePassword.module.scss";
import { Button, CircularProgress, TextField } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState } from "react";
import { setFirstTimePasswordAction } from "src/page/auth/loginSlice";
import { setShowSetPassword } from "src/page/users/usersSlice";

interface Iprops {
  handleLoginClick: () => void;
}
const SetFirstTimePassword = ({ handleLoginClick }: Iprops) => {
  const { setPasswordLoading } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState({
    password: ""
  });

  const handleSetPassword = () => {
    dispatch(setFirstTimePasswordAction(password));
  };

  const handleLoginRedirect = () => {
    handleLoginClick();
    dispatch(setShowSetPassword(false));
  };

  return (
    <div>
      <div className={styles.header}>
        <h1>Set Password</h1>
      </div>
      {setPasswordLoading ? (
        <CircularProgress sx={{ color: "#0f172a" }} />
      ) : (
        <div className={styles.container}>
          <div className={styles.formInput}>
            <div className={styles.email}>
              <label htmlFor="password">New Password</label>
              <TextField
                type="password"
                id="password"
                placeholder="PASSWORD"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!e.target.value) {
                    setPasswordError({ ...passwordError, password: "Password is required" });
                  } else {
                    setPasswordError({ ...passwordError, password: "" });
                  }
                }}
                sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
              />
              {passwordError.password && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {passwordError.password}
                </div>
              )}
            </div>
          </div>
          <div className={styles.signIn}>
            <Button
              variant="contained"
              className={styles.SignInBtn}
              disabled={setPasswordLoading}
              onClick={handleSetPassword}
            >
              Set Password
            </Button>
          </div>
          <div className={styles.overlayContainerR}>
            <div className={styles.overlayR}>
              <div className={`${styles.overlayPanelR} ${styles.overlayRight}`}>
                <span>
                  Go Back To
                  <b onClick={handleLoginRedirect}>. Login</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SetFirstTimePassword;
